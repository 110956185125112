import React, {useContext, useState} from "react";
import {Menu, Layout, Button, Drawer, Row, Col, Divider, Badge, FloatButton} from "antd";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import {
    SnippetsOutlined,
    TeamOutlined,
    BorderTopOutlined,
    CodeSandboxOutlined,
    AppstoreOutlined,
    HomeOutlined,
    EnvironmentOutlined,
    ShoppingCartOutlined,
    PhoneOutlined,
    HeatMapOutlined,
    UserSwitchOutlined,
    SolutionOutlined,
    UnorderedListOutlined,
    FileTextOutlined,
    BarsOutlined,
    LoginOutlined,
    CommentOutlined,
    BookOutlined, FacebookOutlined, FacebookFilled, InstagramOutlined, LinkedinOutlined, LogoutOutlined
} from "@ant-design/icons";

import type { MenuProps } from "antd";

import "./overlay.css";
import { Footer, Header } from "antd/es/layout/layout";
import {LoginModal} from "../LoginModel/LoginModal";
import CartPanel from "../cartPanel/cartPanel";
import {observer} from "mobx-react-lite";
import CartStore from "../../stores/Cart.store";
import UserStore from "../../stores/user.store";

const { Content, Sider } = Layout;

type MenuItems = Required<MenuProps>["items"][number];
type OverlayProps = {
    children: React.ReactNode;
}

function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItems[],
    disabled?: boolean
): MenuItems {
    return {
        label,
        key,
        icon,
        children,
        disabled
    } as MenuItems;
}

const stylesIcons = {fontSize: '80%'}

const Overlay: React.FC<OverlayProps> = (props: OverlayProps) => {

    const [menu, setMenu] = useState(false);
    const [loginMoal, setLoginModal] = useState(false);
    const [cartPanel, setCartPanel] = useState(false);
    // external wall padding here
    const UserCartStore = useContext(CartStore);
    const userStore = useContext(UserStore);

    const items: MenuItems[] = [
        getItem(<Link to="/" className="font_link">Home</Link>, "/", <HomeOutlined className="menu-icon" style={stylesIcons}/>),
        getItem(<Link to="/about" className="font_link">About us</Link>, "/about", <TeamOutlined className="menu-icon" style={stylesIcons}/>),
        getItem(<Link to="/ProductRanges" className="font_link">Catalogue</Link>, "/catalogue", <SnippetsOutlined className="menu-icon" style={stylesIcons}/>),
        getItem(<Link to={"/OrderForm"} className="font_link">Order Form</Link>, "/OrderForm",  <FileTextOutlined style={stylesIcons}/>),
        // getItem(<Link to="/Inspiration" className="font_link">Inspiration</Link>, "/Inspiration", <CodeSandboxOutlined style={{fontSize: '120%'}}/>),
        getItem(<Link to="/SiteVisit" className="font_link">Book a Site Visit</Link>, "/SiteVisit", <EnvironmentOutlined style={stylesIcons}/>),
        getItem(<Link to="/ContactUs" className="font_link">Contact Us</Link>, "/ContactUs", <CommentOutlined  style={stylesIcons}/>),
        getItem(<Link to="/Articles" className="font_link">Blog</Link>, "/Articles", <BookOutlined style={stylesIcons}/>),
        getItem( "", "/Cart", <Badge count={UserCartStore.CartLength} color="#faad14">
            <ShoppingCartOutlined
                onClick={() => {
                    setCartPanel(!cartPanel)
                }}
                style={{
                    fontSize: '200%',
                    cursor: "pointer",
                }}
            />
        </Badge>),
        // getItem(<Link to="/Admin" className="font_link">Admin</Link>, "/Admin", <UserSwitchOutlined style={{fontSize: '120%'}} />, [], !userStore.IsUserAdmin()),
        // getItem(<Link to="/User" className="font_link">User</Link>, "/User", <SolutionOutlined style={{fontSize: '120%'}}/>),
    ];

    const mobileItems: MenuItems[] = [
        getItem(<Link to="/" className="font_link">Home</Link>, "/", <HomeOutlined className="menu-icon"/>),
        getItem(<Link to="/about" className="font_link">About</Link>, "/about", <TeamOutlined className="menu-icon" />),
        getItem(<Link to={"/OrderForm"} className="font_link">Order Form</Link>, "/OrderForm",  <FileTextOutlined />),
        getItem(<Link to="/SiteVisit" className="font_link">Book a Site Visit</Link>, "/SiteVisit", <EnvironmentOutlined />),
        getItem(<Link to="/ContactUs" className="font_link">Contact Us</Link>, "/ContactUs", <CommentOutlined/>),
        getItem(<Link to="/Articles" className="font_link">Blog</Link>, "/Articles", <BookOutlined />),
        getItem( "", "", <Badge count={UserCartStore.CartLength} color="#faad14">
            <ShoppingCartOutlined
                onClick={() => {
                    setCartPanel(!cartPanel)
                }}
                style={{
                    fontSize: '200%',
                    cursor: "pointer",
                }}
            />
        </Badge>),
    ];

    //
    // window.addEventListener('beforeunload', (event) => {
    //           // Cancel the event as stated by the standard.
    //     event.preventDefault();
    //     // Chrome requires returnValue to be set.
    //     event.returnValue = null;
    //     UserCartStore.SaveCartToStorage();
    //     return;
    // });

    return (
        <div
        >
            <MediaQuery minWidth={900}>
                <Layout >

                    <Header className="header"
                        style={{
                            top: 0,
                            zIndex: 99,
                            height: '17vh',
                            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)',
                          }}
                    >
                        <Row
                            className="RowLogo"
                            style={{
                            }}
                        >
                            <Col span={4}>
                                <img
                                    className="logo"
                                    alt="bayside"
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                    src="../../assets/icons/BaysideLogo.png"
                                    style={{
                                        width: '90%',
                                        cursor: 'pointer',
                                        marginBottom: '2vw'
                                    }}
                                />
                            </Col>
                            <Col
                                span={20}
                                style={{
                                    marginTop: "1vw",
                                }}
                                >
                                {/*<Row style={{width: "100%"}}>*/}
                                {/*        <hr style={{height: "4vh", background: 'url(../../assets/images/waves.png)', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)', borderRadius: "20px" , width: '100vw', marginLeft: '10vw'}}/>*/}
                                {/*</Row>*/}
                                <Row

                                >
                                    <Col
                                        span={20}
                                    >
                                        <Row>
                                            <Col span={22}
                                            >
                                                <Menu
                                                    style={{
                                                        paddingLeft: '2vw',
                                                        alignSelf: 'center',
                                                        width: "122%",
                                                        borderRadius: '25px',
                                                        background: '#ffffff',
                                                        boxShadow: '5px 10px rgba(255, 255, 255, 0.3)',
                                                    }}
                                                    className="menu"
                                                    mode="horizontal"
                                                    items={items}
                                                    selectedKeys={[window.location.pathname]}
                                                />

                                            </Col>
                                            <Col span={2}  style={{
                                                marginTop: "1vh",
                                                marginLeft: 'auto',
                                                marginRight: 0,
                                                display: 'flex'
                                            }}>
                                            </Col>

                                        </Row>

                                    </Col>

                                </Row>

                            </Col>
                        </Row>
                        <FloatButton  badge={{count: UserCartStore.CartLength}} icon={<ShoppingCartOutlined />} onClick={() => setCartPanel(!cartPanel)}/>

                    </Header>
                    <Content className="site-layout" style={{}}>
                        {loginMoal &&
                            <LoginModal
                                cancelstate={()=>{
                                    setLoginModal(false)
                                }}
                                okfunction={()=>{}}
                            />
                        }
                            <CartPanel
                                isOpen={cartPanel}
                                closeDrawer={() => {setCartPanel(false)}}
                            />
                        {props.children}
                    </Content>
                    <Footer style={{ backgroundColor: "#424243" }}>
                        <Row>
                            <Col
                                className={"font_link"}
                                span={6}
                                style={{
                                    fontSize: '110%',
                                    color: 'whitesmoke',
                                    paddingRight: '2%'
                                }}
                            >
                                <p >Bayside PlasterBoard ©2024</p>
                                <a href={`tel:0732454488`} style={{color: 'white', fontSize: '130%'}}>
                                    <Row>
                                        <PhoneOutlined/>
                                        <Divider type={"vertical"}/>
                                        <p>07 3245 4488</p></Row>
                                </a>
                                <div
                                    onClick={() => {
                                        const newWindow = window.open("https://maps.app.goo.gl/g84yYyaKK4zTEUfC9", '_blank', 'noopener,noreferrer')
                                        if (newWindow) newWindow.opener = null;
                                    }}
                                    style={{cursor: "pointer",}}
                                >
                                    <Row>
                                            <HeatMapOutlined/>
                                            <Divider type={"vertical"}/>
                                            <p>367 Thynne Rd, Morningside QLD 4170</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={1}
                                 style={{textAlign: "right" , marginBottom: '0'}}
                            ></Col>
                            <Col span={8} style={{ justifyContent: 'center', marginRight: 'auto', marginLeft: 'auto' , display: 'flex'}}>
                                <img
                                    className="logo"
                                    alt="bayside"
                                    onClick={() => {
                                        window.location.href = "/";
                                    }}
                                    src="../../assets/icons/BaysideLogo.png"
                                    style={{
                                        width: '70%',
                                    }}
                                />
                             </Col>
                            <Col span={4} style={{textAlign: "right" , marginBottom: '0'}}>
                                <Link to={"/OrderForm"} className={"font_link"} style={{color: 'orange', fontWeight: 'bolder'}}><p >Order Form</p></Link>
                                {/*<Link to={"/catalogue"} className={"font_link"} style={{color: 'white'}}><p style={{boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)"}}>Catalogue</p></Link>*/}
                                <Link to={"/about"} className={"font_link"} style={{color: 'orange', fontWeight: 'bolder'}}><p>About Us</p></Link>
                                <Link to={"/Articles"} className={"font_link"} style={{color: 'orange', fontWeight: 'bolder'}}><p>Blog</p></Link>
                                <Link to="/SiteVisit" className={"font_link"} style={{color: 'orange', fontWeight: 'bolder'}}><p >Book A Site Visit</p></Link>
                            </Col>
                            <Col span={4}
                                 style={{textAlign: "right" , marginBottom: '0'}}
                            >
                                <InstagramOutlined style={{fontSize: '400%', cursor: 'pointer'}} onClick={() => {
                                    const newWindow = window.open("https://www.instagram.com/bayside_plasterboard/", '_blank', 'noopener,noreferrer')
                                    if (newWindow) newWindow.opener = null;
                                }}/>

                                <FacebookFilled style={{fontSize: '400%', cursor: 'pointer'}}  onClick={() => {
                                    const newWindow = window.open("https://www.facebook.com/baysideplasterboard", '_blank', 'noopener,noreferrer')
                                    if (newWindow) newWindow.opener = null;
                                }}/>

                                <LinkedinOutlined style={{fontSize: '400%', cursor: 'pointer'}} onClick={() => {
                                    const newWindow = window.open("https://www.linkedin.com/company/bayside-plasterboard/", '_blank', 'noopener,noreferrer')
                                    if (newWindow) newWindow.opener = null;
                                }}/>
                                <Link className={"font_link"} to={"/terms-conditions"} style={{color: 'white'}}><p> Terms And Conditions </p></Link>
                                <Link className={"font_link"} to={"/privacy-policy"} style={{color: 'white'}}><p> Privacy-Policy </p> </Link>
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </MediaQuery>
            <MediaQuery maxWidth={901}>
                <div>
                    <Layout>
                        <FloatButton.BackTop ></FloatButton.BackTop>
                        <Header className={"header"} style={{
                            height: '100%',
                            display: 'flex'
                        }}>
                            <Row>
                                <Col span={12}>
                                    <img
                                        className="logo"
                                        alt="bayside"
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                        src="../../assets/icons/BaysideLogo.png"
                                        style={{
                                            width: '90%',
                                            cursor: 'pointer',
                                            paddingTop: '5%',
                                            marginLeft: '0',
                                            paddingLeft: 0
                                        }}
                                    />
                                </Col>
                                <Col span={12}>
                                    <Menu
                                        style={{
                                            borderRadius: '25px',
                                            background: '#ffffff',
                                            boxShadow: "boxShadow: '5px 10px rgba(255, 255, 255, 0.3)",
                                            padding: '5%',
                                            marginTop: '5%',
                                            marginRight: '1%',
                                            width: '90%',
                                            marginLeft: 'auto'
                                        }}
                                        overflowedIndicator={<BarsOutlined style={{fontSize: '200%', color: 'black'}}/>}
                                        className="menu"
                                        mode="horizontal"
                                        items={mobileItems}
                                        selectedKeys={[window.location.pathname]}
                                    />
                                </Col>
                            </Row>

                        </Header>
                        <Content style={{height: '100%'}}>
                            {props.children}
                        </Content>
                        <Footer style={{backgroundColor: "#424243", marginBottom: 0, flex: 'auto', marginTop: 'auto'}}>
                            <Row>
                                <Col span={12}
                                     style={{}}
                                >
                                    <img
                                        className="logo"
                                        alt="bayside"
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                        src="../../assets/icons/BaysideLogo.png"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                            paddingTop: '5%',
                                            marginLeft: '0'
                                        }}
                                    />
                                </Col>
                                <Col span={12}
                                     style={{textAlign: "right", marginBottom: '0'}}
                                >

                                    <img
                                        className="logo"
                                        alt="bayside"
                                        onClick={() => {
                                            const newWindow = window.open("https://www.facebook.com/baysideplasterboard", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                        src="../../assets/icons/fb.png"
                                    />

                                    <img
                                        className="logo"
                                        alt="bayside"
                                        onClick={() => {
                                            const newWindow = window.open("https://www.instagram.com/bayside_plasterboard/", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}

                                        style={{
                                            cursor: "pointer"
                                        }}
                                        src="../../assets/icons/insta.png"
                                    />
                                </Col>
                            </Row>
                        </Footer>
                    </Layout>
                </div>
            </MediaQuery>
        </div>
    );
}

export default observer(Overlay);