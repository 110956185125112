import {observer} from "mobx-react-lite";
import Overlay from "../../componets/overlay/overlay";
import {Card, Col, Descriptions, Image, List, Row, Tabs} from "antd";
import {MinusOutlined} from "@ant-design/icons";
import {FiberCerment} from "./FiberCerment";
import PlasterBoardRange from "./PlasterBoardRange";
import {Compounds} from "./Compounds";
import {Cornice} from "./Cornice";
import {Fixings} from "./Fixings";
import {CeilingSystems} from "./CeilingSystems";
import {Insulation} from "./Insulation";
import {AccessPanels} from "./AccessPanels";
import {SteelFraming} from "./SteelFraming";

// https://web.archive.org/web/20200311145501/https://www.baysideplasterboard.com.au/product-category/fibre-cement-products/
function ProductRangeMain() {
    return (
        <div>
            <Overlay>
                <Tabs centered className={"font_link"}>
                    <Tabs.TabPane tab="Plasterboard" key="1">
                        <PlasterBoardRange />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Cornice" key="9">
                        <Cornice />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Access Panels" key="10">
                        <AccessPanels />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Steel framing" key="2">
                        <SteelFraming />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Insulation" key="3">
                        <Insulation />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Fibre cement" key="4">
                        <FiberCerment />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Compounds & adhesives" key="5">
                        <Compounds />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Fixing & fasteners" key="6">
                        <Fixings />
                    </Tabs.TabPane>
                    {/*<Tabs.TabPane tab="Tools & accessories" key="7">*/}
                    {/*    Content of Tab Pane 3*/}
                    {/*</Tabs.TabPane>*/}
                    <Tabs.TabPane tab="Ceiling systems" key="8">
                        <CeilingSystems />
                    </Tabs.TabPane>
                </Tabs>
            </Overlay>
        </div>
    );
}

export default observer(ProductRangeMain);