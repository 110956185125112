import {Card, Col, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function Fixings() {
    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>
            <Row>
                <Col span={11}>
                    <Card title={"Needle Point Bugle Head Zinc Yellow – Coarse Thread Collated Strip for Fixing Plasterboard"}>
                        <Row>
                            <Image src={"./assets/images/productRanges/Needle-Point-Bugle-Head-Zinc-Yellow-E28093-Coarse-Thread-Collated-Strip-for-Fixing-Plasterboard-Featured-Image.jpg"} alt={"Needle Point Bugle Head Zinc Yellow"}/>
                            <Image src={"./assets/images/productRanges/needlePointCollated.png"}  alt={"Needle Point Bugle Head Zinc Yellow Range"}/>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Needle Point Bugle Head Zinc Yellow – FineThread Collated Strip for Fixing Plasterboard"}>
                        <Row>
                            <Image src={"./assets/images/productRanges/Needle-Point-Bugle-Head-Zinc-Yellow-E28093-Coarse-Thread-Collated-Strip-for-Fixing-Plasterboard-Featured-Image.jpg"} alt={"Needle Point Bugle Head Zinc Yellow"}/>
                            <Image src={"./assets/images/productRanges/Needle-Point-Bugle-Head-Zinc-Yellow-–-Coarse-Thread-for-Fixing-Plasterboard-Specifications.png"}  alt={"Needle Point Bugle Head Zinc Yellow Range"}/>
                        </Row>
                    </Card>
                </Col>

            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col span={11}>
                    <Card title={"Needle Point CSK Villaboard 4 Nibb Zinc Yellow – 8 Gauge – Self Embedding Head"}>
                        <Row>
                            <Image src={"./assets/images/productRanges/Needle-Point-CSK-Villaboard-4-Nibb-Zinc-Yellow-E28093-8-Gauge-E28093-Self-Embedding-Head-Featured-Image.jpg"} alt={"Needle point csk"}/>
                            <Image src={"./assets/images/productRanges/Needle-Point-CSK-Villaboard-4-Nibb-Zinc-Yellow-–-8-Gauge-–-Self-Embedding-Head-Specifications.png"} alt={"Needle point csk range"}/>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Needle Point Bugle Head Zinc Yellow – Coarse Thread for Fixing Plasterboard"}>
                        <Row>
                            <Image src={"./assets/images/productRanges/Needle-Point-CSK-Villaboard-4-Nibb-Zinc-Yellow-E28093-8-Gauge-E28093-Self-Embedding-Head-Featured-Image.jpg"} alt={"Needle point csk"}/>
                            <Image src={"./assets/images/productRanges/Needle-Point-Bugle-Head-Zinc-Yellow-–-Coarse-Thread-for-Fixing-Plasterboard-Specifications-1.png"} alt={"Needle point csk range"}/>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
