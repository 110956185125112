import { Col, Image, Row } from "antd";

function AboutUs() {
    return (
        <div>
            <h2>About Us</h2>

            <p>Our mission is to provide a platform for local businesses to sell their products and services to the community.</p>
            <p>Our goal is to help local businesses grow and thrive in the community.</p>
            <p>Our vision is to create a community where local businesses can thrive and grow.</p>
            <h3>Meet Our Team</h3>
            <Row>
                 <Col span={8}>
                    <Image src="../../assets/images/staffheadshot-2.jpg" height="40vh" width="15vw" />
                    <h4>John Doe</h4>
                    <p>CEO</p>
                </Col>
            </Row>
        </div>
    );
}

export default AboutUs;