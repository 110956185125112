import Overlay from "../../componets/overlay/overlay";
import {Helmet} from "react-helmet";
import React from "react";

export function PrivacyPolicy() {
    return (

        <div>
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content={"Privacy Policy"} />
                <meta name="keywords" content="Privacy Policy" />
                <meta property="og:title" content="Privacy Policy" />
                <meta property="og:description" content="Privacy Policy" />
            </Helmet>
            <Overlay>
                <div style={{padding: '5%', fontSize: '120%'}}>
                    <h2>Privacy Policy</h2>
                    <p>We are committed to protecting your privacy. Authorized employees within the company on a need to
                        know basis only use any information collected from individual customers. We constantly review
                        our systems and data to ensure the best possible service to our customers. Government has
                        created specific offences for unauthorised actions against computer systems and data. We will
                        investigate any such actions with a view to prosecuting and/or taking civil proceedings to
                        recover damages against those responsible</p>

                </div>
            </Overlay>
        </div>
    );
}
