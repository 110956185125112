import {Card, Col, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function Compounds() {
    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>
            <Row>
                <Col span={11}>
                    <Card title={"Cornice Adhesive 45, 60 & 90 Compound"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    Cornice Adhesive is a plaster-based adhesive compound. It is formulated for bonding
                                    paper-faced and plaster cornices to plasterboard surfaces and reinforcing joints in
                                    cornices. Cornice Adhesive may also be used for bonding other plaster decorative
                                    products, back-blocking plasterboard joints and laminating plasterboard sheets.
                                </p>
                                <p>
                                    Cornice Adhesive now has a new, smoother and creamier formula!
                                </p>
                                <p>
                                    Cornice Adhesive is available in 10kg & 20kg bags – perfect for small and larger
                                    jobs.
                                </p>
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Cornice-Adhesive-45-60-90-Compound.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"BaseCote™ 45, 60 & 90 Jointing Compound"}>
                        <Row>
                            <Col span={24}>
                                <List
                                    dataSource={["Consistent working times.",
                                        "Mixes easily to a creamy gauge. Soaking is not essential.",
                                        "Flow smoothly under trowel and from mechanical tools.",
                                        "Excellent joint filling with superior adhesion, high-strength and low shrinkage."
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Col>
                            <Col span={11}>
                                <p>
                                BaseCote™ is a leading plaster-based setting compound designed for taping and basecoating plasterboard joints, angles and fastener heads – including SHEETROCK® Plasterboard and FIBEROCK®.
</p>
                                <p>
                                BaseCote™ has superior joint filling compound characteristics with smooth flow by hand or machine, offering a strong and durable bond with low shrinkage.
</p>
                                <p>
                                Available in 45, 60 and 90 minutes working time.
</p><p>
                                BaseCote™ is now available in 10kg bags – perfect for small jobs
                                </p>

                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Basecoat-45.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col span={11} >
                    <Card title={"Back-Blocking Adhesive"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    Back-blocking is a mandatory fixing practice described in AS/NZS 2589:2007 Gypsum linings – Application and finishing.
                                </p><p>
                                    Back-Blocking Adhesive is a plaster-based setting type compound specially formulated for back-blocking ceiling joints or wall joints. Back-blocking reinforces plasterboard joints and minimises cracking and peaking that results from building movement.
                                </p>
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Back-Blocking-Adhesive.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2} ></Col>
                <Col span={11} >
                    <Card title={"Masonry Adhesive Compound"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    Masonry Adhesive is a plaster based adhesive compound formulated to bond plasterboard sheets to masonry, brick or concrete walls. Masonry Adhesive compound has high bond strength, offering a long setting time and is suitable for bonding to difficult surfaces.
                                </p>
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Masonry-Adhesive-Compound.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col span={11} >
                    <Card title={"All Purpose premix"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    USG Boral All Purpose Premix compound is an economical, air-drying, lightweight compound suitable for all three coat applications or as a finishing coat for all plasterboard joints, angles and fastener heads. It can be used in conjunction with USG Boral BaseCote®, SHEETROCK® Brand Easy Sand™ or USG Boral RediBase™ compound.
</p>
                                <p>
                                    High quality paper tape must be used such as SHEETROCK® Paper Joint Tape when embedding with All Purpose Premix.
                                </p>
                                <h4>Advantages include:</h4>
                                <List dataSource={[
                                    "Can be used for all three coats or as a finishing coat.",
                                    "Excellent workability, easy application by hand or with mechanical tools.",
                                    "Minimal mixing and cleaning of tools.",
                                    "OK to sand by hand or with mechanical sanding tools.",
                                    "Excellent surface for painting.",
                                    ]}
                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/All-Purpose-Premix.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2} ></Col>
                <Col span={11} >
                    <Card title={"Premium Bond™ Stud Adhesive"}>
                        <Row>
                            <Col span={11}>
                                <p>The butter consistency is designed for easy no-slump application, fast grab and maximum adhesive transfer.

                                    Premium Bond™ Stud Adhesive is a water-based synthetic stud adhesive used for bonding plasterboard to timber and metal studs for wall and ceiling linings.</p>
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Premium-Bond™-Stud-Adhesive.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
