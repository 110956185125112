import {observable, action, computed, reaction, makeAutoObservable} from "mobx"
import { createContext } from "react"
import {CatalogueItem} from "./catalogue.store";
import {message} from "antd";
import axios from "axios";
import baseUrl from "./Utility";
class OrderStore {
    orders: any[] = []
    Token: string = ""
    UserFilter: number = -1
    FilterWords: string = ""
    guestOrders: any[] =[]

    viewModalOpen: boolean = false;

    OrderBeingViewed: any = {reference: "8908jllkj"}
    OpenOrder: number = -1;

    constructor() {
        makeAutoObservable(this);
    }

    get pendingOrders() {
        let results = this.orders;
        if (this.UserFilter >= 0) {
            results = results.filter(item => {return (item.UserId == this.UserFilter)})
        }
        if (this.FilterWords !== "" && this.FilterWords != null) {
            results = results.filter(item => {return(item.Reference.toLowerCase().includes(this.FilterWords.toLowerCase()))})
        }
        return results.filter(item => {return (item.Approved == false)})
    }

    get ApprovedOrders() {
        let results = this.orders;
        if (this.UserFilter >= 0) {
            results = results.filter(item => {return (item.UserId == this.UserFilter)})
        }
        if (this.FilterWords !== "" && this.FilterWords != null) {
            results = results.filter(item => {return(item.Reference.toLowerCase().includes(this.FilterWords.toLowerCase()))})
        }
        return results.filter(item => {return (item.Approved == true)})
    }

    get displayOrderDetails() {
        console.log(this.orders)
        let order = this.orders.findIndex(item => {return( item.id === this.OpenOrder )})
        if (order == -1) return [];
        return this.orders[order].Cart;
    }

    async getGuestOrders(token: string) {
        let OrderArray: any[];
        this.Token = token;
        OrderArray = [];
        const requestConfig = {
            method : "Get",
            url :  `${baseUrl()}/GetOrdersGuest`,
            headers : {
                "Content-Type":"application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: token ? `${token}` : "",
            }
        }
        console.log(requestConfig)
        await axios(requestConfig)
            .then((response) => {
                OrderArray = response.data
                console.log(OrderArray);
            })
            .catch()
        this.guestOrders = OrderArray;
    }

    get guestOrdersDisplay() {
        return this.guestOrders
    }

    async getOrders(token: string) {
        let OrderArray: any[];
        this.Token = token;
        OrderArray = [];
        const requestConfig = {
            method : "Get",
            url :  `${baseUrl()}/GetOrders`,
            headers : {
                "Content-Type":"application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: token ? `${token}` : "",
            }
        }
        console.log(requestConfig)
        await axios(requestConfig)
            .then((response) => {
                OrderArray = response.data
                console.log(OrderArray);
            })
            .catch()
        this.orders = OrderArray;
    }

    async approveOrder(id: number) {
        console.log(id)
        const requestConfig = {
            method : "Post",
            url :  `${baseUrl()}/ApproveOrder`,
            headers : {
                "Content-Type":"application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: this.Token ? `${this.Token}` : "",
            },
            data: {Order: id}
        }
        console.log(requestConfig)
        await axios(requestConfig)
            .then((response) => {
                message.success("Order Approved!")
                this.getOrders(this.Token)
            })
            .catch()
    }

    deleteOrder(id: number) {
        
    }

    viewOrder(id: number) {
        this.OpenOrder = id
        this.viewModalOpen = true;
    }
}

export default createContext(new OrderStore())
