import {Card, Col, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function Cornice() {
    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>
            <Row>
                <Col span={11}>
                    <Card title={"Cairo 50, 75 & 100mm Profile – Decorative Cornice"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    Cairo cornice is stepped and sharply defined with clean crisp angles. This distinctive angular cornice is available in 2 step as well as 3 and 4 step profiles, making it easy to create a sensation of height and space in any room.
                                </p><p>
                                    Cairo’s aesthetically striking profile can be used across a diverse range of decors, and is especially suited to modern interiors.
                                </p>
                                <p>Cairo is available as:</p>
                                <List dataSource={[
                                    "Cairo 2 Step (50mm)",
                                    "Cairo 3 Step (75mm)",
                                    "Cairo 4 Step (100mm)",
                                    "Contemporary paper-faced designs",
                                    "Precision machine extruded providing a smooth finish",
                                    "Sharp distinct lines",
                                    "Variety of sizes",
                                    "Consistent Quality",
                                ]}
                                      renderItem={(item, index) => (<p><MinusOutlined />{item}</p>)}
                                      />
                                <h5>Physical Properties</h5>
                                <List dataSource={[
                                   "Paper-faced decorative cornice",
                                   "Profiles : 50, 75 & 100mm",
                                   "Length: 4200mm",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined />{item}</p>)}
                                      />
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Cairo_Cornice_Inside_06-870x569_en.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Linear 75mm Square Edge Profile – Decorative Cornice"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    An ideal upgrade from standard cornice, Linear is the obvious design alternative when considering a square edge finish.
</p><p>
                                    Characterised by its minimal, architectural lines; the stylish 75mm square edge profile is a perfect complement for modern residential and commercial interiors.
</p><p>
                                    Linear is suitable for the discerning individual looking for the next generation in cornice. Linear is at the forefront of contemporary design.
                                </p>
                                <List dataSource={[
                                    "Clean, architectural lines",
                                    "Alternative to square setting",
                                    "Easy installation",
                                    "Ideal upgrade to standard cornice",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined />{item}</p>)}
                                      />
                                <h5>Physical Properties</h5>
                                <List dataSource={[
                                    "Paper-faced decorative cornice",
                                    "Profile: 75mm",
                                    "Length: 4200mm",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined />{item}</p>)}
                                      />
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/LinearCornice-cover_med-870x569_en.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row
                style={{marginTop: '1%'}}
            >
                <Col span={11}>
                    <Card title={"Manly 75mm Profile – Decorative Cornice"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    Upgrade from a standard profile with Manly decorative cornice. With a stylish fresh appearance, Manly offers a decorative touch with an understated smooth contour combining for a clean, flawless statement.
                                </p>
                                <p>
                                    Manly is a 75mm profile that will highlight any room environment. It is well suited for most ceiling heights as well as a majority of interiors.
                                </p>
                                <List dataSource={[
                                    "Contemporary paper-faced designs",
                                    "Machine extruded providing a perfect finish",
                                    "Distinctly contoured, subtle lines",
                                    "Easy installation",
                                    "Ideal upgrade from standard cornice",
                                    "Consistent quality",
                                ]}
                                      renderItem={(item, index) => (<p><MinusOutlined />{item}</p>)}
                                      />
                                <h5>Physical Properties</h5>
                                <List dataSource={[
                                   "Paper-faced decorative cornice",
                                   "Profiles : 75mm",
                                   "Length: 4200mm",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined />{item}</p>)}
                                      />
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/KEY_Manly_Cornice_Cover_05-870x569_en.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"New York 90mm Profile – Decorative Cornice"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    The clean, straight lines and sleek curves of New York cornice combine understated elegance with a modern edge. This stylish 90mm profile adds an element of refinement, sophistication and space to a room minus the exclusive price tag.
                                </p>
                                <p>New York decorative cornice is perfect for</p>
                                <List dataSource={[
                                   "Contemporary paper faced designs",
                                   "Machine extruded providing a perfect finish",
                                   "Gentle curve, subtle lines",
                                   "Retrofit over 55mm standard cornice",
                                   "Easy installation",
                                   "Ideal upgrade from standard cornice",
                                   "Consistent quality",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined />{item}</p>)}
                                      />
                                <h5>Physical Properties</h5>
                                <List dataSource={[
                                    "Paper-faced decorative cornice",
                                    "Profile: 90mm",
                                    "Length: 4200mm",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined />{item}</p>)}
                                      />
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/NewYorkCornice-870x569_en.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col span={12}>
                    <Card title={"Sydney 90mm Profile – Decorative Cornice"}>
                        <Row >
                            <Col span={11}>
                                <p>The combination of smooth curves and straight lines, Sydney is simply a stunning 90mm
                                    decorative cornice which adds style and elegance to any room.
                                </p><p>
                                An ideal upgrade from standard cornice, Sydney exemplifies a classic timeless look and
                                is bound to provide visual dominance to a broad selection of interior types.</p>
                                <List dataSource={[
                                    "Contemporary paper-faced designs",
                                    "Machine extruded providing a perfect finish",
                                    "Easy installation",
                                    "Ideal upgrade from standard cornice",
                                    "Consistent quality",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined/>{item}</p>)}
                                />
                                <h5>Physical Properties</h5>
                                <List dataSource={[
                                    "Paper-faced decorative cornice",
                                    "Profile: 90mm",
                                    "Length: 4200mm",
                                ]}
                                      renderItem={(item) => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/KEY_Sydney_CorniceCover-870x569_en.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>

                    </Card>
                </Col>
            </Row>
        </div>
    );
}

