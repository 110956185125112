import {FileTextOutlined} from "@ant-design/icons";
import {Card, Divider, Image, Row, Space} from "antd";
import Meta from "antd/es/card/Meta";
import React, {useContext} from "react";
import resourceStore from "../../stores/resource.store";
import {observer} from "mobx-react-lite";
import {useNavigate} from "react-router-dom";
import baseUrl from "../../stores/Utility";
import "../../componets/overlay/overlay.css";

function ArticleTile({ColId, RowId, PageId}: {ColId: number, RowId: number, PageId: number}) {

    const navigate = useNavigate();
    const store = useContext(resourceStore)

    // Title: string, Brief: string, Id: number}

    const article = store.getResource(ColId, RowId, PageId);

    if (article === null || article === undefined) return <div></div>
    return (
        <Card
            style={{
                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                maxWidth: '95vw',
                cursor: 'pointer'
            }}
            actions={[
                <div>
                    <Row
                        style={{flex: 'auto', marginLeft: '40%', marginRight: 'auto'}}
                        onClick={() => {
                            store.setSelectArticle(article.Id);
                            navigate(`/Blog/${article.Title}`);
                        }}
                    >
                        <p className={"aboutMainBody"} style={{}}>Read more</p>
                        <FileTextOutlined onClick={() => {

                        }}

                        />
                    </Row>

                </div>]
            }
            cover={<Image src={`${baseUrl()}/resourceImage/${article.Id}`}
                          style={{padding: '2%', height:"100%", width:"100%"}}/>}
            onClick={() => {
                store.setSelectArticle(article.Id);
                navigate(`/Blog/${article.Title}`);
            }}
        >
            <Meta
                className={"font_link"}
                style={{flexWrap: 'wrap', height: '15vh'}}
                title={ article.Title}
                description={article.SubTitle}
            />
        </Card>
    );
}

export default observer(ArticleTile)
