import {Col, Form, InputNumber, Row} from "antd";

export default function OrderFormRow({Title, col1, col2, lengthensCol1, lengthensCol2} : {Title: string, col1: string, col2: string, lengthensCol1: string[][], lengthensCol2: string[][]}) {
    if (lengthensCol2.length === 0) {
        return (
            <div>
                <h3>{Title}</h3>
                <Row>
                    <Col span={24}>
                        <h4>{col1}</h4>
                        {lengthensCol1.map(item => {
                            return (
                                <Form.Item name={item[0]}>
                                    <InputNumber addonBefore={item[1]}/>
                                </Form.Item>
                            )
                        })}
                    </Col>
                </Row>
            </div>
        )
    }
    return (
        <div>
            <h3>{Title}</h3>
            <Row>
                <Col span={11}>
                    <h4>{col1}</h4>
                    {lengthensCol1.map(item => {
                        return (
                            <Form.Item name={item[0]}>
                                <InputNumber addonBefore={item[1]}/>
                            </Form.Item>
                        )
                    })}
                </Col>
                <Col span={1}></Col>
                <Col span={11}>
                    <h4>{col2}</h4>
                    {lengthensCol2.map(item => {
                        return (
                            <Form.Item name={item[0]}>
                                <InputNumber addonBefore={item[1]}/>
                            </Form.Item>
                        )
                    })}
                </Col>
            </Row>
        </div>
    );
}
