 import React, { useContext, useEffect, useState } from "react";
import Overlay from "../../componets/overlay/overlay";
import {Button, Card, Col, Divider, Image, Input, Modal, Pagination, Row, Space, Table, Tabs} from "antd";

import MediaQuery from "react-responsive";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import resourceStore from "../../stores/resource.store";
import Meta from "antd/es/card/Meta";
import ArticleTile from "./ArticleTile";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {LeftCircleOutlined} from "@ant-design/icons";
 import baseUrl from "../../stores/Utility";
 import {ArrowRight} from "react-bootstrap-icons";

function MarketingResourceView() {
    const store = useContext(resourceStore)
    const navigate = useNavigate();

    useEffect(() => {
        store.downloadResources()
    }, []);

    useEffect(() => {
        store.SetArticleToLatest()
    }, [store.resourceArray]);

    return (
        <div>
            <div className="Resource" style={{
                height: '100%',
                marginLeft: '10%',
                marginRight: '10%',
                padding: '1%',
                color: 'white'
            }}
            >
                <Row
                    className="mainback"
                    style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}
                >
                    <h1 className={"font_link"} >{store.SelectArticle.Title}</h1>
                    <Divider style={{borderBottom: '1px solid #ccc'}}/>
                </Row>
                <Row
                    style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                    }}
                >
                    <Image src={`${baseUrl()}/resourceImage/${store.SelectArticle.Id}`} height="50%" width="70%"
                           style={{
                               borderRadius: '10px',
                               boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                           }}/>
                </Row>
                <Row>
                    <Col
                        className={"articleMainBody"}
                        style={{
                            fontSize: '115%'
                        }}
                    >
                        {store.formatForMarketingDisplay(store.SelectArticle.Article)}
                    </Col>

                </Row>
                <Row>
                    <Button
                        type={"primary"}
                        icon={<ArrowRight />}
                        style={{paddingLeft: '1%', backgroundColor: 'whitesmoke', color: 'black'}}
                        onClick={() => {
                            navigate(`/Blog/${store.SelectArticle.Title}`)
                        }}
                    >Read Full Article</Button>
                </Row>
            </div>
        </div>
    );
}

 export default observer(MarketingResourceView);