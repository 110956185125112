import React from "react";
import {Carousel, Divider, Image, Row} from "antd";
import MediaQuery from "react-responsive";
import {OpeningSIgnUpForm} from "./OpeningSIgnUpForm";
import {useNavigate} from "react-router-dom";
import MarketingResourceView from "../../pages/Resources/MarketingResourceView";


const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '100%',
    color: '#fff',
    textAlign: 'center',
};

interface MarketingCompProps {
    afterChange: unknown
}
function MarketingComp() {
    const navigate = useNavigate();
    return(
        <div
            style={{
                width: "100%",
                background: 'rgba(42, 42, 43, 0.9)',
                borderRadius: '20px',
                color: 'white',
                boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)'
            }}
        >
            <Carousel
                autoplay={false}
                autoplaySpeed={3500}
                dotPosition={'bottom'}
                style={{
                    height: '100%'
                }}
            >
                <div>
                    <MarketingResourceView />
                </div>

                <div className="font_link">

                    <Row style={{paddingLeft: '2%', paddingRight: '2%', paddingTop: '2%'}}>
                        <Image src="../../assets/images/brushed-concrete-linea-modern-exterior.jpg" height={"30%"}
                               width={"30%"}/>
                        <Divider type={"vertical"}/>
                        <Image src="../../assets/images/img.png" height={"30%"} width={"60%"}/>

                    </Row>
                    <h1 style={contentStyle}>James Hardie Linea™ Weatherboard</h1>
                    <MediaQuery minWidth={901} >
                        <Row>
                            <p style={{padding: "2%", paddingBottom: 0, fontSize: "120%", color: 'white'}}
                               className={"articleMainBody"}>
                                The clean horizontal lines of Linea™ weatherboards work beautifully
                                on the expansive external walls of modern architecture. Ideal for creating a Hamptons or
                                coastal inspired look when combined with contrasting Axent Trims in new homes or
                                renovations, Linea can also be confidently painted in dark colours.
                            </p>
                            <p style={{padding: "2%", fontSize: "120%", paddingTop: 0, color: 'white'}} className={"articleMainBody"}>
                                Innovative and durable,
                                Linea weatherboards are resistant to shrinking, swelling and cracking and will hold
                                paint
                                longer than wood. Featuring the distinctive charm of a deep shadow weatherboard without
                                the
                                maintenance of timber, the unmatched thickness for fibre cement weatherboards of 16mm
                                which
                                also enable handy tongue and groove short ends for clean butt joins even off-stud.
                            </p>
                        </Row>
                    </MediaQuery>
                </div>

            </Carousel>
        </div>
    )
}

export default MarketingComp;
