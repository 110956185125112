import Overlay from "../../componets/overlay/overlay";
import MediaQuery from "react-responsive";
import {Col, DatePicker, DescriptionsProps, Divider, Form, Image, Input, message, Row, Spin} from "antd";
import React, {useState} from "react";
import {useForm} from "antd/es/form/Form";
import Button from "antd/es/button";
import Radio from "antd/es/radio";
import TextArea from "antd/es/input/TextArea";
import {EnvironmentOutlined, MailOutlined, PhoneOutlined} from "@ant-design/icons";
import axios from "axios";
import baseUrl from "../../stores/Utility";
import {Helmet} from "react-helmet";

export const ContactUsPage = () => {
    const [form] = useForm();
    const [requestLoad, setRequestLoad] = useState(false)
    const [FormEnabled, setFormEnabled] = useState(true)

    const onFormFinish = (values: any) => {
        setRequestLoad(true)
        setFormEnabled(false)
        axios({
            method : "Post",
            url :  `${baseUrl()}/ContactUs`,
            headers : {
                "Content-Type":"application/json",
            },
            data: values
        }).then(res => {
            message.success("Request Sent")
            onFormClearClick()
            setRequestLoad(false)
            setFormEnabled(true)
        }).catch(()=>{
            message.error("Please Retry")
            setRequestLoad(false)
            setFormEnabled(true)
        })
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    return (
        <div>
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" content={"Contact Bayside PlasterBoard"} />
                <meta name="keywords" content="plasterboard, Bayside, Contact Us" />
                <meta property="og:title" content="Contact Us" />
                <meta property="og:description" content="Contact Bayside PlasterBoard" />
            </Helmet>
            <Overlay>
                <MediaQuery minWidth={901}>
                    <div className="Resource" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: '100%',
                        minHeight: '70vh',
                        marginLeft: '15%',
                        marginRight: '15%',
                        marginTop: '2%'
                    }}
                    >
                        <h1 className={"font_link"}> Contact us </h1>
                        <Row
                            className="mainback"
                            style={{

                            }}
                        >

                            <Col span={11}
                                style={{
                                    marginRight: '2%'
                                }}
                            >
                                <div>
                                    <Form
                                        form={form}
                                        name="basic"
                                        layout="horizontal"
                                        initialValues={{remember: true}}
                                        onFinish={onFormFinish}
                                        disabled={!FormEnabled}
                                        onFinishFailed={onFormFinishFailed}

                                    >
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item label="Company Name" name="Company">
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Divider type={"vertical"}/>
                                            <Col span={12}>
                                                <Form.Item label="Contact Number" name="ContactNumber">
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item label="Contact Email" name="ContactEmail"
                                                           rules={[{required: true, message: 'Please input!'}]}>
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Divider type={"vertical"}/>
                                            <Col span={12}>
                                                <Form.Item label="Contact Name" name="ContactName"
                                                           rules={[{required: true, message: 'Please input!'}]}>
                                                    <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Form.Item label="Notes" name="Notes" style={{width: '100%'}} wrapperCol={{span: 24}}>
                                                <TextArea rows={4}/>
                                            </Form.Item>
                                        </Row>
                                        <Form.Item wrapperCol={{offset: 8, span: 20}}>
                                            <Button type="primary" htmlType="submit" style={{padding: '5px'}}
                                                    className={"font_link"}>
                                                Submit
                                            </Button>
                                            <Divider type={"vertical"}/>
                                            <Button htmlType="button" onClick={onFormClearClick}
                                                    style={{padding: '5px'}} className={"font_link"}>
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <Spin spinning={requestLoad} size={'large'} />
                                    <p>* Please allow up to 48hrs for a response from our team</p>
                                </div>
                            </Col>
                            <Col span={10} style={{marginLeft: '5%'}} className={"articleMainBody"}>
                                <h3>Our Store Locations</h3>
                                <h4>Brisbane Store</h4>
                                <div style={{marginLeft: '5%'}}>
                                    <a href={`tel:3245 4488 (1)`} style={{color: 'black'}}>
                                        <p><PhoneOutlined/>{" : 07 3245 4488 (1)"}</p>
                                    </a>
                                    <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '100%'}}>
                                        <p><MailOutlined/>{" : admin@bspb.com.au"}</p>
                                    </a>
                                    <p
                                        onClick={() => {
                                            const newWindow = window.open("https://maps.app.goo.gl/g84yYyaKK4zTEUfC9", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{cursor: 'pointer'}}
                                    ><EnvironmentOutlined/>
                                        {" : 367 Thynne Rd, Morningside QLD 4170"}
                                    </p>
                                    <h4>Opening hours</h4>
                                    <div style={{marginLeft: '5%'}}>
                                        <p>{'Monday: 5:30am :    4:00pm'}</p>
                                        <p>{'Tuesday: 5:30am :   4:00pm'}</p>
                                        <p>{'Wednesday: 5:30am : 4:00pm'}</p>
                                        <p>{'Thursday: 5:30am :  4:00pm'}</p>
                                        <p>{'Friday: 5:30am :    4:00pm'}</p>
                                    </div>

                                </div>

                                <h4>Redlands Store</h4>
                                <div style={{marginLeft: '5%'}}>
                                    <a href={`tel:07 3245 4488 (3)`} style={{color: 'black'}}>
                                        <p><PhoneOutlined/>{" : 07 3245 4488 (3)"}</p>
                                    </a>
                                    <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '100%'}}>
                                        <p><MailOutlined/>{" : redlands@bspb.com.au"}</p>
                                    </a>
                                    <p
                                        onClick={() => {
                                            const newWindow = window.open("https://maps.app.goo.gl/Qx6rauzQBuxetsWw8", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{cursor: 'pointer'}}
                                    ><EnvironmentOutlined/>{" : 2/1424 New Cleveland Rd, Chandler QLD 4155"}</p>
                                    <h4>Opening hours</h4>
                                    <div style={{marginLeft: '5%'}}>
                                        <p>{'Monday: 6:00am :    4:00pm'}</p>
                                        <p>{'Tuesday: 6:00am :   4:00pm'}</p>
                                        <p>{'Wednesday: 6:00am : 4:00pm'}</p>
                                        <p>{'Thursday: 6:00am :  4:00pm'}</p>
                                        <p>{'Friday: 6:00am :    4:00pm'}</p>
                                    </div>
                                </div>

                                <h4>Gold Coast Store</h4>
                                <div style={{marginLeft: '5%'}}>
                                    <a href={`tel:07 3245 4488 (2)`} style={{color: 'black'}}>
                                        <p><PhoneOutlined/>{" : 07 3245 4488 (2)"}</p>
                                    </a>
                                    <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '100%'}}>
                                    <p><MailOutlined/>{" : gc@bspb.com.au"}</p>
                                    </a>
                                    <p
                                        onClick={() => {
                                            const newWindow = window.open("https://maps.app.goo.gl/VS1jh2bcqivpb3Ax7", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{cursor: 'pointer'}}
                                    ><EnvironmentOutlined/>{" : 106 Eastlake St, Carrara QLD 4211"}</p>
                                    <h4>Opening hours</h4>
                                    <div style={{marginLeft: '5%'}}>
                                        <p>{'Monday: 6:00am :    3:30pm'}</p>
                                        <p>{'Tuesday: 6:00am :   3:30pm'}</p>
                                        <p>{'Wednesday: 6:00am : 3:30pm'}</p>
                                        <p>{'Thursday: 6:00am :  3:30pm'}</p>
                                        <p>{'Friday: 6:00am :    3:00pm'}</p>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={900}>
                    <div className="Resource" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: '100%',
                        minHeight: '70vh',
                        marginLeft: '15%',
                        marginRight: '15%',
                        marginTop: '2%'
                    }}
                    >
                        <h1 className={"font_link"}> Contact us </h1>
                        <Row
                            className="mainback"
                            style={{}}
                        >

                            <Col span={24}
                                 style={{
                                     marginRight: '2%'
                                 }}
                            >
                                <div>
                                    <Form
                                        form={form}
                                        name="basic"
                                        layout="horizontal"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        initialValues={{remember: true}}
                                        onFinish={onFormFinish}
                                        disabled={!FormEnabled}
                                        onFinishFailed={onFormFinishFailed}

                                    >
                                        <Form.Item label="Company" name="Company">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Number" name="ContactNumber">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Email" name="ContactEmail"
                                                   rules={[{required: true, message: 'Please input!'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Name" name="ContactName"
                                                   rules={[{required: true, message: 'Please input!'}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Notes" name="Notes" style={{width: '100%'}}
                                                   wrapperCol={{span: 24}}>
                                            <TextArea rows={4}/>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{offset: 8, span: 20}}>
                                            <Button type="primary" htmlType="submit" style={{padding: '5px'}}
                                                    className={"font_link"}>
                                                Submit
                                            </Button>
                                            <Divider type={"vertical"}/>
                                            <Button htmlType="button" onClick={onFormClearClick}
                                                    style={{padding: '5px'}} className={"font_link"}>
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <p>* Please allow up to 48hrs for a response from our team</p>
                                    <Spin spinning={requestLoad} size={'large'} />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} className={"articleMainBody"}>
                                <h3>Our Store Locations</h3>
                                <h4>Brisbane Store</h4>
                                <div style={{marginLeft: '5%'}}>
                                    <a href={`tel:3245 4488 (1)`} style={{color: 'black'}}>
                                        <p><PhoneOutlined/>{" : 07 3245 4488 (1)"}</p>
                                    </a>
                                    <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '100%'}}>
                                        <p><MailOutlined/>{" : admin@bspb.com.au"}</p>
                                    </a>
                                    <p
                                        onClick={() => {
                                            const newWindow = window.open("https://maps.app.goo.gl/g84yYyaKK4zTEUfC9", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{cursor: 'pointer'}}
                                    ><EnvironmentOutlined/>
                                        {" : 367 Thynne Rd, Morningside QLD 4170"}
                                    </p>
                                    <h4>Opening hours</h4>
                                    <div style={{marginLeft: '5%'}}>
                                        <p>{'Monday: 5:30am :    4:00pm'}</p>
                                        <p>{'Tuesday: 5:30am :   4:00pm'}</p>
                                        <p>{'Wednesday: 5:30am : 4:00pm'}</p>
                                        <p>{'Thursday: 5:30am :  4:00pm'}</p>
                                        <p>{'Friday: 5:30am :    4:00pm'}</p>
                                    </div>

                                </div>

                                <h4>Redlands Store</h4>
                                <div style={{marginLeft: '5%'}}>
                                    <a href={`tel:07 3245 4488 (3)`} style={{color: 'black'}}>
                                        <p><PhoneOutlined/>{" : 07 3245 4488 (3)"}</p>
                                    </a>
                                    <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '100%'}}>
                                        <p><MailOutlined/>{" : redlands@bspb.com.au"}</p>
                                    </a>
                                    <p
                                        onClick={() => {
                                            const newWindow = window.open("https://maps.app.goo.gl/Qx6rauzQBuxetsWw8", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{cursor: 'pointer'}}
                                    ><EnvironmentOutlined/>{" : 2/1424 New Cleveland Rd, Chandler QLD 4155"}</p>
                                    <h4>Opening hours</h4>
                                    <div style={{marginLeft: '5%'}}>
                                        <p>{'Monday: 6:00am :    4:00pm'}</p>
                                        <p>{'Tuesday: 6:00am :   4:00pm'}</p>
                                        <p>{'Wednesday: 6:00am : 4:00pm'}</p>
                                        <p>{'Thursday: 6:00am :  4:00pm'}</p>
                                        <p>{'Friday: 6:00am :    4:00pm'}</p>
                                    </div>
                                </div>

                                <h4>Gold Coast Store</h4>
                                <div style={{marginLeft: '5%'}}>
                                    <a href={`tel:07 3245 4488 (2)`} style={{color: 'black'}}>
                                        <p><PhoneOutlined/>{" : 07 3245 4488 (2)"}</p>
                                    </a>
                                    <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '100%'}}>
                                        <p><MailOutlined/>{" : gc@bspb.com.au"}</p>
                                    </a>
                                    <p
                                        onClick={() => {
                                            const newWindow = window.open("https://maps.app.goo.gl/VS1jh2bcqivpb3Ax7", '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}
                                        style={{cursor: 'pointer'}}
                                    ><EnvironmentOutlined/>{" : 106 Eastlake St, Carrara QLD 4211"}</p>
                                    <h4>Opening hours</h4>
                                    <div style={{marginLeft: '5%'}}>
                                        <p>{'Monday: 6:00am :    3:30pm'}</p>
                                        <p>{'Tuesday: 6:00am :   3:30pm'}</p>
                                        <p>{'Wednesday: 6:00am : 3:30pm'}</p>
                                        <p>{'Thursday: 6:00am :  3:30pm'}</p>
                                        <p>{'Friday: 6:00am :    3:00pm'}</p>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
};
