import {Button, Col, Drawer, Image, InputNumber, Row, Table, Tag} from "antd";
import React, {useContext} from "react";
import {ColumnsType} from "antd/es/table";
import CartStore from "../../stores/Cart.store";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import {Link} from "react-router-dom";



function CartPanel({isOpen, closeDrawer}:{isOpen: any, closeDrawer: any}) {

    const cart = useContext(CartStore)
    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'Description',
            key: 'Description',
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            render: (text, record, index) => {
                return (
                    <div>

                        <InputNumber
                            min={0}
                            defaultValue={text}
                            onChange={(value) => {
                                cart.UpdateByRow(value, record.id);
                            }}
                        />
                    </div>
                )
            }
        },
    ]
    return (
        <>
            <Drawer
                title="Bayside Cart"
                open={isOpen}
                onClose={()=>{closeDrawer()}}
                closeIcon={true}
                
            >
                <Row>
                    <Col span={10}>
                        <Link to={"/checkout"} >
                            <Button
                                type={'primary'}
                                style={{
                                    padding: '5px',
                                    width: '7vw'
                                }}
                            >CheckOut</Button>
                        </Link>
                    </Col>
                    <Col span={10}><Button
                        style={{
                            width: '7vw',
                            padding: '5px',
                        }}
                        onClick={()=>{
                            cart.ClearCart()
                        }}
                    >Clear</Button></Col>
                </Row>
                <br/>
                <Table columns={columns} dataSource={toJS(cart.cart)}></Table>
            </Drawer>
        </>
    );
}

export default observer(CartPanel)
