import {observer} from "mobx-react-lite";
import {Card, Col, Descriptions, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

function PlasterBoardRange() {
    return (
        <div
            style={{padding: '2%', height: '100%', minHeight: '60vh'}}
        >
            <Row
                style={{marginBottom: '2%'}}
            >
                <Col span={11}>
                    <Card title={"OneBoard 10mm Wall"}>
                        <Row>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/bayside_sheetrock-wall.jpg"} alt={"Bayside Product"}/>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <p className={"articleMainBody"}>SHEETROCK® ONE is Knauf’s
                                    state-of-the-art
                                    lightweight two in one application
                                    plasterboard for residential walls and ceilings. Utilising a unique
                                    patented
                                    formulation and encased in a strong 100% recycled paper liner, this
                                    plasterboard has a high strength-to-weight ratio and is ideal for a
                                    wide
                                    range of residential internal applications where fire resistance
                                    ratings,
                                    impact and water resistance is not required.</p>
                                <Descriptions items={[
                                    {
                                        label: 'Thickness',
                                        children: '10mm'
                                    }, {
                                        label: 'Edge Profiles',
                                        children: 'Recessed edge'
                                    }, {
                                        label: 'Length',
                                        children: '6000mm, 4800mm, 4200mm, 3600mm, 3000mm, 2700mm, 2400mm'
                                    }, {
                                        label: 'Weight',
                                        children: '5.9 kg/m² (nominal)'
                                    },

                                ]}
                                              column={1}
                                              className={"articleMainBody"}
                                ></Descriptions>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"13mm Regular"}>
                        <Row>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/SHEETROCK13MM-870x569_en.jpg"} alt={"Bayside Product"}/>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11}>
                                <p>SHEETROCK® Standard 13mm is USG Boral’s newest re-engineered
                                    lightweight gypsum alternative to Standard 13mm Plasterboard. This
                                    gypsum plasterboard has a high strength-to-weight composite design
                                    encased in a strong 100% recycled paper liner. It is ideal for a
                                    wide range of residential and commercial internal wall and ceiling
                                    applications where fire resistance ratings, impact and water
                                    resistance are not required. SHEETROCK® Standard 13mm is light, easy
                                    to score and snap and has excellent sag resistance.</p>
                                <Descriptions column={1} items={[
                                    {
                                        label: 'Thickness',
                                        children: '13mm'
                                    },
                                    {
                                        label: "Edge Profiles",
                                        children: 'Recessed Edge / Recessed Edge/Square Edge'
                                    },
                                    {
                                        label: "Weight",
                                        children: '6.9 kg/m² (nominal)'
                                    }
                                ]}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row
                style={{marginBottom: '2%'}}
            >
                <Col span={11}>
                    <Card title={"10mm Wet Area"}>
                        <Col span={11}>
                            <Image src={"./assets/images/productRanges/bayside_sheetrock-wall.jpg"} alt={"Bayside Product"}/>
                        </Col>
                        <Col span={2}></Col>
                        <p>WETSTOP™ (formerly Wet Area Board™) is ideal as an internal lining used in
                            wet areas such as bathrooms, showers, laundries and toilets. WETSTOP™ must
                            be installed in accordance with USG Boral Plasterboard’s installation
                            instructions in order to satisfy regulatory requirements for wet areas.</p>
                        <Col span={11} >

                        </Col>

                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"13mm SoundStop"}>
                        <Row>
                        <Col span={11}>
                            <Image src={"./assets/images/productRanges/bayside_sheetrock-wall.jpg"} alt={"Bayside Product"}/>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <p>Soundstop® is specifically developed to help control room noise levels and
                                improve living comfort in both residential and commercial environments.
                                Soundstop® 10mm and 13mm plasterboard is utilised in QuietZone®,
                                CinemaZone®, Partiwall® and other proprietary USG Boral building systems
                                where reducing sound transfer from one room to another is required.</p>
                            <List
                                dataSource={["Sound resistance", "Easy installation", "Suitable for use in proprietary USG Boral Acoustic rated building systems"]}
                                renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                            />
                        </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row
                style={{marginBottom: '2%'}}
            >
                <Col span={11}>
                    <Card title={"1200mm FireStop"}>
                        <Row>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/bayside_sheetrock-wall.jpg"} alt={"Bayside Product"}/>
                            </Col>
                            <Col span={2}></Col>
                            <Col span={11} >
                                <p>Firestop® must be used in partition wall and ceiling systems where specific fire-resistance
                                    ratings are required.</p>
                                <p>
                                    Firestop® plasterboard is utilised in Multiframe™, Partiwall®, IntRwall® and other
                                    proprietary USG Boral Building Systems providing lightweight fire and acoustic performance.
                                </p>
                                <p>
                                    Provides a lightweight option where fire and acoustic performance is required.
                                </p>
                                <p>
                                    Firestop® is certified by Good Environmental Choice Australia (GECA) and may contribute to
                                    Green Star points.</p>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                </Col>
            </Row>

        </div>
    );
}

export default observer(PlasterBoardRange);