import React, { useContext, useEffect, useState } from "react";
import Overlay from "../../componets/overlay/overlay";
import {Button, Card, Col, Image, Input, Modal, Pagination, Row, Space, Table, Tabs} from "antd";
import MediaQuery from "react-responsive";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import resourceStore from "../../stores/resource.store";
import Meta from "antd/es/card/Meta";
import ArticleTile from "./ArticleTile";
import {Helmet} from "react-helmet";

function ResourcesPage() {
    const store = useContext(resourceStore)

    useEffect(() => {
        store.downloadResources()
    }, []);

    function RowCreate(rowId: number, pageNumber: number){
        console.log("beep")
        return (
            <Row>
                <Col span={6} style={{padding: '1%'}}>
                    <ArticleTile ColId={0} RowId={rowId} PageId={pageNumber} />
                </Col>
                <Col span={6} style={{padding: '1%'}}>
                    <ArticleTile ColId={1} RowId={rowId} PageId={pageNumber} />
                </Col>
                <Col span={6} style={{padding: '1%'}}>
                    <ArticleTile ColId={2} RowId={rowId} PageId={pageNumber} />
                </Col>
                <Col span={6} style={{padding: '1%'}}>
                    <ArticleTile ColId={3} RowId={rowId} PageId={pageNumber} />
                </Col>
            </Row>
        )
    }

    function CreateDemRows(pageNumber: number) {
        console.log("boop")
        return(
            <div>
                {RowCreate(0, pageNumber)}
                {RowCreate(1, pageNumber)}
                {RowCreate(2, pageNumber)}
                {RowCreate(3, pageNumber)}
            </div>
        )
    }

    function createColMobile(rowId: number, pageNumber: number) {
        return (
            <Row>
                <Col span={24} style={{padding: '3%', marginRight: '10%'}}>
                    <ArticleTile ColId={0} RowId={rowId} PageId={pageNumber} />
                </Col>
            </Row>
        )
    }

    function createDemMobileRows(pageNumber: number) {
        return(
            <div>
                { createColMobile(0, pageNumber)}
                { createColMobile(1, pageNumber)}
                { createColMobile(2, pageNumber)}
                { createColMobile(3, pageNumber)}
                { createColMobile(4, pageNumber)}
                { createColMobile(5, pageNumber)}
            </div>
        )
    }



    function CreateDaPages() {
        const [page, setPage] = useState(1)
        useEffect(() => {
            console.log(page)
        }, [page]);
        console.log("desktop")
        console.log(store.resourceArray.length)
        return(
            <div>
                {CreateDemRows(page)}
                <Pagination
                    style={{boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)", width: '60%', borderRadius: '10px', padding: '2%'}}
                    defaultCurrent={1}
                    onChange={(item) => {
                        setPage(item)
                    }}
                    defaultPageSize={16}
                    total={store.resourceArray.length}
                />
            </div>
        )

    }

    function MobilePages(){
        console.log("mobile view")
        const [page, setPage] = useState(1)
        useEffect(() => {
            console.log(page)
        }, [page]);
        return(
            <div>
                <Pagination
                    style={{boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)", width: '60%', borderRadius: '10px', padding: '2%'}}
                    defaultCurrent={1}
                    onChange={(item) => {
                        setPage(item)
                    }}
                    defaultPageSize={6}
                    total={store.resourceArray.length}
                />
                {createDemMobileRows(page)}
                <Pagination
                    style={{boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.3)", width: '60%', borderRadius: '10px', padding: '2%'}}
                    defaultCurrent={1}
                    onChange={(item) => {
                        setPage(item)
                    }}
                    defaultPageSize={6}
                    total={store.resourceArray.length}
                />
            </div>
        )
    }

    let jmediaquery = window.matchMedia( "(min-width: 900px)" )
    if (jmediaquery.matches) {
        store.SetDesktop();
    }
    else {
        store.SetMobile();
    }




    return (
        <div>
            <Helmet>
                <title>Blogs</title>
                <meta name="description" content="Bayside Blog" />
                <meta name="keywords" content="plasterboard, blog, Bayside" />
                <meta property="og:title" content="Blogs" />
                <meta property="og:description" content="Bayside Blog" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="../../assets/icons/BaysideLogo.png" />
            </Helmet>
            <Overlay>
                <MediaQuery minWidth={900}>

                    <div className="Resource" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize:     "cover",
                        backgroundRepeat:   "no-repeat",}}
                    >
                        <Row
                            className="mainback"
                            style={{
                                height: '100%',
                                marginLeft: '3%',
                                marginRight: '3%'
                            }}
                        >
                            <div
                                style={{marginBottom: '2%'}}
                            >
                                <h1 className={"font_link"}> Blog </h1>
                                <Input addonBefore="SearchText" defaultValue="" onChange={(e) => {
                                    store.searchText = e.target.value;
                                }}/>
                                {CreateDaPages()}



                            </div>
                        </Row>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={899}>

                    <Row
                        className="mainback"
                        style={{
                            height: '100%',

                            marginLeft: '1%',
                            marginRight: '1%'
                        }}
                    >
                        <div
                            style={{
                                marginBottom: '2%',
                                marginLeft: '1%',
                                marginRight: '1%'
                            }}
                        >
                            <h1 className={"font_link"}> Blog </h1>
                            {MobilePages()}

                        </div>
                    </Row>
                </MediaQuery>
            </Overlay>
        </div >
    );
}

export default observer(ResourcesPage);