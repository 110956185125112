import {observable, action, computed, reaction, makeAutoObservable} from "mobx"
import {createContext, useContext} from "react"
import {CatalogueItem} from "./catalogue.store";
import {message} from "antd";
import axios from "axios";
import UserStore, {UserDetails} from "./user.store";
import baseUrl from "./Utility";

class UsersStore {
    users: UserDetails[] = []
    Token: string = ""
    EditingUser: number = -1;

    constructor() {
        makeAutoObservable(this);
        const sessionToken = localStorage.getItem("Token");
        if (sessionToken != null) {
            this.Token = sessionToken;
        }
    }

    async getUsers(token: string) {
        let UserArray: any[];
        this.Token = token;
        UserArray = [];
        const requestConfig = {
            method : "Get",
            url :  `${baseUrl()}/Customers`,
            headers : {
                "Content-Type":"application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: token ? `${token}` : "",
            }
        }
        console.log(requestConfig)
        await axios(requestConfig)
            .then((response) => {
                UserArray = response.data
                console.log(UserArray);
            })
            .catch()
        this.users = UserArray;
    }

    get PendingUsers() {
        return this.users.filter(user => {return(!user.Approved)})
    }

    get ApprovedUsers() {
        return this.users.filter(user => {return(user.Approved)})
    }

    editUser(userId: number){
        this.EditingUser = userId
    }

    get UserToEdit() {
        const index = this.users.findIndex(item => {return(item.Id == this.EditingUser)})
        return this.users[index]
    }

    async createUser(userDetails: any) {
        let requestConfig = {
            method: "POST",
            url: `${baseUrl()}/createNewCustomer`,
            headers: {
                "Content-Type": "application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: this.Token ? `${this.Token}` : "",
            },
            data: userDetails
        }
        if (this.EditingUser > 0) {
            requestConfig.method = "PUT"
            requestConfig.data.UserId = this.EditingUser
        }
        userDetails.Approved = false
        await axios(requestConfig)
            .then((response) => {
                message.success("User Added");
                this.users.push(userDetails)
            })
            .catch()
    }

    async approveUser(userId: number, index: number, status: boolean) {
        const requestConfig = {
            method : "POST",
            url :  `${baseUrl()}/api/CustomersApprove`,
            headers : {
                "Content-Type":"application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: this.Token ? `${this.Token}` : "",
            },
            data: {
                UserId: userId,
                Approved: status
            }
        }
        await axios(requestConfig)
            .then((response) => {
                message.success("User Approved");
                this.users[index].Approved = status;
            })
            .catch()
    }
    deleteUser(Id: number) {
        
    }
}

export default createContext(new UsersStore())
