 import {observable, action, computed, reaction, makeAutoObservable} from "mobx"
import { createContext } from "react"
import {CatalogueItem} from "./catalogue.store";
import {message} from "antd";
import axios from "axios";
 import baseUrl from "./Utility";

export interface UserDetails {
    Id: number ;
    Email: string ;
    Address: string ;
    ContactNumber: string ;
    Name: string ;
    Company: string ;
    Store: string ;
    Approved: boolean;
    Delivery: boolean;
}

export interface loginRequest {
    UserName: string;
    PassWord: string;
}

export interface loginResponse {
    Token: string;
    UserDetails: any;
}

class UserStore {

    Id: number = -1;
    Email: string = '';
    Address: string = '';
    ContactNumber: string = '';
    Name: string | null = null;
    Company: string = "";
    Store: string = "";
    Delivery: boolean = false;
    LoggedIn: boolean = false;
    Token: string = '';
    Admin: boolean = true;
    constructor() {
        const sessionToken = localStorage.getItem("Token");
        if (sessionToken != null) {
            this.CheckToken(sessionToken);
        }
        makeAutoObservable(this);
    }

    IsUserLoggedIn() {
        return this.LoggedIn
    }

    IsUserAdmin() {
        return true;
        // dev reasons
        return this.LoggedIn && false
    }

    async CheckToken(token: string) {
        let status = false;
        let loggedInMaybe = false
        let data: any = null;
        await axios.post(`${baseUrl()}/Token`, {
            Token: token
        })
            .then((response)=> {
                loggedInMaybe = true;
                data = response.data
            })
            .catch(() => {
                localStorage.setItem("Token", "");
            })

        this.setUserData(data, loggedInMaybe);
    }

    setUserData(data: any, loggedInMaybe: boolean){
        if(data == null) return
        this.Token = data.Token;
        this.Address = data.UserDetails.Address;
        this.Name = data.UserDetails.Name;
        this.ContactNumber = data.UserDetails.ContactNumber;
        this.Email = data.UserDetails.Email;
        this.LoggedIn = loggedInMaybe;
        this.Id = data.UserDetails.Id;
        localStorage.setItem("Token", this.Token);
    }

    async Login(userName: string, password: string) {
        const login: loginRequest = {
            UserName: userName,
            PassWord: password
        }
        const config = {
            method : "post",
            url :  `${baseUrl()}/login`,
            headers : {
                "Content-Type":"application/json"
            },
            data : JSON.stringify(login)
        }
        let data: any = null;
        let loggedInMaybe = false
        await axios(config)
            .then(function (response) {
                // handle success
                console.log(response);
                data = response.data;
                loggedInMaybe = true;
                message.success("Log In Success")
            })
            .catch(function (error) {
                // handle error
                console.log(error);
                message.error("Failed To Login")
                localStorage.setItem("Token", "");
            })
        this.setUserData(data, loggedInMaybe);
    }

    get CheckOutDetails() {
        return {
            Id         : this.Id,
            Email         : this.Email,
            Address       : this.Address,
            ContactNumber : this.ContactNumber,
            Name          : this.Name,
            Company       : this.Company,
            Store         : this.Store,
            Delivery    : false
        }
    }

    Logout(){}

    async ProcessOrder(user: UserDetails, cart: CatalogueItem[]) {
        console.log(user);
        user.Id = this.Id;
        user.ContactNumber = user.ContactNumber.toString();
        if (cart.length == 0) {
            message.error("Empty Cart")
            return
        }
        message.info('Order Placed!')
        const order = {
            User: user,
            UserCart: cart
        }
        const requestConfig = {
            method : "POST",
            url :  `${baseUrl()}/placeOrder`,
            headers : {
                "Content-Type":"application/json",
                // "x-hello": "I am the one who knocks.",
                Authorization: this.Token ? `${this.Token}` : "",
            },
            data : JSON.stringify(order)
        }
        let orderStatus = false;
        await axios(requestConfig)
            .then((response)=>{
                orderStatus = true
            })
            .catch(error => {
                console.log(error)
            })

        return orderStatus
    }

}

export default createContext(new UserStore())
