import {Card, Col, Image, Row} from "antd";
import Meta from "antd/es/card/Meta";
import React from "react";
import {MailOutlined, PhoneOutlined} from "@ant-design/icons";

function StaffMember({Name, title, phone, email, photo}:{Name: string, title: string, phone: string, email :string, photo: string}) {
    return (
        <div
            className={"font_link"}
            style={{
                height: '100%',
                width: '100%',
                backgroundColor: "#03cffc",
                textAlign: 'center',
                borderRadius: '10px',
                padding: "2%",
                fontSize: "1em",
                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
            }}
        >

            <Card bordered={false} className={"font_link"}>

                <Meta title={Name} description={title} />
                {email && (<a href={`mailto:${email}`} style={{color: 'black', fontSize: '100%'}}><p><MailOutlined/> {` : ${email}`}</p></a>)}
                {phone && (<a href={`tel:${phone}`} style={{color: 'black', fontSize: '100%'}} ><p><PhoneOutlined/>{` : ${phone}`}</p> </a>)}
            </Card>
        </div>
    );
}

export default StaffMember;