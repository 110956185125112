import {Divider, Form, Input, Modal} from "antd";
import {LoginOutlined, StopOutlined} from "@ant-design/icons";
import {useContext} from "react";
import UserStore from "../../stores/user.store";
import {useForm} from "antd/es/form/Form";
import Button from "antd/es/button";
import UsersStore from "../../stores/Users.store";

export function LoginModal({cancelstate, okfunction}: {cancelstate: any, okfunction: any}) {

    const userStore = useContext(UserStore);
    const adminUserStore = useContext(UsersStore)

    const [form] = useForm();

    const onFormFinish = (values: any) => {
        // todo handle form finish
        userStore.Login(values.UserName, values.password);

        okfunction();
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    return (
        <>
            <Modal
                title="Login"
                open
                onOk={() =>{
                    okfunction()
                }}
                onCancel={() => {
                    cancelstate();
                }}
                footer={null}
            >

                <Form
                    form={form}
                    name="basic"
                    layout="horizontal"
                    initialValues={{remember: true}}
                    onFinish={onFormFinish}
                    onFinishFailed={onFormFinishFailed}
                >

                    <Form.Item label="" name="UserName">
                        <Input addonBefore="UserName" defaultValue=""/>
                    </Form.Item>
                    <Form.Item label="" name="password">
                        <Input.Password addonBefore="Password" defaultValue=""/>
                    </Form.Item>
                    <Form.Item >
                        <Button type="primary" htmlType="submit" style={{margin: "2px", width: "47%"}}>
                            Submit
                        </Button>
                        <Divider type={"vertical"} />
                        <Button htmlType="button" onClick={onFormClearClick} style={{margin: "2px", width: "47%"}}>
                            Clear
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
