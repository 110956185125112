import {observable, action, computed, reaction, makeAutoObservable} from "mobx"
import { createContext } from "react"
import {message} from "antd";
import {CatalogueItem} from "./catalogue.store";
import baseUrl from "./Utility";
import axios, {Axios} from "axios";

export interface Product extends CatalogueItem{
    Quantity: number;
}

class CartStore {
    constructor() {
        reaction(() => this.cart, _ => console.log(this.cart.length))
        this.LoadCartFromStorage();
        makeAutoObservable(this);
    }

    get CartTotalInc() {
        let rollingCost = 0;
        this.cart.forEach(item => {
            rollingCost += (item.Quantity * item.PriceInc)
        })
        return parseFloat(rollingCost.toString()).toFixed(2);
    }

    get CartTotalEx() {
        let rollingCost = 0;
        this.cart.forEach(item => {
            rollingCost += (item.Quantity * item.PriceEx)
        })
        return parseFloat(rollingCost.toString()).toFixed(2);
    }

    cart: Product[] = [
    ]

    AddToCart = (productToAdd: Product) => {
        if (productToAdd.Quantity <= 0) return;
        const index = this.cart.findIndex(item => {return(item.Id === productToAdd.Id)})
        // message.success("Added To Cart");
        if (index >= 0) {
            this.cart[index].Quantity += productToAdd.Quantity;
            return;
        }
        this.cart.push(productToAdd)
        this.SaveCartToStorage();
        return;
    }

    ClearCart(){
        this.cart = [];
        this.SaveCartToStorage();
    }

    async DownloadOrderForm() {
        await axios({
            method : "Get",
            url :  `${baseUrl()}/DownloadOrderForm`,
            responseType: 'blob',
        })
            .then((response) => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', 'Bayside Plasteroard Order Form 11.23.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }

    UpdateByRow(quantity: number, ItemId: string) {
        const index = this.cart.findIndex((item) => {return (item.Id === ItemId)});
        if (quantity === 0) {
            this.cart.splice(index, 1);
            return;
        }
        this.cart[index].Quantity = quantity;
        this.SaveCartToStorage();
    }

    get CartLength(){
        return this.cart.length;
    }

    SaveCartToStorage() {
        // load string to id, quantity, id2, quantity2, idn, quantityn
        let cartString: string = "";
        cartString = JSON.stringify(this.cart)
        localStorage.setItem("baysideCart", cartString);
    }

    LoadCartFromStorage(){
        const cartString: string | null = localStorage.getItem("baysideCart")
        if (cartString === null) return
        try {
            this.cart = JSON.parse(cartString);
        } catch (exception) {
            console.log(exception)
        }
        return;
    }

    ModifyCartItem(action: string, id: string) {
        const index = this.cart.findIndex((item) => {return (item.Id === id)})
        switch (action){
            case 'Add': {
                this.cart[index].Quantity += 1;
                break;
            }
            case 'Reduce': {
                this.cart[index].Quantity -= 1;
                break;
            }
            case 'Clear': {
                this.cart.splice(index, 1);
                break;
            }
            default: {
                break;
            }
        }
        this.SaveCartToStorage();
    }

    FormToCart(values: Product[]) {
        values.forEach(product => {
            this.AddToCart(product)
        })
        this.SaveCartToStorage();
    }
}

export default createContext(new CartStore())
