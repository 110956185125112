import {Card, Col, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function FiberCerment() {
    return (
        <div style={{padding: '2%'}}>
            <Card title={"James Hardie"}>
                <Row>
                    <Col span={11}>
                        <div>
                            <p>Villaboard™ is a heavy-duty wall lining compared to plasterboard. Ideal for use in
                                bathrooms and wet
                                areas, if the waterproofing fails you can rest assure that Villaboard won’t.
                            </p>
                            <p>
                                Resistant to damage from moisture, it won’t deform or lose its structural integrity
                                which means fewer call backs and
                                less costly rectifications.
                            </p><p>
                            Villaboard also has incredible hanging power for tiles, hooks and fixings.
                        </p><p>
                            With a tensile bond strength up to 75 per cent greater than wet area plasterboard, it can
                            hold tiles with a maximum weight between 30 and 60 kg/m2 depending on the thickness of the
                            tiles and
                            Villaboard™ sheet used, ensuring expensive bathroom tiles are always secure - Please refer
                            to the
                            product installation guide for further information of maximum tile thicknesses and weights.
                            Use it
                            on walls and ceilings in bathrooms, laundries, kitchens and high-traffic areas, and even in
                            multi-residential and commercial buildings.</p>
                        </div>
                        <p>Ranges Stocked:</p>
                        <List
                            dataSource={["External Cladding", "Internal Lining", "Eaves", "Flooring And Decking", "Trims", "UnderLays"]}
                            renderItem={(item, index) => (<p><MinusOutlined/> {item}</p>)}
                        />
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                        <Image src={"./assets/images/productRanges/villaboard-withlogo-interior-jameshardie-2.jpg"} alt={"Bayside Product"}/>
                    </Col>
                </Row>


            </Card>
        </div>
    );
}
