import {Menu, Layout, Button, Drawer, Row, Col, AppProps, Divider, Descriptions} from "antd";
import React, {useState} from "react";
import { EnvelopeAtFill, TelephoneFill, GeoAltFill, ClockFill} from "react-bootstrap-icons";
import {EnvironmentOutlined, MailOutlined, PhoneFilled} from "@ant-design/icons";
import {format} from "timeago.js";


function currentTimeToClose(){
    let CurentTime = new Date();
    let timeString = CurentTime.getHours();
    let dateString = CurentTime.toDateString().split(" ")[0]
    console.log(dateString)
    return "Monday to Friday"
}

function StoreLocationMobile({StoreTitle, phonenumber, photo, inverse, address, addressUrl, emailAddress}: {StoreTitle: string, phonenumber: string, photo: string, inverse: boolean, address: string, addressUrl: string, emailAddress: string}) {
    const [openingtimes, setOpeningtimes] = useState(false)
    let openingHours = []
    if (StoreTitle === "Gold Coast") {
        openingHours = [
            {
                label: "Monday",
                children: "6am - 3:30am"
            },
            {
                label: "Tuesday",
                children: "6am - 3:30am"
            },
            {
                label: "Wednesday",
                children: "6am - 3:30am"
            },
            {
                label: "Thursday",
                children: "6am - 3:30am"
            },
            {
                label: "Friday",
                children: "6am - 3:30am"
            },
        ]
    } else {
        openingHours = [
            {
                label: "Monday",
                children: "5:30am - 4pm"
            },
            {
                label: "Tuesday",
                children: "5:30am - 4pm"
            },
            {
                label: "Wednesday",
                children: "5:30am - 4pm"
            },
            {
                label: "Thursday",
                children: "5:30am - 4pm"
            },
            {
                label: "Friday",
                children: "5:30am - 4pm"
            },
        ]
    }
    return (
        <Col
            style={{
                background: "rgba(43, 196, 217, 0.5)",
                height: "40%",
                width: "90%",
                borderRadius: "20px",
                padding: "2%"
            }}
        >
            <Row
                className="StoreDetails"
                style={{
                    height: "70%",
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: "rgba(245, 255, 250, 0.8)",
                    padding: '3%'
                }}
            >
                <Row
                    style={{
                        paddingBottom: '2%'
                    }}
                >
                    <Row>
                        <h1 className="font_link" style={{marginBottom: 0}}>{`${StoreTitle} Store`}</h1>
                    </Row>
                    <Col>
                        <Descriptions
                            column={1}
                            style={{paddingBottom: '0px'}}
                            contentStyle={{paddingBottom: '0px'}}
                            labelStyle={{paddingBottom: '0px'}}
                            items={[
                                {
                                    label: <p><PhoneFilled style={{fontSize: '2em'}}/></p>,
                                    children: <a href={`tel:${phonenumber}`} style={{color: 'black', fontSize: '130%'}}><p>{phonenumber}</p></a>
                                },
                                {
                                    label: <p><EnvironmentOutlined style={{fontSize: '2em'}}/></p>,
                                    children: <p
                                        style={{
                                            cursor: "pointer",
                                            textOverflow: 'ellipsis',
                                            overflow: 'visible',
                                            color: 'black',
                                            fontSize: '130%'
                                        }}
                                        onClick={() => {
                                            const newWindow = window.open(addressUrl, '_blank', 'noopener,noreferrer')
                                            if (newWindow) newWindow.opener = null;
                                        }}

                                    >
                                        {address}
                                    </p>
                                },
                                {
                                    label: <p><MailOutlined style={{fontSize: '2em'}}/></p>,
                                    children: <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '130%'}}><p>{emailAddress}</p>
                                    </a>
                                }
                            ]}
                        >
                        </Descriptions>
                        <Row onClick={() => setOpeningtimes(!openingtimes)} style={{cursor: "pointer"}}>
                            <Col>
                                <h3 className={"aboutMainBody"} style={{marginBottom: 0}}>Opening Hours:</h3>
                                <p
                                    className={"aboutMainBody"}
                                    style={{marginTop: 0}}
                                >
                                    {currentTimeToClose()}
                                </p>
                                {openingtimes && (
                                    <div className={"font_link"}>
                                        <h3 className={"font_link"}>Opening Hours:</h3>
                                        <Descriptions
                                            column={1}
                                            items={openingHours}
                                        ></Descriptions>
                                    </div>
                                )}
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Row>
            <Row
                className=""
                style={{
                    marginTop: '2%',
                    height: "200%",
                    width: "100%",
                    borderRadius: "20px",
                    backgroundColor: "#D9D9D9",
                    padding: '1%'
                }}
            >
                <img src={photo} style={{width: "100%", height: "100%", borderRadius: "20px"}}/>
            </Row>
        </Col>
    )
}

export default StoreLocationMobile;
