import {Card, Col, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function AccessPanels() {
    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>
            <Row>
                <Col span={24}>
                    <h4>ManHole Frames</h4>
                </Col>
                <Col span={11}>
                    <Card title={"Manhole Frame 450x600mm Lockable Plastic Door Kimberley"}>
                        <p>Kimberley’s plastic Lockable Access Panel provides internal access to services such as plumbing, cabling etc.</p>
                        <Image src={"./assets/images/productRanges/ap_kmhd-m-300x186.png"}
                               alt={"bayside Product"}/>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Wallboard Manhole Frames"}>
                        <p>Manhole Frames are manufactured from heavy-duty plastic and are a quick and lightweight solution for ceiling access.</p>
                        <Image src={"./assets/images/productRanges/wmh-01-manhole-frame-300x244.png"}
                               alt={"bayside Product"}/>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <h4>Fire Rated Access Panels</h4>
                </Col>
                <Col span={11}>
                    <Card title={"1 hour Fire Rated Access Panels Flanged"}>
                        <p>
                            1 Hour Fire Rated Access Panels (APF1) are designed to sit flush with the ceiling or wall surface leaving a narrow opening joint around the face.  The Access Panel is secured with a locking key or can be individually keyed for maximum security.
</p><p>
                            APF1 Panels have fully concealed hinges to provide lockable security access to services.  The Access Panels can be installed during construction or after the wall or ceiling has been lined.
                        </p>
                        <Image src={"./assets/images/productRanges/ap_f1-S-300x186.png"}
                               alt={"bayside Product"}/>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"1 hour Fire Rated Access Panels with Set Bead"}>
                       <p>
                           1 Hour Fire Rated Access Panels (APF1) are designed to sit flush with the ceiling or wall surface leaving a narrow opening joint around the face.  The Access Panel is secured with a locking key or can be individually keyed for maximum security.
</p><p>
                           APF1 Panels have fully concealed hinges to provide lockable security access to services.  The A
                       </p>
                        <Image src={"./assets/images/productRanges/ap_f1-S-300x186.png"}
                               alt={"bayside Product"}/>
                    </Card>
                </Col>
                <Col span={11}>
                    <Card title={"2 hour Fire Rated Access Panels Flanged"}>
                        <p>
                            Hour Fire Rated Access Panels (APF2) are screw fixed access panels with a laminated MDF face designed to meet the requirements of the BCA with FRL of 120/120/120 and 60/60/60.
</p><p>
                            APF2 panels are designed for installation in walls, shafts and ceilings where full integrity and insulation criteria are required under fire conditions.  AFP2 panels provide maintenance access to voids and services in fire rated construction.
                        </p>
                        <Image src={"./assets/images/productRanges/ap_f1-S-300x186.png"}
                               alt={"bayside Product"}/>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"2 hour Fire Rated Access Panels with Set Bead"}>
                        <p>
                            2 Hour Fire Rated Access Panels (APF2) are screw fixed access panels with a laminated MDF face designed to meet the requirements of the BCA with FRL of 120/120/120 and 60/60/60.
</p><p>
                            APF2 panels are designed for installation in walls, shafts and ceilings where full integrity and insulation criteria are required under fire conditions.  AFP2 panels provide maintenance access to voids and services in fire rated construction.
                        </p>
                        <Image src={"./assets/images/productRanges/ap_f1-S-300x186.png"}
                               alt={"bayside Product"}/>
                    </Card>
                </Col>
            </Row>

        </div>
    );
}
