import {Card, Row, Table, Image, Tabs, Col, List} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function SteelFraming() {
    const columns = [
        {
            title: "Part Number",
            dataIndex: "partNumber",
            key: "partNumber",
        },
        {
            title: "Length (mm)",
            dataIndex: "length",
            key: "length",
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
        }
    ]

    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>

            <Tabs>
                <Tabs.TabPane tab="Rondo Cross Tee" key="1" forceRender={true}>
                    <Row>
                        <Card title={"RONDO DUO"} style={{width: '100%'}}>
                            <h4>(Exposed Grid Ceiling System)
                                Primary Sections</h4>

                            <Image src={"./assets/images/productRanges/Rondo-Grid-Frame.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "DU02",
                                    length: "600",
                                    description: "24 x 34mm Cross Tee Tile White",
                                },
                                {
                                    partNumber: "DU02",
                                    length: "1200",
                                    description: "24 x 34mm Cross Tee Tile White",
                                }
                            ]}/>
                        </Card>
                    </Row>
                    <Row>
                        <Card
                            style={{marginTop: '1%', width: '100%'}}
                            title={"RONDO RAPID"}>

                            <h4>(Drywall Ceiling Grid System)
                                Primary Sections: For Flat & Curved Ceilings</h4>
                            <Image src={"./assets/images/productRanges/Rondo-Grid-Frame.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "RAP2",
                                    length: "1200",
                                    description: "38 x 38mm Cross Tee (for flat or curved ceilings)",
                                },{
                                    partNumber: "RAP2",
                                    length: "1250",
                                    description: "38 x 38mm Cross Tee (for flat or curved ceilings)",
                                },{
                                    partNumber: "RAP2",
                                    length: "1600",
                                    description: "38 x 38mm Cross Tee (for flat or curved ceilings)",
                                },
                            ]}/>
                        </Card>
                    </Row>
                    <Row>
                        <Card
                            style={{marginTop: '1%', width: '100%'}}
                            title={"RONDO ALUMINIUM"}>

                            <h4>((Exposed Grid Ceiling System)
                                Primary Sections</h4>
                            <Image src={"./assets/images/productRanges/Rondo-Grid-Frame.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "357",
                                    length: "600",
                                    description: "24mm Face x 38mm Aluminium Cross Tee",
                                },{
                                    partNumber: "357",
                                    length: "1200",
                                    description: "24mm Face x 38mm Aluminium Cross Tee",
                                },
                            ]}/>
                        </Card>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rondo Main Tee" key="2" forceRender={true}>
                    <Row>
                        <Card title={"RONDO DUO"} style={{width: '100%'}}>
                            <h4>(Exposed Grid Ceiling System)
                                Primary Sections</h4>

                            <Image src={"./assets/images/productRanges/Rondo-Main-Tee-600x277.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "DU02",
                                    length: "3600",
                                    description: "24 x 38mm Main Tee Tile White Proc 100C",
                                },
                            ]}/>
                        </Card>
                    </Row>
                    <Row>
                        <Card
                            style={{marginTop: '1%', width: '100%'}}
                            title={"RONDO RAPID"}>

                            <h4>(Drywall Ceiling Grid System)
                                Primary Sections: For Flat & Curved Ceilings</h4>
                            <Image src={"./assets/images/productRanges/Rondo-Main-Tee-600x277.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "RAP1",
                                    length: "3600",
                                    description: "\t38 x 38mm Main Tee (for flat or curved ceilings)",
                                },
                            ]}/>
                        </Card>
                    </Row>
                    <Row>
                        <Card
                            style={{marginTop: '1%', width: '100%'}}
                            title={"RONDO ALUMINIUM"}>

                            <h4>((Exposed Grid Ceiling System)
                                Primary Sections</h4>
                            <Image src={"./assets/images/productRanges/Rondo-Main-Tee-600x277.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "359",
                                    length: "3600",
                                    description: "24mm Face x 38mm Aluminium Lightweight Main Tee",
                                },
                            ]}/>
                        </Card>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rondo Wall Trims" key="3" forceRender={true}>
                    <Row>
                        <Card title={"RONDO KEYLOCK"} style={{width: '100%'}}>
                            <h4>(Exposed Grid Ceiling System)
                                Primary Sections</h4>

                            <Image src={"./assets/images/productRanges/Rondo-Wall-Trims-600x255.jpg"} alt={"bayside Product"}/>
                            <Table columns={columns} dataSource={[
                                {
                                    partNumber: "DU05",
                                    length: "3600",
                                    description: "Wall Angle 25 x 19mm Steel",
                                },{
                                    partNumber: "DU06",
                                    length: "3600",
                                    description: "Steel Shadowline Wall Angle 19mm x 9mm x 15mm",
                                },{
                                    partNumber: "DU07",
                                    length: "3600",
                                    description: "Wall Angle 19 x 9 x 9 x15mm Aluminium Shadowline",
                                },{
                                    partNumber: "DU08",
                                    length: "3600",
                                    description: "Aluminium Wall Angle 32mm x 19mm",
                                },{
                                    partNumber: "P51",
                                    length: "3000",
                                    description: "Shadowline Combination Set Bead for 10mm BoardC",
                                },{
                                    partNumber: "P52",
                                    length: "3000",
                                    description: "Shadowline Combination Set Bead for 13mm Board",
                                },{
                                    partNumber: "P53",
                                    length: "3000",
                                    description: "\tShadowline Combination Set Bead for 16mm Board",
                                },
                            ]}/>
                        </Card>
                    </Row>

                </Tabs.TabPane>
                <Tabs.TabPane tab={"Top Hat"} key="4" forceRender={true}>
                    <Row>
                        <Col span={11}>
                            <Card title={"James Hardie ExoTec® Intermediate Top Hat"}>
                                <Image src={"./assets/images/productRanges/e2064449-9780-4e62-9c81-cb1be594ae57-600x600.jpg"}
                                       alt={"bayside Product"}/>
                                <p>ExoTec Intermediate Top Hat is rolled zincalum coated AZ150 steel sections. Designed to span vertically across the building support structures and to be used as intermediate support to the panels.</p>
                                <List
                                    dataSource={[
                                        "Steel Zincalum Coated",
                                        "Available in length 4800mm",
                                        "Use with ExoTec® Facade Panel",
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Card>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Card title={"James Hardie™ ComTex® Top Hat"}>
                                <Image src={"./assets/images/productRanges/e2064449-9780-4e62-9c81-cb1be594ae57-600x600.jpg"}
                                       alt={"bayside Product"}/>
                                <p>A metal top hat for use with ComTex® façade panel and fixing system, behind control joints. 75mm wide x 35mm deep x 1.15mm gauge thick.</p>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1%'}}>
                        <Col span={11}>
                            <Card title={"James Hardie ExoTec® Intermediate Top Hat"}>
                                <Image src={"./assets/images/productRanges/e2064449-9780-4e62-9c81-cb1be594ae57-600x600.jpg"}
                                       alt={"bayside Product"}/>
                                <p>ExoTec Intermediate Top Hat is rolled zincalum coated AZ150 steel sections. Designed to span vertically across the building support structures and to be used as intermediate support to the panels.</p>
                                <List
                                    dataSource={[
                                        "Steel Zincalum Coated",
                                        "Available in length 4800mm",
                                        "Use with ExoTec® Facade Panel",
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Card>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                            <Card title={"James Hardie™ ComTex® Top Hat"}>
                                <Image src={"./assets/images/productRanges/e2064449-9780-4e62-9c81-cb1be594ae57-600x600.jpg"}
                                       alt={"bayside Product"}/>
                                <p>A metal top hat for use with ComTex® façade panel and fixing system, behind control joints. 75mm wide x 35mm deep x 1.15mm gauge thick.</p>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '1%'}}>
                        <Col span={11}>
                            <Card title={"James Hardie™ Intermediate Top Hat"}>
                                <Image src={"./assets/images/productRanges/e2064449-9780-4e62-9c81-cb1be594ae57-600x600.jpg"}
                                       alt={"bayside Product"}/>
                                <p>James Hardie™ Intermediate Top Hat.
</p><p>
                                    A metal top hat for use with ExoTex® and ComTex® façade panel and fixing system, for intermediate sheet support.
                            </p><p>
                                    50mm wide x 35mm deep x 1.15mm gauge thick</p>
                                <List
                                    dataSource={[
                                        "Steel Zincalum Coated",
                                        "Available in length 4800mm",
                                        "Use with ExoTec® Facade Panel",
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Card>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab={"Rondo Top Hats"} key={"5"} forceRender={true} >
                    <Card title={"Rondo Top Hats"}>
                        <h5>Top Hats: Medium Weight</h5>
                        <p>Stock Pack: 50</p>
                        <Table
                            columns={columns}
                            dataSource={[
                                {
                                    partNumber: "M515",
                                    length: 3600,
                                    description: 'Top Hat: 15 x 15 x 50 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "M515*",
                                    length: 7200,
                                    description: 'Top Hat: 15 x 15 x 50 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "M525",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 25 x 50 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M525*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 25 x 50 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M535",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 35 x 50 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M535*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 35 x 50 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M545",
                                    length: 3600,
                                    description: ' Top Hat: 20 x 45 x 50 x 45 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M545*",
                                    length: 7200,
                                    description: ' Top Hat: 20 x 45 x 50 x 45 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M550",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 50 x 50 x 50 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M550*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 50 x 50 x 50 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M560",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 60 x 50 x 60 x 20 x 0.75BMT'
                                }, {
                                    partNumber: "M560*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 60 x 50 x 60 x 20 x 0.75BMT'
                                }, {
                                    partNumber: "M715",
                                    length: 3600,
                                    description: ' Top Hat: 15 x 15 x 75 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "M715*",
                                    length: 7200,
                                    description: ' Top Hat: 15 x 15 x 75 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "M725",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 25 x 75 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M725*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 25 x 75 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M735",
                                    length: 3600,
                                    description: ' Top Hat: 20 x 35 x 75 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M735*",
                                    length: 7200,
                                    description: ' Top Hat: 20 x 35 x 75 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M750",
                                    length: 3600,
                                    description: ' Top Hat: 20 x 50 x 75 x 50 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "M750*",
                                    length: 7200,
                                    description: ' Top Hat: 20 x 50 x 75 x 50 x 20 x 0.75 BMT'
                                },
                            ]}
                        />
                        <h5>Top Hats: Heavy Weight</h5>
                        <p>Stock Pack: 50</p>
                        <Table
                            columns={columns}
                            dataSource={[
                                {
                                    partNumber: "H515",
                                    length: 3600,
                                    description: 'Top Hat: 15 x 15 x 50 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "H515*",
                                    length: 7200,
                                    description: 'Top Hat: 15 x 15 x 50 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "H525",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 25 x 50 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H525*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 25 x 50 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H535",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 35 x 50 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H535*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 35 x 50 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H545",
                                    length: 3600,
                                    description: ' Top Hat: 20 x 45 x 50 x 45 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H545*",
                                    length: 7200,
                                    description: ' Top Hat: 20 x 45 x 50 x 45 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H550",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 50 x 50 x 50 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H550*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 50 x 50 x 50 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H560",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 60 x 50 x 60 x 20 x 0.75BMT'
                                }, {
                                    partNumber: "H560*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 60 x 50 x 60 x 20 x 0.75BMT'
                                }, {
                                    partNumber: "H715",
                                    length: 3600,
                                    description: ' Top Hat: 15 x 15 x 75 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "H715*",
                                    length: 7200,
                                    description: ' Top Hat: 15 x 15 x 75 x 15 x 15 x 0.75 BMT'
                                }, {
                                    partNumber: "H725",
                                    length: 3600,
                                    description: 'Top Hat: 20 x 25 x 75 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H725*",
                                    length: 7200,
                                    description: 'Top Hat: 20 x 25 x 75 x 25 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H735",
                                    length: 3600,
                                    description: ' Top Hat: 20 x 35 x 75 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H735*",
                                    length: 7200,
                                    description: ' Top Hat: 20 x 35 x 75 x 35 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H750",
                                    length: 3600,
                                    description: ' Top Hat: 20 x 50 x 75 x 50 x 20 x 0.75 BMT'
                                }, {
                                    partNumber: "H750*",
                                    length: 7200,
                                    description: ' Top Hat: 20 x 50 x 75 x 50 x 20 x 0.75 BMT'
                                },
                            ]}
                        />
                        <h5>Top Hats Cleats</h5>
                        <p>Stock Pack: 50</p>
                        <Table
                            columns={columns}
                            dataSource={[
                                {
                                    partNumber: "535",
                                    length: "",
                                    description: '50 x 35 x 1.90 BMT Cleat'
                                }, {
                                    partNumber: "550",
                                    length: "",
                                    description: ' 50 x 55 x 1.90 BMT Cleat'
                                }, {
                                    partNumber: "735",
                                    length: "",
                                    description: ' 75 x 35 x 1.90 BMT Cleat'
                                },{
                                    partNumber: "750",
                                    length: "",
                                    description: ' 75 x 55 x 1.90 BMT Cleat'
                                },
                            ]}
                        />
                    </Card>
                </Tabs.TabPane>

            </Tabs>
        </div>
    );
}
