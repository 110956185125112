import React, {useContext, useEffect, useState} from "react";
import Overlay from "../../componets/overlay/overlay";
import {
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Row,
    Select, Spin,
    Statistic,
    Switch,
    Table
} from "antd";
import {
    DeleteFilled,
    DollarOutlined,
    DownOutlined,
    LikeOutlined,
    MoneyCollectFilled,
    UpOutlined,
} from "@ant-design/icons";
import MediaQuery from "react-responsive";
import {observer} from "mobx-react-lite";
import CartStore from "../../stores/Cart.store";
import CatalogueStore from "../../stores/catalogue.store";
import {ColumnsType} from "antd/es/table";
import {toJS} from "mobx";
import {useForm} from "antd/es/form/Form";
import UserStore from "../../stores/user.store";
import {Helmet} from "react-helmet";

const Checkout: React.FC = () => {
    const CatStore = useContext(CatalogueStore);
    const UserCartStore = useContext(CartStore);
    const userStore = useContext(UserStore)
    const [requestLoad, setRequestLoad] = useState(false)
    const [FormEnabled, setFormEnabled] = useState(true)
    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'Description',
            key: 'Description',
            render: (record: any, index: number) => {
                return (
                    <div
                    >
                        <Row>
                            <Col> <Image src="../../assets/icons/BaysideLogo.png" width="50%" /> </Col>
                            <Col style={{marginLeft: '1vw'}}> <h4 className={"articleMainBody"}>{record}</h4> </Col>
                        </Row>
                    </div>
                )
            }
        },
        {
            title: 'Quantity',
            dataIndex: 'Quantity',
            key: 'Quantity',
            render: (text, record, index) => {
                return(
                    <div>
                        <InputNumber min={0} defaultValue={text}
                            onChange={value => {
                                UserCartStore.UpdateByRow(value, record.id);
                            }}
                        />
                    </div>
                )
            }
        },
        {
            title: 'Modify Order',
            dataIndex: 'id',
            key: 'id',
            render: (record, index) =>{
                return (
                    <div>

                        <Button icon={<UpOutlined/>} type="primary" onClick={() => {
                            UserCartStore.ModifyCartItem("Add", record)
                        }}></Button>
                        <Divider type="vertical"/>
                        <Button icon={<DownOutlined/>} type="primary" onClick={() => {
                            UserCartStore.ModifyCartItem("Reduce", record)
                        }}></Button>
                        <Divider type="vertical"/>
                        <Button icon={<DeleteFilled />} danger onClick={() => {
                            UserCartStore.ModifyCartItem("Clear", record)
                        }}/>
                    </div>
                )
            }
        }
    ]

    const [form1] = useForm();
    const [deliveryNeeded, setDelivery] = useState(false);

    useEffect(() => {
        form1.validateFields(['Address']);
    }, [deliveryNeeded, form1]);

    const onFormFinish = (values: any) => {
        setRequestLoad(true)
        setFormEnabled(false)
        userStore.ProcessOrder(values, UserCartStore.cart).then((result) => {
            if (result) {
                message.success("Order Succeed")
                UserCartStore.ClearCart();
                onFormClearClick()
                setRequestLoad(false)
                setFormEnabled(true)
                return
            }
            message.error("Order Failed")
            setRequestLoad(false)
            setFormEnabled(true)
        });
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form1.resetFields();
    };
    return (
        <div>
            <Helmet>
                <title>CheckOut</title>
                <meta name="description" content="Bayside Checkout"/>
                <meta name="keywords" content="plasterboard, checkout, Bayside" />
                <meta property="og:title" content="CheckOut" />
                <meta property="og:description" content="Bayside Checkout" />
                <meta property="og:image" content="../../assets/icons/BaysideLogo.png" />
            </Helmet>
            <Overlay>
                <MediaQuery minWidth={901}>
                    <div className="Resource" style={{
                        //  backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    >
                        <Row
                            style={{
                                paddingLeft: "5%",
                                paddingRight: "5%",
                                paddingTop: "2%",
                                paddingBottom: '2%'
                            }}
                        >
                            <Col
                                span={12}
                                style={{}}
                            >
                                {/*<Row*/}
                                {/*    style={{*/}
                                {/*        padding: '2%',*/}
                                {/*        marginTop: '2%',*/}
                                {/*        width: '100 %',*/}
                                {/*        marginLeft: '5%',*/}
                                {/*        backgroundColor: "rgba(82, 171, 210, 0.5)",*/}
                                {/*        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',*/}
                                {/*        borderRadius: '20px',*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Col span={11}>*/}
                                {/*        <Statistic title="Order Total (inc)"*/}
                                {/*                   value={UserCartStore.CartTotalInc}*/}
                                {/*                   prefix={<DollarOutlined/>}*/}
                                {/*                   className={"font_link"}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*    <Col span={2}>*/}
                                {/*        <Divider type={"vertical"}/>*/}
                                {/*    </Col>*/}
                                {/*    <Col span={11}>*/}
                                {/*        <Statistic title="Order Total (ex)"*/}
                                {/*                   value={UserCartStore.CartTotalEx}*/}
                                {/*                   prefix={<DollarOutlined/>}*/}
                                {/*                   className={"font_link"}*/}
                                {/*        />*/}
                                {/*    </Col>*/}

                                {/*</Row>*/}
                                <div
                                    style={{
                                        padding: '2%',
                                        marginTop: '2%',
                                        width: '100%',
                                        marginLeft: '5%',
                                        backgroundColor: "rgba(82, 171, 210, 0.5)",
                                        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                        borderRadius: '20px'
                                    }}
                                >
                                    <h1 className="font_link">Checkout</h1>
                                    <Spin spinning={requestLoad} size={'large'} />
                                    <h3 className={"font_link"}>Please Enter the Following Details</h3>
                                    <Form
                                        form={form1}
                                        name="basic"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        disabled={!FormEnabled}
                                        layout="horizontal"
                                        initialValues={
                                            userStore.CheckOutDetails
                                        }

                                        onFinish={onFormFinish}
                                        onFinishFailed={onFormFinishFailed}
                                    >
                                        <Form.Item label="Name" name="Name" rules={[{required: true}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Email" name="Email" rules={[{required: true}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Company" name="Company">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Number" name="ContactNumber"
                                                   rules={[{required: true}]}>
                                            <InputNumber style={{width: '100%'}}/>
                                        </Form.Item>
                                        {/*<Form.Item label="Address" name="Address" rules={[{*/}
                                        {/*    required: deliveryNeeded,*/}
                                        {/*    message: "Please input address"*/}
                                        {/*}]}>*/}
                                        {/*    /!*TODO add google geolocation support*!/*/}
                                        {/*    <Input/>*/}
                                        {/*</Form.Item>*/}
                                        <Form.Item label="Store" name="Store" rules={[{
                                            required: !deliveryNeeded,
                                            message: "Please select PickUp Store"
                                        }]}>
                                            <Select defaultValue="MorningSide" options={[
                                                {value: 'MorningSide', label: 'MorningSide'},
                                                {value: 'Calapaba', label: 'Calapaba'},
                                                {value: 'GoldCoast', label: 'GoldCoast'},
                                            ]}>
                                            </Select>
                                        </Form.Item>
                                        {/*<Form.Item label="Delivery" name="Delivery" valuePropName="checked">*/}
                                        {/*    <Switch*/}
                                        {/*        onChange={(e) => {*/}
                                        {/*            setDelivery(e)*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*</Form.Item>*/}
                                        <Form.Item label="Reference Number" name="Ref" rules={[{
                                            warningOnly: true,
                                            message: "Input A Reference Number"
                                        }]}>
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item style={{flex: 'auto', marginLeft: '30%', marginRight: 'auto'}}>
                                            <Button type="primary"
                                                    className={"font_link"}
                                                    htmlType="submit"
                                                    style={{padding: '2px', width: '45%'}}>
                                                Submit
                                            </Button>
                                            <Divider type="vertical"/>

                                            <Button htmlType="button"
                                                    className={"font_link"}
                                                    onClick={onFormClearClick}
                                                    style={{padding: '2px', width: '45%'}}>
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <p className={"font_link"}>
                                        {`*If ordering for delivery please be advised delivery cost will be advised in an email from staff`}
                                    </p>


                                </div>

                            </Col>
                            <Col span={1}>
                                <Divider type={"vertical"}/>
                            </Col>
                            <Col
                                span={11}
                                style={{
                                    height: '100%',
                                    minHeight: '100vh'
                                }}
                            >
                                <Table
                                    style={{
                                        marginTop: '2%',
                                        marginLeft: '5%',
                                        width: '90%',
                                        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                        borderRadius: '20px',
                                        backgroundColor: '#C2C2C2',
                                        padding: '1%'

                                    }}
                                    pagination={{position: ["topRight", "bottomRight"], pageSize: 3}}
                                    dataSource={toJS(UserCartStore.cart)}
                                    columns={columns}>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={900}>
                    <div className="Resource" style={{
                        //  backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    >
                        <div
                            style={{
                                paddingLeft: "5%",
                                paddingRight: "5%",
                                paddingTop: "2%",
                                paddingBottom: '2%'
                            }}
                        >
                            <Row
                                style={{}}
                            >
                                {/*<Row*/}
                                {/*    style={{*/}
                                {/*        padding: '2%',*/}
                                {/*        marginTop: '2%',*/}
                                {/*        width: '100 %',*/}
                                {/*        marginLeft: '5%',*/}
                                {/*        backgroundColor: "rgba(82, 171, 210, 0.5)",*/}
                                {/*        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',*/}
                                {/*        borderRadius: '20px',*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Col span={11}>*/}
                                {/*        <Statistic title="Order Total (inc)"*/}
                                {/*                   value={UserCartStore.CartTotalInc}*/}
                                {/*                   prefix={<DollarOutlined/>}*/}
                                {/*                   className={"font_link"}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*    <Col span={2}>*/}
                                {/*        <Divider type={"vertical"}/>*/}
                                {/*    </Col>*/}
                                {/*    <Col span={11}>*/}
                                {/*        <Statistic title="Order Total (ex)"*/}
                                {/*                   value={UserCartStore.CartTotalEx}*/}
                                {/*                   prefix={<DollarOutlined/>}*/}
                                {/*                   className={"font_link"}*/}
                                {/*        />*/}
                                {/*    </Col>*/}

                                {/*</Row>*/}
                                <div
                                    style={{
                                        padding: '2%',
                                        marginTop: '2%',
                                        width: '100%',
                                        marginLeft: '5%',
                                        backgroundColor: "rgba(82, 171, 210, 0.5)",
                                        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                        borderRadius: '20px'
                                    }}
                                >
                                    <h1 className="font_link">Checkout</h1>
                                    <Spin spinning={requestLoad} size={'large'} />
                                    <h3 className={"font_link"}>Please Enter the Following Details</h3>
                                    <Form
                                        form={form1}
                                        name="basic"
                                        labelCol={{span: 8}}
                                        wrapperCol={{span: 16}}
                                        layout="horizontal"
                                        disabled={!FormEnabled}
                                        initialValues={
                                            userStore.CheckOutDetails
                                        }

                                        onFinish={onFormFinish}
                                        onFinishFailed={onFormFinishFailed}
                                    >
                                        <Form.Item label="Name" name="Name" rules={[{required: true}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Email" name="Email" rules={[{required: true}]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Company" name="Company">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Number" name="ContactNumber"
                                                   rules={[{required: true}]}>
                                            <InputNumber style={{width: '100%'}}/>
                                        </Form.Item>
                                        <Form.Item label="Address" name="Address" rules={[{
                                            required: deliveryNeeded,
                                            message: "Please input address"
                                        }]}>
                                            {/*TODO add google geolocation support*/}
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Store" name="Store" rules={[{
                                            required: !deliveryNeeded,
                                            message: "Please select Pick Up Store"
                                        }]}>
                                            <Select defaultValue="MorningSide" options={[
                                                {value: 'MorningSide', label: 'MorningSide'},
                                                {value: 'Calapaba', label: 'Calapaba'},
                                                {value: 'GoldCoast', label: 'GoldCoast'},
                                            ]}>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="Delivery" name="Delivery" valuePropName="checked">
                                            <Switch
                                                onChange={(e) => {
                                                    setDelivery(e)
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item label="Reference Number" name="Ref" rules={[{
                                            warningOnly: true,
                                            message: "Input A Reference Number"
                                        }]}>
                                            <Input/>
                                        </Form.Item>

                                        <Form.Item style={{flex: 'auto', marginLeft: '30%', marginRight: 'auto'}}>
                                            <Button type="primary"
                                                    className={"font_link"}
                                                    htmlType="submit"
                                                    style={{padding: '2px', width: '45%'}}>
                                                Submit
                                            </Button>
                                            <Divider type="vertical"/>

                                            <Button htmlType="button"
                                                    className={"font_link"}
                                                    onClick={onFormClearClick}
                                                    style={{padding: '2px', width: '45%'}}>
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <p className={"font_link"}>
                                        {`*If ordering for delivery please be advised delivery cost will be advised in an email from staff`}
                                    </p>


                                </div>

                            </Row>
                            <Row
                                style={{
                                    height: '100%',
                                    minHeight: '100vh'
                                }}
                            >
                                <Table
                                    style={{
                                        marginTop: '2%',
                                        marginLeft: '5%',
                                        width: '100%',
                                        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                        borderRadius: '20px',
                                        backgroundColor: '#C2C2C2',
                                        padding: '1%'

                                    }}
                                    pagination={{position: ["topRight", "bottomRight"], pageSize: 3}}
                                    dataSource={toJS(UserCartStore.cart)}
                                    columns={columns}>
                                </Table>
                            </Row>
                        </div>
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default observer(Checkout);