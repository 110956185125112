import {Menu, Layout, Button, Drawer, Row, Col, AppProps, Divider, Descriptions} from "antd";
import React from "react";
import "../overlay/overlay.css"
import {EnvironmentOutlined, MailOutlined, PhoneFilled} from "@ant-design/icons";

function StoreLocation({StoreTitle, phonenumber, photo, inverse, address, addressUrl, emailAddress}: {StoreTitle: string, phonenumber: string, photo: string, inverse: boolean, address: string, addressUrl: string, emailAddress: string}) {
    let openingHours = []
    if (StoreTitle === "Gold Coast") {
        openingHours = [
            {
                label: "Monday",
                children: "6am - 3:30am"
            },
            {
                label: "Tuesday",
                children: "6am - 3:30am"
            },
            {
                label: "Wednesday",
                children: "6am - 3:30am"
            },
            {
                label: "Thursday",
                children: "6am - 3:30am"
            },
            {
                label: "Friday",
                children: "6am - 3:30am"
            },
        ]
    } else {
        openingHours = [
            {
                label: "Monday",
                children: "5:30am - 4pm"
            },
            {
                label: "Tuesday",
                children: "5:30am - 4pm"
            },
            {
                label: "Wednesday",
                children: "5:30am - 4pm"
            },
            {
                label: "Thursday",
                children: "5:30am - 4pm"
            },
            {
                label: "Friday",
                children: "5:30am - 4pm"
            },
        ]
    }

    if (!inverse) {
        return (
            <Row
                style={{
                    backgroundColor: "rgba(245, 255, 250, 0.8)",
                    minHeight: "10vw",
                    height: '100%',
                    width: "85vw",
                    borderRadius: "40px",

                }}
            >
                <Col
                    span={14}
                    className="StoreDetails"
                    style={{
                        height: "100%",
                        width: "100%",
                        borderTopLeftRadius: "40px",
                        borderBottomLeftRadius: "40px",
                        backgroundColor: "rgba(245, 255, 250, 1)",
                        //backgroundColor: 'mintcream',
                        paddingLeft: '3%'
                    }}
                >
                    <Row style={{display: 'inline-flex',
                        alignItems: 'center'}}>
                        <h1  style={{justifyItems: 'center', alignContent: 'center'}} className="font_link">{`${StoreTitle} Store`}</h1>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div
                                style={{
                                    marginLeft: '1%'
                                }}
                            >
                                <Descriptions
                                    column={1}
                                    items={[
                                        {
                                            label: <p><PhoneFilled style={{fontSize: '2em'}}/></p>,
                                            children: <a href={`tel:${phonenumber}`} style={{color: 'black', fontSize: '130%'}}><p>{phonenumber}</p></a>
                                        },
                                        {
                                            label: <p><EnvironmentOutlined style={{fontSize: '2em'}}/></p>,
                                            children: <p
                                                style={{
                                                    cursor: "pointer",
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'visible',
                                                    color: 'black',
                                                    fontSize: '130%'
                                                }}
                                                onClick={() => {
                                                    const newWindow = window.open(addressUrl, '_blank', 'noopener,noreferrer')
                                                    if (newWindow) newWindow.opener = null;
                                                }}

                                            >
                                                {address}
                                            </p>
                                        },
                                        {
                                            label: <p><MailOutlined style={{fontSize: '2em'}}/></p>,
                                            children: <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '130%'}}><p>{emailAddress}</p>
                                            </a>
                                        }
                                    ]}
                                >
                                </Descriptions>

                            </div>
                        </Col>
                        <Col style={{marginLeft: '3%', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}} span={10}>
                            <h3 className={"font_link"}>Opening Hours:</h3>
                            <Descriptions
                                style={{
                                    paddingLeft: '15%'
                                }}
                                column={1}
                                items={openingHours}
                            ></Descriptions>
                        </Col>
                    </Row>

                </Col>
                <Col
                    span={10}
                    className="StoreImage"
                    style={{
                        height: "100%",
                        width: "100%",
                        borderBottomLeftRadius: "40px",
                        borderTopLeftRadius: "40px",

                    }}
                >
                    <img src={photo} style={{
                        width: "100%",
                        padding: '2%',
                        maxWidth: '40vw',
                        height: 'fit-content',
                        maxHeight: '26vh',
                        borderBottomRightRadius: "40px",
                        borderTopRightRadius: "40px"
                    }}
                    />
                </Col>
            </Row>
        )
    }
    return (
        <Row
            style={{
                background: "rgba(43, 196, 217, 1)",
                minHeight: "10vw",
                height: '100%',
                width: "85vw",
                borderRadius: "40px"
            }}
        >
            <Col
                span={10}
                className="StoreImage"
                style={{
                    height: "100%",
                    width: "100%",
                    borderBottomRightRadius: "40px",
                    borderTopRightRadius: "40px",
                }}
            >
                <img src={photo} style={{
                    height: 'fit-content',
                    maxHeight: '28vh',
                    overflow: 'clip',
                    borderBottomLeftRadius: "40px",
                    borderTopLeftRadius: "40px"}}/>
            </Col>
            <Col
                span={14}
                className="StoreDetails"
                style={{
                    height: "100%",
                    width: "100%",
                    borderTopRightRadius: "40px",
                    borderBottomRightRadius: "40px",
                    backgroundColor: "rgba(245, 255, 250, 1)",
                    //backgroundColor: 'mintcream',
                    paddingLeft: '3%'
                }}
            >
                <Row style={{display: 'inline-flex',
                    alignItems: 'center'}}>
                    <h1  style={{justifyItems: 'center', alignContent: 'center'}} className="font_link">{`${StoreTitle} Store`}</h1>
                </Row>
                <Row>
                    <Col span={12}>
                        <div
                            style={{
                                marginLeft: '1%'
                            }}
                        >
                            <Descriptions
                                column={1}
                                style={{paddingBottom: '0px'}}
                                contentStyle={{paddingBottom: '0px'}}
                                labelStyle={{paddingBottom: '0px'}}
                                items={[
                                    {
                                        label: <p><PhoneFilled style={{fontSize: '2em'}}/></p>,
                                        children: <a href={`tel:${phonenumber}`} style={{color: 'black', fontSize: '130%'}}><p>{phonenumber}</p></a>
                                    },
                                    {
                                        label: <p><EnvironmentOutlined style={{fontSize: '2em'}}/></p>,
                                        children: <p
                                            style={{
                                                cursor: "pointer",
                                                textOverflow: 'ellipsis',
                                                overflow: 'visible',
                                                color: 'black',
                                                fontSize: '130%'
                                            }}
                                            onClick={() => {
                                                const newWindow = window.open(addressUrl, '_blank', 'noopener,noreferrer')
                                                if (newWindow) newWindow.opener = null;
                                            }}

                                        >
                                            {address}
                                        </p>
                                    },
                                    {
                                        label: <p><MailOutlined style={{fontSize: '2em'}}/></p>,
                                        children: <a href={`mailto:sales@bspb.com.au`} style={{color: 'black', fontSize: '130%'}}><p>{emailAddress}</p>
                                        </a>
                                    }
                                ]}
                            >
                            </Descriptions>

                        </div>
                    </Col>
                    <Col style={{marginLeft: '3%', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}} span={10}>
                        <h3 className={"font_link"}>Opening Hours:</h3>
                        <Descriptions
                            style={{
                                paddingLeft: '15%'
                            }}
                            column={1}
                            items={openingHours}
                        ></Descriptions>
                    </Col>
                </Row>

            </Col>
        </Row>
    )
}

export default StoreLocation;
