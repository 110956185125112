import {Card, Col, Image, List, Row, Radio, Space, Tabs} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function CeilingSystems() {

    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>
            <Tabs
                defaultActiveKey="1"
                items={[
                    {
                        label: "USG Boral",
                        key: "1",
                        forceRender: true,
                        children: <div>
                            <Row>
                                <Col span={11}>
                                    <Card title={"DONN® Centricitee™ DXT Acoustical Suspension System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    This ceiling suspension system has an innovative, proprietary
                                                    self-centring device built into each runner, preventing panel
                                                    lip-out.
                                                </p><p>
                                                DONN® Centricitee™ DXT Acoustical Suspension System has a narrow 15mm
                                                face,
                                                offering an alternative to 24mm exposed grid systems. Equipped with
                                                offset
                                                ends that rest on tees to eliminate sagging and twisting, they are also
                                                designed for quick installation and removal, reducing installation and
                                                renovation costs.
                                            </p><p>
                                                The DONN® Centricitee™ DXT Acoustical Suspension System is designed to
                                                maintain the appearance and integrity of your ceilings panelling. It
                                                allows
                                                for the use of standard square-edge panels and will hold their position
                                                without sagging or twisting.
                                            </p>
                                                <List dataSource={[
                                                    "15mm exposed grid offers clean aesthetic",
                                                    "Proprietary panel-centring device allows use of several standard square-edge panels",
                                                    "Refer to specific ceiling panel for limitations with square edge panels",
                                                    "Compatible with Logix™ Integrated Ceiling Systems",
                                                    "Cross tees override-ends resist twisting and ensure a finished look",
                                                    "Meets or exceeds all New Zealand and Australia seismic code requirements",
                                                    "Design flexibility helps meet life safety codes",
                                                    "Proprietary Quick-Release™ cross tees",
                                                    "Optional integrated air diffuser",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>


                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/MAIN_Centricitee-870x569_en-600x392.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Physical Properties</h5>
                                                <List dataSource={[
                                                    "Type: Grid, Ceiling Suspension System, Standard Suspension Systems",
                                                    "For use in a fire rated system",
                                                    "Material: Steel",
                                                    "Colour(s): White",
                                                    "15mm Exposed Grid",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Applications</h5>
                                                <List dataSource={[
                                                    "General Offices",
                                                    "Hotels",
                                                    "Retail Malls",
                                                    "Banks",
                                                    "Reception/lobbies",
                                                    "Board Rooms",
                                                    "Showrooms",
                                                    "Education",
                                                    "Medical",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Card title={"DONN® DX®/DXL™ Acoustical Suspension System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    The most widely used acoustical suspension system available; this
                                                    economical, simple and versatile system allows for maximum access.
                                                </p><p>
                                                DONN® DX®/DXL™ Acoustical Suspension System is rust resistant and offers
                                                appropriate load-carrying capacities for acoustical panels, light
                                                fixtures
                                                and air distribution elements. The 24mm exposed tee grid system can be
                                                used
                                                in both general and fire-rated applications and is engineered for many
                                                options for seismic resistance.
                                            </p><p>
                                                Also available with an innovative Healthcare Coating option, promoting
                                                wellness by offering tough antimicrobial protection.
                                            </p><p>
                                                The DONN® DX®/DXL™ Acoustical Suspension System is made with a
                                                galvanised
                                                steel body that inhibits rust. It is twist resistant, offering a clean
                                                look
                                                without exposed steel edges.
                                            </p>
                                                <List dataSource={[
                                                    "24mm exposed tee grid system",
                                                    "Components for use in general and fire-rated applications",
                                                    "Maximum economy and design simplicity",
                                                    "Compatible with Logix™ Integrated Ceiling Systems",
                                                    "DXL system tested to AS/NZS 1530.4 for systems up to 60/60/60",
                                                    "Cross-tee override-ends resist twisting and give a professional, finished look",
                                                    "Meets or exceeds all New Zealand and Australia seismic code requirements",
                                                    "Proprietary Quick-Release™ cross tees",
                                                ]}
                                                      renderItem={(item) => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/MAIN_DONNDX-DXL-870x569_en-600x392.jpg"}
                                                    alt={"Bayside Product"}/>

                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Physical Properties</h5>
                                                <List dataSource={[
                                                    "Type: 24mm Exposed Grid, Ceiling Suspension System, Standard Suspension Systems",
                                                    "For use in a fire rated system",
                                                    "Material: HDG Steel",
                                                    "Colour(s): Flat Black (205), White (050)",
                                                    "24mm Exposed Grid",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Applications</h5>
                                                <List dataSource={[
                                                    "General Offices",
                                                    "Hotels",
                                                    "Retail Malls",
                                                    "Banks",
                                                    "Reception/lobbies",
                                                    "Board Rooms",
                                                    "Showrooms",
                                                    "Education",
                                                    "Medical",
                                                    "Industrial",
                                                    "Food Preparation Areas",
                                                    "Fire rated areas",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '1%'}}>
                                <Col span={11}>
                                    <Card title={"Fineline™ DXF™ Acoustical Suspension System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    USG Donn® Fineline™ DXF™ Acoustical Suspension System allows for
                                                    easy
                                                    ceiling access, while its narrow slotted profile, with a 14mm wide
                                                    face
                                                    and a 6.4mm reveal, hides the suspension system behind acoustical
                                                    panels. Its 6.4mm slot accommodates partition attachments and
                                                    pendent
                                                    mounted light fixtures.
                                                </p><p>
                                                Fineline™ suspension system provides a premium monolithic ceiling look
                                                with
                                                a wide variety of layouts, low cost, quick installation and access
                                                benefits
                                                of standard exposed grid. Its narrow slotted profile makes the Fineline
                                                suspension system practically disappear into acoustical panels to form a
                                                smooth, clean ceiling plane with less exposed metal.
                                            </p><p>
                                                Fineline™ components are also included in the Logix Integrated Ceiling
                                                System which allows Designers to organize utilities along 150mm channels
                                                and
                                                select from a wide variety of layouts and panel sizes. Mitered
                                                intersections
                                                give Fineline grid the crisp architectural detailing preferred in
                                                today’s
                                                office environment. And its all-steel hot-dipped galvanized construction
                                                means strength, dimensional stability and superior corrosion resistance
                                                at
                                                low cost.
                                            </p>

                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/MAIN_DONNDX-DXL-870x569_en-600x392.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Features and Benefits</h5>
                                                <List dataSource={[
                                                    "Narrow-profile, slotted grid system with (6mm 1/4”) reveal provides streamlined appearance",
                                                    "Reveal accommodates partition attachments and pendantmounted light fixtures",
                                                    "Mitered intersections offer a clean, tailored appearance",
                                                    "Meets or exceeds all national code requirements, including seismic.",
                                                    "Optional integrated air diffuser",
                                                    "Accepts metal ceiling panels for ceiling-area accents without changing grid system",
                                                    "Custom colors available",
                                                    "High recycled content (HRC) available",
                                                    "Compatible with USG LogixTM Integrated Ceilings Systems",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Applications</h5>
                                                <List dataSource={[
                                                    "All interior general use areas",
                                                    "Logix Integrated Ceilings Systems",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Card title={"Logix™ Integrated Ceiling System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    Turn your ceiling’s lighting and utilities into stunning design
                                                    elements
                                                    by integrating them into your ceiling plan with this system.
                                                </p><p>
                                                Logix™ Integrated Ceiling System combines striking visual design
                                                elements
                                                and top-level performance. This flexible and environmentally efficient
                                                system allows you to meet building requirements while transforming your
                                                ceiling with a sleek modern finish that is ideal for virtually any
                                                commercial interior.
                                            </p><p>
                                                Logix™ Integrated Ceiling Systems incorporate a wide range of products
                                                from
                                                best-in-class suppliers of light fixtures, air diffusers, returns and
                                                sprinklers; ensuring an integrated ceiling with superior fit and finish.
                                            </p>
                                                <p>Up to 90% Recycled</p>
                                                <List dataSource={[
                                                    "Monolithic, structured ceiling visual using standard components",
                                                    "High light-reflective finish reduces light fixtures and energy use",
                                                    "Coordinates visually with Halcyon™ and Mars™ Logix panels for open/closed plan applications",
                                                    "ClimaPlus™ 30-year lifetime system warranty against visible sag, mould and mildew",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/MAIN_Logix_Coalesse_HR_v3-870x569_en.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Physical Properties</h5>
                                                <List dataSource={[
                                                    "Type: Metal Specialty Ceiling Panels, Connector Ceiling Panels, Specialty Suspended Ceiling Panels",
                                                    "Material: HDG Steel",
                                                    "Humidity Resistant",
                                                    "Moisture and Mould Resistant",
                                                    "Sag Resistant",
                                                    "Abuse Resistant",
                                                    "Colour(s): Flat White (050)",
                                                    "Edge Profile(s): Fineline, Flush Mount",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Applications</h5>
                                                <List dataSource={[
                                                    "Building Environments: Convention/Meeting Rooms, ",
                                                    "Department Stores/Boutiques, ",
                                                    "Executive Areas/Conference Areas/Board Rooms, ",
                                                    "Galleries/Exhibit Spaces, ",
                                                    "Lobbies and Reception Areas, ",
                                                    "Mall Interior Spaces, ",
                                                    "Media Centres/Music Rooms/Libraries, ",
                                                    "Open Plan Offices",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '1%'}}>
                                <Col span={11}>
                                    <Card title={"USG Boral Drywall Grid - Wall to Wall System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>Eliminate or minimise suspension with the USG Boral Wall to Wall
                                                    Drywall
                                                    Grid System, simplified design and construction for use with flush
                                                    plaster ceiling applications for corridors and small rooms.</p>
                                                <List dataSource={[
                                                    "Industry leading spans eliminate hanger wires and cross tees for unsupported spans up to 2200mm, with Sheetrock¨ Brand 13mm standard or 10mm ceiling board",
                                                    "Pre-engineered components allow easy transition between ceiling types and elevations",
                                                    "Hot dipped galvanised system suitable for interior and exterior applications",
                                                    "Standard and custom lengths available",
                                                    "Manufactured in accordance with AS/NZS 2785, AS/NZS 4600, AS/NZS 1170, AS/ NZS 2589",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image src={"./assets/images/productRanges/Drywall_Grid-870x569_en.jpg"}
                                                       alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Features and Benefits</h5>
                                                <List dataSource={[
                                                    "Type: Grid, Ceiling Suspension System, Standard Suspension Systems",
                                                    "For use in a fire rated system",
                                                    "Material: Steel",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Applications</h5>
                                                <List dataSource={[
                                                    "Condominiums and Apartments",
                                                    "Healthcare",
                                                    "Education",
                                                    "Hotels",
                                                    "Dormitories",
                                                    "Office",
                                                    "Flush Plaster Applications",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Card title={"USG Boral Drywall Suspension System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    USG Boral’s Drywall Grid System cleverly merges with DONN® Brand
                                                    exposed
                                                    grid systems, enabling plasterboard or other materials to be screw
                                                    fixed
                                                    to the Drywall Grid. Due to this evolution from DONN® Brand grid,
                                                    these
                                                    two systems interconnect to provide various transition options from
                                                    acoustical to plasterboard ceilings in the same plane.
                                                </p>
                                                <List dataSource={[
                                                    "Pre-engineered components allow easy transition between ceiling types and elevations",
                                                    "Hot dipped galvanized system suitable for interior and exterior applications",
                                                    "Standard and custom lengths available",
                                                    "Manufactured in accordance with AS/NZS 2785, AS/NZS 4600, AS/NZS 1170, AS/NZS 2589",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/Impressions_HighNRC-870x569.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Physical Properties</h5>
                                                <List dataSource={[
                                                    "Type: Grid, Ceiling Suspension System, Standard Suspension Systems",
                                                    "For use in a fire rated system",
                                                    "Material: Steel",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Applications</h5>
                                                <List dataSource={[
                                                    "Condominiums and Apartments",
                                                    "Healthcare",
                                                    "Education",
                                                    "Hotels",
                                                    "Dormitories",
                                                    "Office",
                                                    "Flush Plaster Applications",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    },
                    {
                        label: "Rondo",
                        key: '2',
                        forceRender: true,
                        children: <div>
                            <Row>
                                <Col span={11}>
                                    <Card title={"Rondo Aluminium Grid Ceiling System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    The Rondo Aluminium Grid Ceiling System is an alternative to the Rondo DUO Exposed Grid Ceiling System. Unlike the DUO system, the Aluminium main tee to cross tee intersection is a butt joint which provides a flat, ghost free surface into which the ceiling panel fits.
</p><p>
                                                    The cross tees have integrated locking tags enabling them to snap together positively at intersections whilst the main tee has a separate splicing plate to join lengths of the main tee together.
                                            </p>
                                                <h4>Advantages</h4>
                                                <List dataSource={[
                                                    "Available in pre-finished powder-coated white",
                                                    "Intersection butt joint for ghost-free surface",
                                                    "Compatible with other Rondo components including hanger brackets, aluminium angle and shadowline perimeter trims",
                                                    "Accepts both mineral fibre and plasterboard ceiling panels",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/Rondo-Aluminium-Grid-Ceiling-System.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Card title={"Rondo Duo Exposed Grid Ceiling System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    The Rondo DUO® Exposed Ceiling Grid System is a practical,
                                                    convenient ceiling system. It has a complete range of main sections
                                                    and complementary parts so that you can adapt the modules to suit
                                                    your design needs.
                                                </p><p>
                                                The smart design of Rondo DUO® Exposed Ceiling Grid System components
                                                means they are easily assembled on site, and can be adjusted or replaced
                                                without damage. All components have been designed and tested by Rondo
                                                Engineers to meet the requirements of suspended ceiling codes in both
                                                Australia and New Zealand.
                                            </p><p>
                                                Rondo DUO® Exposed Ceiling Grid System consists of 24mm wide steel Main
                                                and Cross-tees, to support acoustic and plasterboard ceiling tiles in
                                                non-fire rated environments. The Cross Tees will positively lock into
                                                each other through the Main Tee with a gentle push, creating a sturdy
                                                structure.
                                            </p>
                                            </Col>
                                            <Col span={2}></Col>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/Duo-Exposed-Grid-Ceiling-System.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                            <Row>
                                                <Col span={12}>
                                                    <h5>What you’ll like about the Duo® Ceiling System?</h5>
                                                    <List dataSource={[
                                                        "Available in pre-finished steel",
                                                        "Engineered for fast assembly on site",
                                                        "Double rows of embossed stitching on tees to increase torsional strength",
                                                        "Able to be removed/reinstalled without damage",
                                                        "Main tee slots every 100mm for Cross Tees gives extra layout flexibility",
                                                        "Unique “Zipper” box, better protects and stores products",
                                                    ]}
                                                          renderItem={(item) => (<p><MinusOutlined/>{item}</p>)}/>
                                                </Col>
                                                <Col span={1} />
                                                <Col span={11}>
                                                    <h5>What is the Duo® Ceiling System suitable for?</h5>
                                                    <List dataSource={[
                                                        "Steel Ceiling Grid Systems",
                                                        "Aluminium Ceiling Grid Systems",
                                                        "Drop-in Configurations",
                                                        "One-way semi-concealed configurations",
                                                        "Seismic designs",
                                                        "Bulkhead designs",
                                                        "Lineal diffusers",
                                                    ]}
                                                          renderItem={(item) => (<p><MinusOutlined/>{item}</p>)}/>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '1%'}}>
                                <Col span={11}>
                                    <Card title={"Rondo Key-Lock® Concealed Direct Fix Ceiling System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    For those who need a more direct approach, Rondo supplies a wide range of direct fixing clips that allows you to direct fix Furring Channels or Ceiling Battens to either concrete, steel or timber ceiling structures for suspension depths of up to 200mm.
</p><p>
                                                    If you need a greater suspension depth, you can install a Rondo Key-Lock® suspended ceiling system.
                                            </p><p>
                                                    Direct fixing clips need to be fixed along the sections in accordance with the relevant maximum span tables and the Furring Channels should be spaced as per the building board manufacturers’ recommendations.
                                                </p>
                                                <h4>Advantages to Metal Ceiling Battens</h4>
                                                <p>
                                                    Using the metal Ceiling Battens in residential applications will cut back on the cost of call-back maintenance as they help control movement, reducing the chance of lining board cracks. We have a range of ceiling batten systems which are suitable for truss spacings from 600mm to 1200mm and for use in cyclonic and high wind areas.
                                                </p>
                                                <p>
                                                    The Ceiling Battens and accompanying clips have been load tested to ensure they meet all relevant standard requirements.
                                                </p><p>
                                                    Two of the most popular clips are the 314 Direct Fixing Clip and the 390 Batten Swivel Clip.
                                            </p><p>
                                                    314 Direct Fixing Clip – to accommodate the increasing use of timber “I” beams, the 314 Clip has two extra nail or screw slots lower down on the clip, with an additional holding tab to assist installation.
                                            </p><p>
                                                    Cleverly, the temporary holding tab is tapped into the timber beam when the clip is at the right level freeing up both your hands to secure the clip.
                                            </p><p>
                                                    390 Batten Swivel Clip – with the ability to rotate 360°, the swivel clip can easily turn the ceiling batten on an angle that suits the change in roof truss direction. It also incorporates the temporary holding tab for quick and easy installation.
                                                </p>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/Key_Lock_direct-fix_Ceiling_System_2016_874x371.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Card title={"Rondo Key-Lock® Concealed Suspended Ceiling System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    The KEY-LOCK® Concealed Suspended Ceiling System is designed to produce a high-quality structure for a flush or featured finish to your plasterboard ceiling.
                                                </p><p>
                                                    We have two options for this ceiling system. You can either direct-fix or fully suspend your KEY-LOCK® Concealed Ceiling System.
                                            </p><p>
                                                    However, if it’s a suspension depth of more than 200mm you’re after, then the most popular, fully suspended KEY-LOCK® ceiling system is the way to go.   Available for fire-rated, bulkhead, seismic and acoustic designs, it is strong enough to hold multiple layers of plasterboard.
                                            </p><p>
                                                    The ceiling grid system enables the mixing of primary (Top Cross Rail) and secondary components (Furring Channel) to increase spans, suspension fixing points and maximise structural design.
                                            </p><p>
                                                    The components within the Rondo ceiling system have been expertly designed to simply snap into sections, without the aid of mechanical tools. This offers Contractors an easy to install ceiling system, speeding up valuable time on site.
                                            </p><p>
                                                    What’s more, the innovative Top Cross Rail and Furring Channel sections have rolled edges making them safer to handle and together with the state-of-the-art manufacturing processes, we can also produce these profiles in custom lengths or radiuses for vaulted and curved ceilings.
                                            </p><p>
                                                    Not only that, the KEY-LOCK® Ceiling System can be installed with the Acoustic Mounts to improve airborne sound and impact sound transmitted between floors particularly important in multi-residential projects.
                                            </p><p>
                                                    Additionally, the Rondo Technical Team can provide seismic design solutions using the KEY-LOCK® Ceiling System, which have been tested to meet Australian and New Zealand building codes.
                                            </p><p>
                                                    So, if you are after a ceiling system that stands the test of time and provides time saving and safety innovation features, you can’t go past the KEY-LOCK Ceiling System.
                                                </p>


                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/Key_Lock_Suspended_Ceiling_System_2016_874x371.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h4>What you’ll like about the key-Lock Ceiling System</h4>
                                                <List dataSource={[
                                                    "Furring Channels and Top Cross Rails are available in radiuses for curved ceilings",
                                                    "Furring Channels, Ceiling Battens and Top Cross Rails can be ordered in special lengths",
                                                    "Able to be engineered designed for external ceiling use, e.g. Soffits",
                                                    "Base Material is engineered from G2 BlueScope Steel for strength and reliability",
                                                    "Rondo range of ceilings can interlock with each other in the same ceiling area, providing specialised ceilings after partitioning",
                                                    "Allows easy, economical and true bulkhead corner ceiling finishes to be achieved by using furring channel along with concealed support clips and fixings",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h4>What is the Key-Lock Ceiling System suitable for?</h4>
                                                <List dataSource={[
                                                    "Flush plasterboard ceilings",
                                                    "Direct Fix or Fully Suspended ceiling applications",
                                                    "Suspended Ceilings for internal applications",
                                                    "Fire Rated Ceiling System Applications",
                                                    "Curved Ceiling Systems using pre-curved furring channel and top cross rail",
                                                    "Raked and Cantilevered Ceilings",
                                                    "Bulkheads as an architectural feature or concealing services",
                                                    "Wall Framing for masonry walls – battening out irregular walls or providing a cavity for cables and plumbing",
                                                    "Suspended Ceilings for External Applications",
                                                    "Acoustic Ceiling Design available",
                                                    "Seismic Ceiling Design available",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row style={{marginTop: '1%'}}>
                                <Col span={11}>
                                    <Card title={"Rondo Rapid® Drywall Grid System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    Rondo RAPID® is a light-weight grid system that is fast, simple to install and can be used in a variety of applications such as, suspended or direct-fix ceilings, curved ceilings, bulkheads and wall applications.
                                                </p><p>
                                                    The system includes Main Tee and Cross Tees, as well as complementary accessories and can be suspended or directly fixed to the structure above.
                                            </p><p>
                                                    The Rondo RAPID® Drywall Grid System is particularly useful in corridors where services such as; air conditioning ducts, electrical and data cabling, fire sprinkler pipework and light fixtures make it nearly impossible to install a suspended ceiling.
                                            </p><p>
                                                    RAPID® can achieve spans of up to 1600mm without the use of suspension hangers (making it ideal for small rooms).
                                            </p><p>
                                                    Not only that, within the system is a clip that allows a seamless transition between the RAPID® and DUO® ceiling systems, making switching from concealed grid and exposed grid, a breeze.
                                            </p><p>
                                                    For your convenience, RAPID® primary components follow the same naming conventions as the DUO® Exposed Grid Ceiling System, such as RAP1 Main Tee and RAP2 Cross Tee, similar to the DUO1 Main Tee and DUO2 Cross Tee.
                                            </p><p>
                                                    Main Tee, Cross Tees and Wall Trims are all available in box packaging, making them easy to transport and carry, and RAPID® accessories come in either a box or bucket.
                                            </p><p>
                                                    The Rondo RAPID® Drywall Grid System is a quick, simple and easy to install ceiling system which makes it a good choice for small rooms and corridors, however for more complex jobs requiring a concealed ceiling system that’s fire-rated and available with acoustic and seismic designs, check out the KEY-LOCK® Concealed Ceiling System.
                                                </p>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image src={"./assets/images/productRanges/Rondo-Rapid-Drywall-Grid-System-600x345.jpg"}
                                                       alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Rondo Rapid® suitable for:</h5>
                                                <List dataSource={[
                                                    "Light-weight concealed plasterboard ceilings",
                                                    "Direct fix or fully suspended applications",
                                                    "Curved plasterboard ceilings",
                                                    "Seamless transitions from a concealed to exposed grid system",
                                                    "Bulkheads, including multiple steps and curved",
                                                    "Corridors",
                                                    "Non-fire rated systems",
                                                    "Wall applications",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Special Features:</h5>
                                                <List dataSource={[
                                                    "Surface Finish: Z275 galvanised",
                                                    "Low profile with 38mm Wide Face",
                                                    "Integration with Rondo DUO® Exposed Grid Ceiling System",
                                                    "Simple Integration of services",
                                                    "Main Tee and Cross Tee clips have a secure connection and are easy to remove and relocate",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <Card title={"Rondo Steel Stud & Track Drywall Ceiling System"}>
                                        <Row>
                                            <Col span={11}>
                                                <p>
                                                    Rondo Steel Studs may be used as a ceiling structure, which is particularly useful in situations where it is difficult to install a suspended ceiling system.
                                                </p><p>
                                                    Typical applications include corridors, bathrooms or open roof areas. In corridors it is often used to hide services such as air conditioning ducts, fire sprinkler pipework and electrical and data cabling.
                                            </p><p>
                                                    By using the Rondo 51mm x 0.50bmt Steel Stud along with the matching steel track on the walls, you can span over 1800mm at 600mm centres for 10mm plasterboard lining. For 16mm plasterboard lining, it will span over 1700mm when spaced 600mm apart. You can download our Steel Stud Ceiling System Span Tables for details of single or continuous spans, and single or boxed studs.
                                            </p><p>
                                                    The span tables also show the minimum rows of bridging based on ceiling span and lining condition (lined one side). Bridging in a ceiling serves the same purpose as Noggings do in a stud wall.
                                            </p><p>
                                                    Rondo Steel Studs can also be used for ceiling bulkheads where a drop greater than 600mm makes it difficult to use our Furring Channels. To carry the additional layers of plasterboard and framing members, bulkheads require independent suspension. This can be achieved by fixing the framing directly to the structural soffit or providing additional suspension hangers to the bulkhead. All bulkheads require bracing to provide lateral stability to the framework during incidental loading. This may be achieved by providing stud bracing, fixed diagonally between the bulkhead framework and the structural soffit at regular intervals, or alternatively, by rigidly coupling the ceiling to the bulkhead. Learn more…
                                            </p><p>
                                                    Ceiling Friction Joints using Steel Stud provide allowance for movement of building elements and Mechanical Joints are required where the maximum spans of the ceiling exceeds those allowable for Friction Joints, or where a more positive connection is required. Read more…
                                                </p>
                                            </Col>
                                            <Col span={2}/>
                                            <Col span={11}>
                                                <Image
                                                    src={"./assets/images/productRanges/Rondo-Steel-Stud-Track-Drywall-Ceiling-System.jpg"}
                                                    alt={"Bayside Product"}/>
                                            </Col>
                                        </Row>
                                        <Row style={{width: '100%'}}>
                                            <Col span={12}>
                                                <h5>Suitable for:</h5>
                                                <List dataSource={[
                                                    "Corridors",
                                                    "Bathrooms",
                                                    "Open Rood Areas",
                                                    "Applications where it is difficult to install a suspended system",
                                                    "Fire-raterd and non-fore rated areas",
                                                    "Seismic System by design",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                            <Col span={12} style={{float: 'right'}}>
                                                <h5>Advantages</h5>
                                                <List dataSource={[
                                                    "Useful in areas where it is difficult to intall a suspended ceiling system",
                                                    "Availble in custom lengths",
                                                    "Manufactured from BlueScope Steel with a minimus coating of Z275",
                                                    "Profile widths range from 51 to 150mm, and guages from 0.50 to 1.15bmt.",
                                                ]}
                                                      renderItem={item => (<p><MinusOutlined/>{item}</p>)}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    }
                ]}
            >
            </Tabs>

        </div>
    );
}
