import {observer} from "mobx-react-lite";
import MediaQuery from "react-responsive";
import Overlay from "../../componets/overlay/overlay";
import {
    Col,
    Collapse,
    Divider,
    Flex,
    FloatButton,
    Form,
    Image,
    Input,
    InputNumber,
    Row,
    Select,
    Table,
    Tabs
} from "antd";
import {useForm} from "antd/es/form/Form";
import Button from "antd/es/button";
import OrderFormRow from "../../componets/OrderFormRow/OrderFormRow";
import React, {useContext} from "react";
import CatalogueStore, {CatalogueItem} from "../../stores/catalogue.store";
import {ColumnsType} from "antd/es/table";
import {toJS} from "mobx";
import CartStore from "../../stores/Cart.store";
import {DownCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";

function OrderFormPage() {
    const CatStore = useContext(CatalogueStore);
    const UserCartStore = useContext(CartStore);
    const [form] = useForm();
    const navigate = useNavigate();
    const onFormFinish = (values: any) => {
        const cartArray = CatStore.createOrderFormCartObject(values);
        UserCartStore.FormToCart(cartArray);
        navigate("/checkout")
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };
    const DesktopColumns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'Description',
            key: 'Description',
            render: (record: any, index: number) => {
                return (
                    <div
                    >
                        <Row>
                            <Col> <Image src="../../assets/images/plasterboard.jpg" height="15vh" width="20vw" /> </Col>
                            <Col style={{marginLeft: '1vw'}}> <h4>{record}</h4> </Col>
                        </Row>
                    </div>
                )
            }
        },
        {
            title: 'Sku',
            dataIndex: 'Id',
            key: 'Id'
        },
        {
            title: 'Supplier',
            dataIndex: 'Category',
            key: 'Category',
        },
        {
            title: 'Add To Cart',
            dataIndex: 'Name',
            key: 'operation',
            render: (text, record: any, index: number) => {
                let itemCount = 0;
                return (
                    <div>
                        <InputNumber
                            min={0}
                            style={{
                                marginRight: '0.5vw'
                            }}
                            onChange={(value) => {
                                if (value === null) return
                                itemCount = value;
                            }}
                        />


                        <button
                            onClick={() => {
                                UserCartStore.AddToCart({...record, Quantity: itemCount});
                                itemCount = 0;
                            }}
                        >Add</button>
                    </div>
                )
            }
        },
        {
            title: 'Price Inc',
            dataIndex: 'PriceInc',
            key: 'PriceInc',
            render: ((text, record: CatalogueItem, index: number) => {
                return (
                    <div>
                        <p>{`$${text} / ${record.ItemType}`}</p>
                    </div>
                )
            })
        }
    ]

    const MobileColumns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'Description',
            key: 'Description',
            render: (record: any, index: number) => {
                return (
                    <div
                    >
                        <Row>
                            <Col> <Image src="../../assets/images/plasterboard.jpg" height="15vh" width="20vw" /> </Col>
                            <Col style={{marginLeft: '1vw'}}> <h4>{record}</h4> </Col>
                        </Row>
                    </div>
                )
            }
        },
        {
            title: 'Add To Cart',
            dataIndex: 'Name',
            key: 'operation',
            render: (text, record: any, index: number) => {
                let itemCount = 0;
                return (
                    <div>
                        <p>{`Sku: ${record.Id}`}</p>
                        <InputNumber
                            min={0}
                            style={{
                                marginRight: '0.5vw'
                            }}
                            onChange={(value) => {
                                if (value === null) return
                                itemCount = value;
                            }}
                        />


                        <button
                            onClick={() => {
                                UserCartStore.AddToCart({...record, Quantity: itemCount});
                                itemCount = 0;
                            }}
                        >Add
                        </button>
                        <p>{`$${record.PriceEx} / ${record.ItemType}`}</p>
                    </div>
                )
            }
        },
    ]

    return (
        <Overlay>
            <Helmet>
                <title>Order Form</title>
                <meta name="description" content={"OrderForm Bayside PlasterBoard"} />
                <meta name="keywords" content="plasterboard, Bayside, Order Form" />
                <meta property="og:title" content="Order Form" />
                <meta property="og:description" content="Order Form" />
            </Helmet>
            <MediaQuery minWidth={901}>
                <div style={{paddingLeft: '15%', paddingRight: '12%', height: '100%', minHeight: '70vh', marginBottom: '2%'}}>

                    <Row>
                        <Col span={10}>
                            <h1 className={"font_link"}>Order Form</h1>
                        </Col>
                        <Col span={1} style={{paddingTop: '1.5%'}}>
                            <Button
                                className={"font_link"}
                                style={{paddingLeft: '5%'}}
                                type="primary"
                                icon={<DownCircleOutlined/>}
                                onClick={()=>{
                                    UserCartStore.DownloadOrderForm();
                                }}
                            >Download Form </Button>
                        </Col>
                    </Row>
                    <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        initialValues={{remember: true}}
                        onFinish={onFormFinish}
                        onFinishFailed={onFormFinishFailed}
                    >
                        <Tabs className={"aboutMainBody"}>
                            <Tabs.TabPane tab="Plasterboard" key="1" forceRender={true}>
                                    <Row>
                                        <Col span={12} >
                                            <OrderFormRow
                                                Title={"OneBoard 10mm Wall"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["O101360", "6000"], ["O101348", "4800"], ["O101342", "4200"], ["O101336", "3600"], ["O101330", "3000"], ['2700', '2700'], ['2400', '2400']]}
                                                lengthensCol2={[["O101260", "6000"], ["O101248", "4800"], ["O101242", "4200"], ["O101236", "3600"], ["O101230", "3000"], ['O101227', '2700'], ['O101224', '2400']]}
                                            />
                                        </Col>
                                        <Col span={12} >
                                            <OrderFormRow
                                                Title={"OneBoard 10mm Ceiling"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["O101360_Ceiling", "6000"], ["O101348_Ceiling", "4800"], ["O101342_Ceiling", "4200"], ["O101336_Ceiling", "3600"], ["O101330_Ceiling", "3000"], ['2700_Ceiling', '2700'], ['2400_Ceiling', '2400']]}
                                                lengthensCol2={[["O101260_Ceiling", "6000"], ["O101248_Ceiling", "4800"], ["O101242_Ceiling", "4200"], ["O101236_Ceiling", "3600"], ["O101230_Ceiling", "3000"], ['O101227_Ceiling', '2700'], ['O101224_Ceiling', '2400']]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"Row1"}>
                                        <Col span={12}>
                                            <OrderFormRow
                                                Title={"13mm Regular"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["R131348", "4800"], ["R131336", "3600"], ["R131330", "3000"]]}
                                                lengthensCol2={[["R131260", "6000"], ["R131248", "4800"], ["R131236", "3600"], ["R131230", "3000"], ['R131227', '2700']]}
                                            />
                                        </Col>
                                        <Col span={1}> <Divider type={"vertical"}/> </Col>
                                        <Col span={11}>
                                            <OrderFormRow
                                                Title={"10mm Wet Area"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["WR101354", "5400"], ["WR101336", "3600"]]}
                                                lengthensCol2={[["WR101242", "4200"], ["WR101236", "3600"], ["WR101230", "3000"], ['WR101224', '2400']]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className={"Row2"}>
                                        <Col span={5}>
                                            <OrderFormRow
                                                Title={"13mm SoundStop"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["SS131336", "3600"]]}
                                                lengthensCol2={[["SS131236", "3600"]]}
                                            />
                                            <OrderFormRow
                                                Title={"10mm SoundStop"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["SS101348", "4800"]]}
                                                lengthensCol2={[["SS101248", "4800"], ["SS101236", "3600"]]}
                                            />
                                        </Col>
                                        <Col span={1}> <Divider type={"vertical"}/></Col>
                                        <Col span={5}>
                                            <OrderFormRow
                                                Title={"1200 FireStop"}
                                                col1={"13mm"}
                                                col2={"16mm"}
                                                lengthensCol1={[["F131236", "3600"], ["F131230", "3000"]]}
                                                lengthensCol2={[["F161236", "3600"], ["F161230", "3000"], ['F161224', '2400']]}
                                            />
                                        </Col>
                                    </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={"Fiber Cement"} key="2" forceRender={true}>
                                <Col span={20}>
                                    <OrderFormRow
                                        Title={"Villa 6mm"}
                                        col1={"1350"}
                                        col2={"1200"}
                                        lengthensCol1={[["V61336", "3600"], ["V61330", "3000"], ["2700", "2700"], ["V61324", "2400"], ["1800", "1800"]]}
                                        lengthensCol2={[["V61236", "3600"], ["V61230", "3000"], ["V61227", "2700"], ["V61224", "2400"], ["V61218", "1800"]]} />
                                </Col>
                                <Col span={20}>
                                    <OrderFormRow
                                        Title={"Villa 9mm"}
                                        col1={"1200"}
                                        col2={""}
                                        lengthensCol1={[["V91236", "3600"], ["V91230", "3000"], ["V91227", "2700"], ["V91224", "2400"], ["1800", "1800"]]}
                                        lengthensCol2={[]} />
                                </Col>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Cornice" key="3" forceRender={true}>
                                    <Col span={20}>
                                        <OrderFormRow
                                            Title={"Decorative Cornice 4200"}
                                            col1={""}
                                            col2={""}
                                            lengthensCol1={[["SC9042", "Sydney 90mm"], ["NC9042", "New York 90mm"], ["MC7542", "Manly 75mm"], ["LC7542", "Linear 75mm"]]}
                                            lengthensCol2={[["C2ST42", "2 Step Cairo"], ["C3ST42", "3 Step Cairo"], ["C4ST42", "4 Step Cairo"]]} />
                                    </Col>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Accessories" key="4" forceRender={true}>
                                    <Col span={20}>
                                        <OrderFormRow
                                            Title={"Accessories"}
                                            col1={"External 90 (PO1)"}
                                            col2={"External 135 (PO1A)"}
                                            lengthensCol1={[["PO136", "3600"], ["EBE9030", "3000"], ["EBE9027", "2700"], ["EBE9024", "2400"]]}
                                            lengthensCol2={[["PO1A36", "3600"], ["PO1A30", "3000"], ["PO1A27", "2700"], ["PO1A24", "2400"]]} />
                                    </Col>
                                    <Col span={1}> <Divider type={"vertical"}/></Col>
                                    <Col span={20}>
                                        <OrderFormRow
                                            Title={"Accessories"}
                                            col1={"Internal 90 (PS17)"}
                                            col2={"Internal 135 (PS1A)"}
                                            lengthensCol1={[["PS1736", "3600"], ["EBI9030", "3000"], ["EBI9027", "2700"], ["EBI9024", "2400"]]}
                                            lengthensCol2={[["PSIA36", "3600"], ["PSIA30", "3000"], ["PSIA30", "2700"], ["PSIA27", "2400"]]} />
                                    </Col>
                                    <Col span={1}> <Divider type={"vertical"}/></Col>
                                    <Col span={20}>
                                        <OrderFormRow
                                            Title={"Paper Tape"}
                                            col1={""}
                                            col2={""}
                                            lengthensCol1={[["PT150", "150M"]]}
                                            lengthensCol2={[["PT75", "75M"]]} />
                                        <OrderFormRow
                                            Title={""}
                                            col1={"Fiber Fuse"}
                                            col2={"Fiber Glass Tape"}
                                            lengthensCol1={[["FT-FF75", "76M"]]}
                                            lengthensCol2={[["EZY-90", "90M"]]} />


                                    </Col>

                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Bags" key="5" forceRender={true}>
                                    <Col span={24}>
                                        <OrderFormRow
                                            Title={"Bags"}
                                            col1={""}
                                            col2={""}
                                            lengthensCol1={[["BC9020", "Basecote 90 20kg"], ["BC6020", "Basecote 60 20kg"], ["BC4520", "Basecote 45 20kg"], ["MASONARY20", "Masonary Adh 20kg"]]}
                                            lengthensCol2={[["CAN4520", "Cornice 45 20kg"], ["CAN6020", "Cornice 60 20kg"], ["BBADHESIVE", "Back blocking 20kg"]]} />
                                    </Col>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Buckets" key="6" forceRender={true}>
                                    <Col span={20}>
                                        <OrderFormRow
                                            Title={"Buckets"}
                                            col1={""}
                                            col2={""}
                                            lengthensCol1={[["FCOTE20", "Finalcote 20kg"], ["LFINISH18", "Litefinish 18kg"], ["SRTL17", "Total Lite 17.5kg"], ["LCOTE18", "Allpurpose (l/cote) 18kg"]]}
                                            lengthensCol2={[["BKT-4T", "Hamiltons 4T BKT"], ["BORALSTUD", "Knauf stud adhesive 5.2kg"], ["RHINO GRIP", "Rhino Grip 5.2KG"]]} />
                                    </Col>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Internal Angle" key="7" forceRender={true}>
                                <OrderFormRow
                                    Title={"Internal Angle"}
                                    col1={"P40"}
                                    col2={"P18"}
                                    lengthensCol1={[["ROP4018", "1800"], ["ROP4024", "2400"]]}
                                    lengthensCol2={[["P1824", "2400"]]} />
                            </Tabs.TabPane>
                            {/*<Tabs.TabPane tab="Search Products" key="8">*/}
                            {/*    <MediaQuery minWidth={900}>*/}
                            {/*        <Form.Item label="Search Products" name="Search">*/}
                            {/*            <Input*/}
                            {/*                placeholder="SearchAble Text"*/}
                            {/*                onChange={(value)=>{*/}
                            {/*                    CatStore.SetSearchAbleText(value.target.value);*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        </Form.Item>*/}
                            {/*        <Table*/}
                            {/*            columns={DesktopColumns}*/}
                            {/*            pagination={{ position: ['topRight', 'bottomRight'] }}*/}
                            {/*            dataSource={toJS(CatStore.Filterdresults)}*/}
                            {/*            style={{*/}
                            {/*                borderRadius: '20px',*/}
                            {/*                padding: "5px",*/}
                            {/*                paddingLeft: '5%',*/}
                            {/*                paddingRight: '5%',*/}
                            {/*                backgroundColor: "lightslategray",*/}
                            {/*                boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.7)"*/}
                            {/*            }}*/}

                            {/*        />*/}
                            {/*    </MediaQuery>*/}
                            {/*    <MediaQuery maxWidth={901}>*/}
                            {/*        <Form.Item label="Search Products" name="Search">*/}
                            {/*            <Input*/}
                            {/*                placeholder="SearchAble Text"*/}
                            {/*                onChange={(value)=>{*/}
                            {/*                    CatStore.SetSearchAbleText(value.target.value);*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        </Form.Item>*/}
                            {/*        <Table*/}
                            {/*            columns={MobileColumns}*/}
                            {/*            pagination={{ position: ['topRight', 'bottomRight'] }}*/}
                            {/*            dataSource={toJS(CatStore.Filterdresults)}*/}
                            {/*            style={{*/}
                            {/*                borderRadius: '20px',*/}
                            {/*                padding: "5px",*/}
                            {/*                paddingLeft: '5%',*/}
                            {/*                paddingRight: '5%',*/}
                            {/*                backgroundColor: "lightslategray",*/}
                            {/*                boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.7)"*/}
                            {/*            }}*/}

                            {/*        />*/}
                            {/*    </MediaQuery>*/}
                            {/*    */}
                            {/*</Tabs.TabPane>*/}
                        </Tabs>
                        <Form.Item style={{marginTop: '5%'}}>
                            <Button className={"font_link"} type="primary" htmlType="submit" style={{padding: '1%', marginRight: '1%', width: ' 45%', textAlign: 'center', paddingBottom: '2%'}}>
                                Submit
                            </Button>
                            <Button className={"font_link"} htmlType="button" onClick={onFormClearClick} style={{padding: '1%', marginRight: '1%',  width: ' 45%', paddingBottom: '2%'}}>
                                Clear
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={900}>
                <div
                    style={{
                        minHeight: '100vh',
                        height: '100%'
                    }}
                >
                    <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        initialValues={{remember: true}}
                        onFinish={onFormFinish}
                        onFinishFailed={onFormFinishFailed}
                    >
                        <Collapse key="1" items={[
                            {   key:1,
                                label: "Plasterboard",
                                children: <div>
                                    <Collapse items={[
                                        {
                                            key: 1-1,
                                            label: 'OneBoard 10mm Wall',
                                            children: <OrderFormRow
                                                Title={"OneBoard 10mm Wall"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["O101360", "6000"], ["O101348", "4800"], ["O101342", "4200"], ["O101336", "3600"], ["O101330", "3000"], ['2700', '2700'], ['2400', '2400']]}
                                                lengthensCol2={[["O101260", "6000"], ["O101248", "4800"], ["O101242", "4200"], ["O101236", "3600"], ["O101230", "3000"], ['O101227', '2700'], ['O101224', '2400']]}
                                            />
                                        },
                                        {
                                            key: 1-2,
                                            label: 'OneBoard 10mm Ceiling',
                                            children: <OrderFormRow
                                                Title={"OneBoard 10mm Ceiling"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["O101360_Ceiling", "6000"], ["O101348_Ceiling", "4800"], ["O101342_Ceiling", "4200"], ["O101336_Ceiling", "3600"], ["O101330_Ceiling", "3000"], ['2700_Ceiling', '2700'], ['2400_Ceiling', '2400']]}
                                                lengthensCol2={[["O101260_Ceiling", "6000"], ["O101248_Ceiling", "4800"], ["O101242_Ceiling", "4200"], ["O101236_Ceiling", "3600"], ["O101230_Ceiling", "3000"], ['O101227_Ceiling', '2700'], ['O101224_Ceiling', '2400']]}
                                            />
                                        },
                                        {
                                            key: 1-3,
                                            label: '13mm Regular',
                                            children: <OrderFormRow
                                                Title={"13mm Regular"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["R131348", "4800"], ["R131336", "3600"], ["R131330", "3000"]]}
                                                lengthensCol2={[["R131260", "6000"], ["R131248", "4800"], ["R131236", "3600"], ["R131230", "3000"], ['R131227', '2700']]}
                                            />
                                        },
                                        {
                                            key: 1-4,
                                            label: '10mm Wet Area',
                                            children: <OrderFormRow
                                                Title={"10mm Wet Area"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["WR101354", "5400"], ["WR101336", "3600"]]}
                                                lengthensCol2={[["WR101242", "4200"], ["WR101236", "3600"], ["WR101230", "3000"], ['WR101224', '2400']]}
                                            />
                                        },
                                        {
                                            key: 1-5,
                                            label: "13mm SoundStop",
                                            children: <OrderFormRow
                                                Title={"13mm SoundStop"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["SS131336", "3600"]]}
                                                lengthensCol2={[["SS131236", "3600"]]}
                                            />
                                        },
                                        {
                                            key: 1-6,
                                            label: "10mm SoundStop",
                                            children:<OrderFormRow
                                                Title={"10mm SoundStop"}
                                                col1={"1350"}
                                                col2={"1200"}
                                                lengthensCol1={[["SS101348", "4800"]]}
                                                lengthensCol2={[["SS101248", "4800"], ["SS101236", "3600"]]}
                                            />
                                        },
                                        {
                                            key: 1-7,
                                            label: "1200 FireStop",
                                            children:  <OrderFormRow
                                                Title={"1200 FireStop"}
                                                col1={"13mm"}
                                                col2={"16mm"}
                                                lengthensCol1={[["F131236", "3600"], ["F131230", "3000"]]}
                                                lengthensCol2={[["F161236", "3600"], ["F161230", "3000"], ['F161224', '2400']]}
                                            />
                                        }
                                    ]}></Collapse>
                                </div>
                            },
                            {
                                key: 2,
                                label: "Fiber Cement",
                                children: <div>
                                    <Collapse
                                        items={[
                                            {
                                                key: 2-1,
                                                label: "Villa 6mm",
                                                children: <OrderFormRow
                                                    Title={"Villa 6mm"}
                                                    col1={"1350"}
                                                    col2={"1200"}
                                                    lengthensCol1={[["V61336", "3600"], ["V61330", "3000"], ["2700", "2700"], ["V61324", "2400"], ["1800", "1800"]]}
                                                    lengthensCol2={[["V61236", "3600"], ["V61230", "3000"], ["V61227", "2700"], ["V61224", "2400"], ["V61218", "1800"]]} />
                                            },
                                            {
                                                key: 2-2,
                                                label: "Villa 9mm",
                                                children: <OrderFormRow
                                                    Title={"Villa 9mm"}
                                                    col1={"1200"}
                                                    col2={""}
                                                    lengthensCol1={[["V91236", "3600"], ["V91230", "3000"], ["V91227", "2700"], ["V91224", "2400"], ["1800", "1800"]]}
                                                    lengthensCol2={[]} />
                                            }
                                        ]}
                                    ></Collapse>
                                </div>
                            },
                            {
                                key: 3,
                                label: 'Cornice',
                                children: <div>
                                    <OrderFormRow
                                        Title={"Decorative Cornice 4200"}
                                        col1={""}
                                        col2={""}
                                        lengthensCol1={[["SC9042", "Sydney 90mm"], ["NC9042", "New York 90mm"], ["MC7542", "Manly 75mm"], ["LC7542", "Linear 75mm"]]}
                                        lengthensCol2={[["C2ST42", "2 Step Cairo"], ["C3ST42", "3 Step Cairo"], ["C4ST42", "4 Step Cairo"]]} />
                                </div>
                            },
                            {
                                key: 4,
                                label: 'Accessories',
                                children: <div>
                                    <Collapse
                                        items={[
                                            {
                                                key: 4-1,
                                                label: "Accessories",
                                                children: <OrderFormRow
                                                    Title={"Accessories"}
                                                    col1={"External 90 (PO1)"}
                                                    col2={"External 135 (PO1A)"}
                                                    lengthensCol1={[["PO136", "3600"], ["EBE9030", "3000"], ["EBE9027", "2700"], ["EBE9024", "2400"]]}
                                                    lengthensCol2={[["PO1A36", "3600"], ["PO1A30", "3000"], ["PO1A27", "2700"], ["PO1A24", "2400"]]} />
                                            },
                                            {
                                                key: 4-2,
                                                label: "Accessories",
                                                children: <OrderFormRow
                                                    Title={"Accessories"}
                                                    col1={"Internal 90 (PS17)"}
                                                    col2={"Internal 135 (PS1A)"}
                                                    lengthensCol1={[["PS1736", "3600"], ["EBI9030", "3000"], ["EBI9027", "2700"], ["EBI9024", "2400"]]}
                                                    lengthensCol2={[["PSIA36", "3600"], ["PSIA30", "3000"], ["PSIA30", "2700"], ["PSIA27", "2400"]]} />
                                            },
                                            {
                                                key: 4-3,
                                                label: "Paper Tape",
                                                children: <OrderFormRow
                                                    Title={"Paper Tape"}
                                                    col1={""}
                                                    col2={""}
                                                    lengthensCol1={[["PT150", "150M"]]}
                                                    lengthensCol2={[["PT75", "75M"]]} />
                                            },
                                            {
                                                key: 4-4,
                                                label: "Accessories",
                                                children: <OrderFormRow
                                                    Title={""}
                                                    col1={"Fiber Fuse"}
                                                    col2={"Fiber Glass Tape"}
                                                    lengthensCol1={[["FT-FF75", "76M"]]}
                                                    lengthensCol2={[["EZY-90", "90M"]]} />
                                            }
                                        ]}
                                    ></Collapse>
                                </div>
                            },
                            {
                                key: 5,
                                label: 'Bags',
                                children: <div><OrderFormRow
                                    Title={"Bags"}
                                    col1={""}
                                    col2={""}
                                    lengthensCol1={[["BC9020", "Basecote 90 20kg"], ["BC6020", "Basecote 60 20kg"], ["BC4520", "Basecote 45 20kg"], ["MASONARY20", "Masonary Adh 20kg"]]}
                                    lengthensCol2={[["CAN4520", "Cornice 45 20kg"], ["CAN6020", "Cornice 60 20kg"], ["BBADHESIVE", "Back blocking 20kg"]]} />
                                </div>
                            },
                            {
                                key: 6,
                                label: 'Buckets',
                                children: <div><OrderFormRow
                                    Title={"Buckets"}
                                    col1={""}
                                    col2={""}
                                    lengthensCol1={[["FCOTE20", "Finalcote 20kg"], ["LFINISH18", "Litefinish 18kg"], ["SRTL17", "Total Lite 17.5kg"], ["LCOTE18", "Allpurpose (l/cote) 18kg"]]}
                                    lengthensCol2={[["BKT-4T", "Hamiltons 4T BKT"], ["BORALSTUD", "Knauf stud adhesive 5.2kg"], ["RHINO GRIP", "Rhino Grip 5.2KG"]]} /></div>
                            }
                        ]}>
                        </Collapse>
                        <Form.Item style={{marginTop: '5%'}}>
                            <Flex justify={"center"} align={"center"}>
                                <Button className={"font_link"} type="primary" htmlType="submit" style={{padding: '1%', marginRight: '1%', width: ' 45%', textAlign: 'center', paddingBottom: '2%'}}>
                                    Submit
                                </Button>
                                <Button className={"font_link"} htmlType="button" onClick={onFormClearClick} style={{padding: '1%', marginRight: '1%',  width: ' 45%', paddingBottom: '2%'}}>
                                    Clear
                                </Button>
                            </Flex>

                        </Form.Item>
                    </Form>
                </div>

            </MediaQuery>
        </Overlay>
    );
}

export default observer(OrderFormPage)