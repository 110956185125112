import Overlay from "../../componets/overlay/overlay";
import MediaQuery from "react-responsive";
import {Col, DatePicker, Divider, Form, Image, Input, message, Row, Spin, Tooltip} from "antd";
import React, {useState} from "react";
import {useForm} from "antd/es/form/Form";
import Button from "antd/es/button";
import Radio from "antd/es/radio";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import baseUrl from "../../stores/Utility";
import {Helmet} from "react-helmet";

export const SiteVisit = () => {
    const [form] = useForm();
    const [requestLoad, setRequestLoad] = useState(false)
    const [FormEnabled, setFormEnabled] = useState(true)
    const onFormFinish = (values: any) => {
        setRequestLoad(true)
        setFormEnabled(false)
        axios({
            method : "Post",
            url :  `${baseUrl()}/SiteVisit`,
            headers : {
                "Content-Type":"application/json",
            },
            data: values
        }).then(res => {
            message.success("Request Sent")
            onFormClearClick()
            setRequestLoad(false)
            setFormEnabled(true)
        }).catch(()=>{
            message.error("Please Retry")
            setRequestLoad(false)
            setFormEnabled(true)
        })
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    return (
        <div>
            <Helmet>
                <title>Site Visit</title>
                <meta name="description" content={"Book a site visit with Bayside Plasterboard"}/>
                <meta name="keywords" content="site visit, plastboard"/>
                <meta property="og:title" content="Site Visit"/>
                <meta property="og:description" content="Book a site visit with Bayside Plasterboard"/>
            </Helmet>
            <Overlay>
                <MediaQuery minWidth={901}>
                    <div className="Resource" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    >

                        <Row
                            className="mainback"
                            style={{
                                height: '100%',
                                marginLeft: '15%',
                                marginRight: '5%'
                            }}
                        >
                            <Col
                                span={8}
                            >
                                <div>
                                    <h1 className={"font_link"}> Book a Site Visit </h1>
                                    <p className={"articleMainBody"}>Please complete the below form to enquire about booking a site visit and our team will respond to you as soon as possible.  </p>
                                    <p className={"articleMainBody"}>Our offices are open Monday to Friday, 5:30am until 4:00pm, except Public Holidays. </p>
                                    <Form
                                        form={form}
                                        disabled={!FormEnabled}
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        layout="horizontal"
                                        initialValues={{remember: true}}
                                        onFinish={onFormFinish}
                                        onFinishFailed={onFormFinishFailed}
                                    >
                                        <Form.Item label="Contact Name" name="ContactName" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Number" name="ContactNumber" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Email Address" name="ContactEmail" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Company" name="Company" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Job Title" name="JobName">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Address" name="Address" rules={[{ required: true, message: 'Please input!' }]} >
                                            <Input/>
                                        </Form.Item>
                                        {/*<Form.Item label="Prefered Date" name="RequestedDate" rules={[{ required: true, message: 'Please Select a date for visit' }]} getValueProps={(i) => ({ value: (i) })}>*/}
                                        {/*    <Tooltip title={"Please select a preferred date for site visit"}><DatePicker/> </Tooltip>*/}
                                        {/*</Form.Item>*/}
                                        <Form.Item label="Notes" name="Notes">
                                            <TextArea rows={4} placeholder={"Example: Training of new Hardie's Brushed Concrete, 6 people on site "}/>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{offset: 8, span: 20}}>
                                            <Button type="primary" htmlType="submit" style={{padding: '5px'}} className={"font_link"}>
                                                Submit
                                            </Button>
                                            <Divider type={"vertical"} />
                                            <Button htmlType="button" onClick={onFormClearClick} style={{padding: '5px'}} className={"font_link"}>
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <Spin spinning={requestLoad} size={'large'} />
                                </div>
                            </Col>
                            <Col
                                span={8}
                                style={{
                                    paddingTop: "5%",
                                    paddingLeft: '10%'
                                }}
                            >
                                <Image src={"../../assets/images/SiteVisit.jpg"} width={"150%"}/>
                            </Col>
                        </Row>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={900}>
                    <div className="Resource" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                    }}
                    >
                        <Row
                            className="mainback"
                            style={{
                                height: '100%',
                                marginLeft: '15%',
                                marginRight: '15%'
                            }}
                        >
                            <Col
                                span={24}
                            >
                                <div>
                                    <h1> Book a Site Visit </h1>
                                    <p className={"articleMainBody"}>Please complete the below form to enquire about booking a site visit and our team will respond to you as soon as possible.  </p>
                                    <p className={"articleMainBody"}>Our offices are open Monday to Friday, 6:00am until
                                        4:00pm, except Public Holidays. </p>
                                    <Form
                                        form={form}
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                        layout="horizontal"
                                        disabled={!FormEnabled}
                                        initialValues={{remember: true}}
                                        onFinish={onFormFinish}
                                        onFinishFailed={onFormFinishFailed}
                                    >
                                        <Form.Item label="Contact Name" name="ContactName" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Contact Number" name="ContactNumber" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Email Address" name="ContactEmail" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Company" name="Company" rules={[{ required: true, message: 'Please input!' }]}>
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Job Title" name="JobName">
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Address" name="Address" rules={[{ required: true, message: 'Please input!' }]} >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item label="Prefered Date" name="RequestedDate" rules={[{ required: true, message: 'Please Select a date for visit' }]}>
                                            <Tooltip title={"Please select a preferred date for site visit"}><DatePicker/> </Tooltip>
                                        </Form.Item>
                                        <Form.Item label="Notes" name="Notes">
                                            <TextArea rows={4}/>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{offset: 8, span: 20}}>
                                            <Button type="primary" htmlType="submit" style={{padding: '5px'}} className={"font_link"}>
                                                Submit
                                            </Button>
                                            <Divider type={"vertical"} />
                                            <Button htmlType="button" onClick={onFormClearClick} style={{padding: '5px'}} className={"font_link"}>
                                                Clear
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                    <Spin spinning={requestLoad} size={'large'} />
                                </div>
                            </Col>
                            <Col
                                span={24}
                                style={{
                                    paddingTop: "5%",
                                    paddingBottom: '10%'
                                }}
                            >
                                <Image src={"../../assets/images/SiteVisit.jpg"} width={"100%"}/>
                            </Col>
                        </Row>
                    </div>
                </MediaQuery>
            </Overlay>
        </div>
    );
};
