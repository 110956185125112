import React, { useContext, useEffect, useState } from "react";
import Overlay from "../../componets/overlay/overlay";
import {Button, Col, Image, Input, InputNumber, Row, Space, Table, Tabs, Tag, Tooltip} from "antd";
import {
    ClockCircleOutlined,
    LaptopOutlined,
    MobileOutlined,
    PhoneOutlined,
    ShoppingCartOutlined,
} from "@ant-design/icons";
import MediaQuery from "react-responsive";
import {ColumnsType} from "antd/es/table";
import {PlusCircle, PlusCircleFill} from "react-bootstrap-icons";
import CatalogueStore from "../../stores/catalogue.store";
import CartStore, {Product} from "../../stores/Cart.store";
import InspirationStore from "../../stores/Inspiration.store";
import {toJS} from "mobx";
import {observer} from "mobx-react-lite";
import {it} from "node:test";

const InsperationPage: React.FC = () => {
    const CatStore = useContext(CatalogueStore);
    const UserCartStore = useContext(CartStore);
    const store = useContext(InspirationStore)

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'Name',
        },
        {
            title: 'Add To Cart',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record: any, index: number) => {
                return (
                    <div>
                        <InputNumber min={0} defaultValue={text}
                                     style={{
                                         marginRight: '0.5vw'
                                     }}
                                     onChange={value => {
                                         store.modifyQuantity(record, value);
                                     }}
                        >
                        </InputNumber>
                        <Button icon={<PlusCircleFill />}
                                onClick={() => {
                                    const itemDetails = store.getItemDetails(record.Id);
                                    if (itemDetails === null) return
                                    UserCartStore.AddToCart({...itemDetails, Quantity: itemDetails.quantity});
                                }}
                        ></Button>
                    </div>
                )
            }
        },
    ]


    return (

        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div className="Insperation" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize:     "cover",
                        backgroundRepeat:   "no-repeat",
                        height: '100%'
                    }}
                    >
                        <Row
                            style={{
                                height: '100%',
                                paddingBottom: '2%'
                            }}
                        >
                        <div
                            style={{
                                backgroundColor: "rgba(82, 171, 210, 0.5)",
                                justifyContent: 'center',
                                paddingLeft: '10vw',
                                zIndex: '1',
                                paddingRight: '10vw'
                            }}
                        >
                            <h3>Very flash ceiling system</h3>
                            <p>orem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                        <Row
                            style={{
                                marginTop: '2%',
                                marginLeft: '5%',
                                width: '90%'
                            }}
                        >
                            <Col span={17}
                                style={{
                                    backgroundColor: "rgba(82, 171, 210, 0.5)",
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    borderRadius: '20px',
                                    padding: '2vw',
                                    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                }}
                            >
                                <Row
                                    style={{height: '50%'}}
                                >
                                    <Col span={12}>
                                        <Image
                                            src="../../assets/images/ceiling.jpg"
                                        />
                                    </Col>
                                    <Col span={1} />
                                    <Col span={11}>
                                        <Image
                                            src="../../assets/images/ceiling.jpg"
                                        />
                                    </Col>
                                </Row>
                                <Row
                                    style={{width: '70%'}}
                                >
                                    <Image
                                        src="../../assets/images/ceiling.jpg"
                                    />
                                </Row>
                            </Col>
                            <col span={1} style={{width: "2%"}}/>
                            <Col span={6}
                                 style={{
                                     height: '100%',
                                     background: 'yellow',
                                     borderRadius: '20px',
                                     boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                     backgroundColor: "rgba(50, 148, 191, 0.7)",
                                     padding: '1vw'

                                 }}
                            >
                                <Table columns={columns} dataSource={toJS(store.ItemsforOrder)} footer={() => {
                                    return (
                                        <div>
                                            <Tooltip title={"Add Items To Cart"}>
                                                <Button
                                                    type="primary"
                                                    style={{width: '50%', height: '10%', background: '#364d79', alignContent: 'right'}}
                                                    onClick={() => {
                                                        store.ItemsforOrder.forEach(itemDetails => {
                                                            if (itemDetails === null) return
                                                            UserCartStore.AddToCart({
                                                                ...itemDetails,
                                                                Quantity: itemDetails.quantity
                                                            });
                                                        })
                                                    }
                                                    }
                                                    icon={<ShoppingCartOutlined style={{fontSize: '3em'}}/>}
                                                    size={"large"}
                                                ></Button>
                                            </Tooltip>
                                        </div>
                                    )
                                }}/>

                            </Col>
                        </Row>
                        </Row>
                        <Row>
                            <p>Previous Inspirations</p>
                        </Row>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <p>mobile</p>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default observer(InsperationPage);