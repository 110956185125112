import React, { useContext, useEffect, useState } from "react";
import { OverlayViewF } from "@react-google-maps/api";
// import { MapContainer, TileLayer, InfoWindow, Marker} from 'react-leaflet'
import Overlay from "../../componets/overlay/overlay";
import { Button, Col, Input, Row, Space } from "antd";
import { ClockCircleOutlined, LaptopOutlined, MobileOutlined, PhoneOutlined, } from "@ant-design/icons";
import MediaQuery from "react-responsive";
import MarketingComp from "../../componets/marketing/marketing";

import { EnvelopeAtFill, TelephoneFill, GeoAltFill, ClockFill} from "react-bootstrap-icons";

import "./home.css";
import SupplierArea from "../../componets/supplierIconAra/supplierArea";
import GenQuestions from "../../componets/generalQuestions";
import StoreLocation from "../../componets/storeLocation";
import StoreLocationMobile from "../../componets/storeLocation/mobile";
import {Helmet} from "react-helmet";

const Home: React.FC = () => {
    const [located, setLocated] = useState(false);
    const [map, setMap] = useState(undefined);
    const [userLocation, setUserLocation] = useState(null);
    const [directions, setDirections] = useState(null);
    const BAYSIDE_BRISBANE = { lat: -27.51167377, lng: 153.18080891 };
    const BAYSIDE_COAST = { lat: -28.03447756, lng: 153.35298353 };
    const BAYSIDE_JSON = {
        Title: "Bayside Plasterboard",
        phoneNumber: "07 3245 4488",
        email: "something@something.com",
        address: "2/1424 New Cleveland Rd, Chandler QLD 4155",
        operatingHours: "Mon-Fri: 6:00am - 4:30pm",
        imgurl: "../../assets/icons/Bayside_Cut.png",
        latlng: BAYSIDE_BRISBANE,
    };

    const COAST_JSON = {
        Title: "Gold Coast Plasterboard",
        phoneNumber: "07 5522 8666",
        email: "soemthing@something.com",
        address: "106 Eastlake St, Carrara QLD 4211",
        operatingHours: "Mon-Fri: 6:00am - 4:30pm",
        imgurl: "../../assets/icons/CoastSide_cut.png",
        latlng: BAYSIDE_COAST,
    };

    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    const google = window.google
    function formatJsonToInfoWindow(json: any) {

        //TODO: icons for details
        return (
            <div
                style={{
                    padding:  '1vh',
                }}
            >
                {/* <h2>{json.Title}</h2> */}
                <img src={json.imgurl} alt="Bayside" />
                <p><TelephoneFill color="#fa8c16" width={15} height={15} style={{marginRight: "0.5vw"}}/>{json.phoneNumber}</p>
                <p><EnvelopeAtFill color="#fa8c16" width={15} height={15} style={{marginRight: "0.5vw"}}/>{json.email}</p>
                <p><GeoAltFill color="#fa8c16" width={15} height={15} style={{marginRight: "0.5vw"}}/>{json.address}</p>
                <p><ClockFill color="#fa8c16" width={15} height={15} style={{marginRight: "0.5vw"}}/>{json.operatingHours}</p>
            </div>
        );
    }

    const containerStyle = {height: "60vh", width: "100%"};
    const center = {
        lat: -27.470125,
        lng: 153.021072,
    }
    useEffect(() => {
        if (userLocation !== null) {
            setTimeout(waitForGeometryLibraries, 250);
        }
    }, [userLocation]);

    const baysideIcon = {
        Url: '../../assets/icons/Bayside_Cut.png',
        //scaledSize: new google.maps.Size(100, 75),
        //iconAnchor: [22, 94],
    };

    const coastIcon = {
        Url: '../../assets/icons/CoastSide_cut.png',
        // scaledSize: new google.maps.Size(100, 75),
        //iconAnchor: [22, 94],
    };

    function waitForGeometryLibraries() {
        if (typeof google.maps.geometry !== "undefined") {
        }
        else {
            setTimeout(waitForGeometryLibraries, 250);
        }
    }
    return (

        <div>
            <Helmet>
                <title>Bayside Plasterboard</title>
                <meta name="description" content={"Bayside Plasterboard"} />
                <meta name="keywords" content="plasterboard, Bayside, home" />
                <meta property="og:title" content="Bayside Plasterboard" />
                <meta property="og:description" content="Bayside Plasterboard" />
            </Helmet>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div className="homepagemaindiv"
                        style={{
                            textAlign: "center"
                        }}
                    >
                        {/*<img className="BackGroundPhoto" alt='error' src="../../assets/images/baysidephoto.jpg" style={{zIndex: 0, height: "100vw", width: "100vw"}}/>*/}
                        {/*<h3>Product Ranges</h3>*/}
                        <div
                            className="productRangeDiv"
                            style={{
                                zIndex: '1',
                                alignContent: 'center',
                               //  backgroundImage: "url(../../assets/images/baysidephoto.jpg)",
                                backgroundSize:     "cover",
                                backgroundRepeat:   "no-repeat",
                                boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)',
                                width: '100%',
                            }}
                        >
                            <br/>
                            <div
                                className="marketingDiv"
                                style={{
                                    alignItems: "Center",
                                    paddingLeft: "5%",
                                    paddingBottom: '2%'
                                }}
                            >
                                <Row
                                    style={{
                                        width: "90%",
                                    }}
                                >
                                    <MarketingComp />
                                </Row>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundImage: "url(../../assets/images/waves.jpg)",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                paddingLeft: "7vw",
                                paddingBottom: '2%'
                            }}
                        >
                            <br/>
                            <StoreLocation
                                StoreTitle="Brisbane"
                                phonenumber="07 3245 4488"
                                address="367 Thynne Rd, Morningside QLD 4170"
                                addressUrl="https://maps.app.goo.gl/g84yYyaKK4zTEUfC9"
                                inverse={false}
                                photo="../../assets/icons/BaysideLogo.png"
                                emailAddress="admin@bspb.com.au"
                            />
                            <br/>
                            <StoreLocation
                                StoreTitle="RedLands"
                                phonenumber="07 3245 4488"
                                address="2/1424 New Cleveland Rd, Chandler QLD 4155"
                                inverse={true}
                                addressUrl="https://maps.app.goo.gl/Qx6rauzQBuxetsWw8"
                                photo="../../assets/images/capalabaStorePhoto.jpg"
                                emailAddress="redlands@bspb.com.au"
                            />
                            <br/>
                            <StoreLocation
                                StoreTitle="Gold Coast"
                                phonenumber="07 5522 8666"
                                address="106 Eastlake St, Carrara QLD 4211"
                                inverse={false}
                                addressUrl="https://maps.app.goo.gl/VS1jh2bcqivpb3Ax7"
                                photo="../../assets/icons/BaysideLogo.png"
                                emailAddress="gc@bspb.com.au"
                            />
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <Row>
                        <div style={{width: '100%', padding: '3%'}}>
                            <MarketingComp />
                        </div>
                        <div
                            style={{
                                backgroundImage: "url(../../assets/images/waves.jpg)",
                                width: "100%",
                                height: '100%',
                                justifyContent: "center",
                                alignItems: "center",
                                paddingLeft: "7vw",
                                paddingBottom: '2%'
                            }}
                        >
                            <br/>
                            <StoreLocationMobile
                                StoreTitle="Brisbane"
                                phonenumber="07 3245 4488"
                                address="367 Thynne Rd, Morningside QLD 4170"
                                addressUrl="https://maps.app.goo.gl/g84yYyaKK4zTEUfC9"
                                inverse={false}
                                photo="../../assets/icons/BaysideLogo.png"
                                emailAddress="admin@bspb.com.au"
                            />
                            <br/>
                            <StoreLocationMobile
                                StoreTitle="Redlands"
                                phonenumber="07 3245 4488"
                                address="2/1424 New Cleveland Rd, Chandler QLD 4155"
                                inverse={true}
                                addressUrl="https://maps.app.goo.gl/Qx6rauzQBuxetsWw8"
                                photo="../../assets/images/capalabaStorePhoto.jpg"
                                emailAddress="redlands@bspb.com.au"
                            />
                            <br/>
                            <StoreLocationMobile
                                StoreTitle="Gold Coast"
                                phonenumber="07 5522 8666"
                                address="106 Eastlake St, Carrara QLD 4211"
                                inverse={false}
                                addressUrl="https://maps.app.goo.gl/VS1jh2bcqivpb3Ax7"
                                photo="../../assets/icons/BaysideLogo.png"
                                emailAddress="gc@bspb.com.au"
                            />
                        </div>
                    </Row>
                </MediaQuery>
            </Overlay>
        </div>
    );
}

export default Home;