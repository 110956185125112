import Overlay from "../../componets/overlay/overlay";
import {Col, Flex, Form, Input, List, message, Row, Space, Spin, Upload, UploadProps} from "antd";
import TextArea from "antd/es/input/TextArea";
import Button from "antd/es/button";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "antd/es/form/Form";
import axios from "axios";
import baseUrl from "../../stores/Utility";
import UserStore from "../../stores/user.store";
import UsersStore from "../../stores/Users.store";
import OrdersStore from "../../stores/Orders.store";
import resourceStore from "../../stores/resource.store";
import {InboxOutlined} from "@ant-design/icons";
import {observer} from "mobx-react-lite";

const { Dragger } = Upload;

function ArticleCreate() {
    const user = useContext(UserStore)
    const adminUsersStore = useContext(UsersStore);
    const orderStore = useContext(OrdersStore);
    const store = useContext(resourceStore)

    const [DisplayText, setDisplayText] = useState("")
    const [requestLoad, setRequestLoad] = useState(false)
    const [FormEnabled, setFormEnabled] = useState(true)

    useEffect(()=> {
        const token = user.Token;
        adminUsersStore.getUsers(token);
        orderStore.getOrders(token);
        orderStore.getGuestOrders(token);
        if (store.editArticle !== null) {
            setDisplayText(store.editArticle.Article)
        }
    },[])
    const [form] = useForm();

    const onFormFinish = (values: any) => {
        setRequestLoad(true)
        setFormEnabled(false)

        if (store.editArticle !== null) {
            axios({
                method : "Post",
                url :  `${baseUrl()}/EditResource/${store.editArticle.Id}`,
                headers:  {
                    "Content-Type":"application/json",
                    Authorization: user.Token ? `${user.Token}` : "",
                },
                data: values
            }).then(res => {
                message.success("Request Sent")
                onFormClearClick()
                setRequestLoad(false)
                setFormEnabled(true)
            }).catch(()=>{
                message.error("Please Retry")
                setRequestLoad(false)
                setFormEnabled(true)
            })
        } else {
            console.log(values)
            axios({
                method : "Post",
                url :  `${baseUrl()}/AddResource`,
                headers:  {
                    "Content-Type":"application/json",
                    Authorization: user.Token ? `${user.Token}` : "",
                },
                data: values
            }).then(res => {
                message.success("Request Sent")
                onFormClearClick()
                setRequestLoad(false)
                setFormEnabled(true)
            }).catch(()=>{
                message.error("Please Retry")
                setRequestLoad(false)
                setFormEnabled(true)
            })
        }
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const props: UploadProps = {
        name: 'file',
        multiple: true,
        action: `${baseUrl()}/baysideImages`,
        headers:  {
            "Content-Type":"application/json",
            Authorization: user.Token ? `${user.Token}` : "",
        },
        accept: '.jpg',
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    const onFormClearClick = () => {
        form.resetFields();
        store.ClearEditArticle()
        setDisplayText("")
    };
    return (
        <div>
            <Overlay>
                <div
                    style={{
                        padding: '5%',
                        minHeight: '70vh',
                        height: '100%'
                    }}
                >
                    <Form
                        form={form}
                        name="basic"
                        labelCol={{span: 3}}
                        wrapperCol={{span: 14}}
                        layout="horizontal"
                        initialValues={store.LoadEditArticle}
                        onFinish={onFormFinish}
                        disabled={!FormEnabled}
                        onFinishFailed={onFormFinishFailed}
                    >
                        <Row>
                            <Col span={12}>
                                <Form.Item label="Title" name="Title">
                                    <Input/>
                                </Form.Item>
                                <Form.Item label="SubTitle" name="SubTitle">
                                    <Input/>
                                </Form.Item>
                                <p>meta Tag format (coma divided): tag, tag, tag</p>
                                <Form.Item label="Meta Tags" name="Tags">
                                    <Input/>
                                </Form.Item>

                                <Form.Item label="Article" name="Article">
                                    <TextArea rows={10} onChange={(e) => {
                                        console.log(e.target.value || "");
                                        setDisplayText(e.target.value)
                                    }}/>
                                </Form.Item>



                                <Flex vertical={true} style={{width: '20%'}}>
                                    <Button
                                        onClick={() => {
                                            const text = DisplayText + "\n" + "<h3>sample text </h3>"
                                            setDisplayText(text);
                                            form.setFieldValue("Article", text)
                                        }}
                                    >Add sub heading</Button>
                                    <Button
                                        onClick={() => {
                                            const text = DisplayText + "\n" + "<h5>sample text </h5>"
                                            setDisplayText(text);
                                            form.setFieldValue("Article", text)
                                        }}
                                    >Add sub sub heading</Button>
                                    <Button
                                        onClick={() => {
                                            const text = DisplayText + "\n" + "<point>point text</point>"
                                            setDisplayText(text);
                                            form.setFieldValue("Article", text)
                                        }}
                                    >Add Point
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            const text = DisplayText + "\n" + "<a> WebsiteLink, link text</a>"
                                            setDisplayText(text);
                                            form.setFieldValue("Article", text)
                                        }}
                                    >Add HyperLink
                                    </Button>
                                </Flex>
                            </Col>
                            <Col span={12} style={{ padding: '2vw',
                                boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',}}>
                                {store.formatForDisplay(DisplayText)}
                            </Col>
                        </Row>

                        <Dragger {...props} style={{height: '10vh', width: '20%'}}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Only .jpg is supported
                            </p>
                        </Dragger>
                        <Spin spinning={requestLoad} size={'large'} />

                        <Form.Item wrapperCol={{offset: 8, span: 16}}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button htmlType="button" onClick={onFormClearClick}>
                                Clear
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Overlay>
        </div>
    );
}

export default observer(ArticleCreate)