import Overlay from "../../componets/overlay/overlay";
import {LoginModal} from "../../componets/LoginModel/LoginModal";
import React, {useContext, useState} from "react";
import {LoginOutlined} from "@ant-design/icons";
import UserStore from "../../stores/user.store";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import UsersStore from "../../stores/Users.store";
import OrdersStore from "../../stores/Orders.store";

function AdminlogIn() {
    const [LoginedModal, setLoginModal] = useState(false)
    const userStore = useContext(UserStore);
    const adminUsersStore = useContext(UsersStore);
    const orderStore = useContext(OrdersStore)
    return (
        <div>
            <Overlay>
                <div
                    style={{
                        padding: '5%',
                        minHeight: '70vh',
                        height: '100%'
                    }}
                >
                    { LoginedModal &&
                        <LoginModal
                            cancelstate={()=>{
                                setLoginModal(false)
                            }}
                            okfunction={()=>{
                                const token = userStore.Token;
                                console.log(token)
                                adminUsersStore.getUsers(token);
                                orderStore.getOrders(token);
                                orderStore.getGuestOrders(token);
                            }}
                        />
                    }
                    <p>Log in:
                        <LoginOutlined
                            onClick={() => {
                                setLoginModal(!LoginedModal)
                            }}
                            style={{
                                fontSize: '200%',
                                cursor: "pointer",
                            }}
                        />
                    </p>

                    {userStore.IsUserLoggedIn() && (
                        <div>
                            <Link to={"/ArticleCreate"} >Create Article</Link>
                        </div>
                    )}
                </div>
            </Overlay>
        </div>
    );
}

export default observer(AdminlogIn)