import {useForm} from "antd/es/form/Form";
import {Form, Input, message, Space, Checkbox, Tooltip, Collapse, Divider} from "antd";
import Button from "antd/es/button";
import Overlay from "../../componets/overlay/overlay";
import Radio from "antd/es/radio";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import axios from "axios";
import baseUrl from "../../stores/Utility";

export function CustomerEnquiry() {
    const [form] = useForm();

    const onFormFinish = (values: any) => {
        // todo handle form finish
        console.log(values)
        const copy = {...values}
        copy.Checkbox = JSON.stringify(copy.Checkbox)
        axios({
            method : "POST",
            url :  `${baseUrl()}/CustomerInquiry`,
            headers : {
                "Content-Type":"application/json",
            },
            data: copy
        }).then(res => {
            message.success("Request Sent")
            console.log(res)
        }).catch((err)=>{
            message.error("Please Retry")
            console.log(err)
        })
        onFormClearClick()
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    const options = [
        {label: "Plasterboard – Knauf", value: 1, text: 'Permarock\n' +
                'Statopanel\n' +
                'Water Resistant Plasterboard\n' +
                'Systems + How to use, where to find\n' +
                'Value Engineering\n' +
                'Partiwall Estimator\n' +
                'Eselector + Selecting Fire-rated and acoustic systems for your project\n' +
                'Understanding Seismic Design'},
        {label: "Fibre Cement – James Harie", value: 2, text: 'Working with Fibre Cement\n' +
                'Modern Styles with James Hardie\n' +
                'Renovating with James Hardie\n' +
                'New Homes with James Hardie\n' +
                'Hardie Architectural Joints and Junctions\n' +
                'RAB Board\n' +
                'Working Safely with James Hardie Products\n' +
                'James Hardie Technical Tips\n' +
                'Unlocking Modern Textures – Hardie Brushed Concrete\n' +
                'Achieving the Modern Vertical Look – Hardie Oblique Cladding\n' +
                'Achieving a 7-star energy Rating on a New Home with Exterior Cladding\n' +
                'Exterior Cladding Tips for a Dream Duplex Build\n' +
                'Take Off Training'},
        {label: "Metal – Rondo", value: 3, text: 'Concealed Ceilings\n' +
                'Exposed Grid Ceilings\n' +
                'Internal Walls\n' +
                'External Walls\n' +
                'Access Panels + E.g. Standard, fire rated, custom tiled access panels\n' +
                'Load Training + Wind and Seismic loads and how they are calculated. Dives into how Rondo design to resist forces\n' +
                'C2 Fasteners Training + Compliance and the use of C1 and C2 fasteners\n' +
                'Information required for a design\n' +
                'Estimators &amp; Calculators\n' +
                'Seismic Design Training\n' +
                'Ceiling Design\n' +
                'Wall Design\n' +
                'Rondo R-Series online training guides\n' +
                'Tall Building Solutions\n' +
                'Complex Design Solutions\n' +
                'Whitepapers + In-depth Educational Papers, Outlining Specific Industry Issues'},
        {label: "Insulation – Fletchers Insulation", value: 4, text: 'Residential Product Selector\n' +
                'Commercial Product Selector\n' +
                'FletcherSpec Pro\n' +
                'Noise Solutions\n' +
                'Thermal R Ratings Explained\n' +
                'Calculators + Noise levels, dew point and humidity, heat flow and thickness required'},
        {label: "Fixings and Fasteners – Iccons", value: 5, text: 'Seismic Solutions\n' +
                'Gas Tool Options and Solutions\n' +
                'Adhesive Options and Training\n' +
                'Anchoring Basics\n' +
                'Technical Solutions and Training\n' +
                'How to Troubleshoot Chemselect Adhesive Achor Issue'},
        {label: "Tools – Wallboard Tools", value: 6, text: 'Tapping Tools\n' +
                'Skimming blades\n' +
                'TrimTex Finishing Beads\n' +
                'Dust Extractor Classes'},
        {label: "Non-Combustible Acoustics Insulation Panels – Stonewall Platinum", value: 7, text: 'Medigrid + Non-Combustible Suspended Ceiling Tile\n' +
                'TerraWool + Non-Combustible Soffit Panels\n' +
                'TerraWeave + Natural Timber Acoustic Panels\n' +
                'Aspect Vivid + Non-Combustible Soffit Tiles\n' +
                'Non-Combustible Compliance Training &amp; Solutions'},
        {label: "Marketing – Content Hive ", value: 8, text: 'Social Media Tips\n' +
                'Marketing Service\n' +
                'Do’s and Don’ts of Marketing\n' +
                'How to DIY Marketing'},
        {label: "Signs & Vehicle Wraps – Tarro Signs ", value: 9, text: 'Signs for your business\n' +
                'Vehicle signage and wraps\n' +
                ' Logo Design'},
        {label: "Finance Brokerage – Moreton Partners", value: 10, text: 'Financial Brokerage Services'},
        {label: "Debtors Insurance – Prasidium ", value: 11, text: 'Insuring Your Debts with Builders\n' +
                ' How to Protect Yourself From Bad Payers'},

    ];

    return (
        <div>
            <Overlay >
                <div>
                    <h1 className={"font_link"} style={{marginLeft: '5%'}}>Lunch And Learn</h1>
                    <h2 className={"font_link"} style={{marginLeft: '5%'}}>With Bayside Plasterboard</h2>
                    <img src={"../../assets/images/LunchLearn.jpg"} style={{width: '100%'}}/>
                </div>
                <div
                    style={{
                        minHeight: '60vh',
                        height: '100%',
                        padding: '3%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p className={"articleMainBody"} style={{fontSize: "120%", justifySelf: 'left'}}>In 2024 Bayside Plasterboard is excited to be able to offer training sessions to our clients at our new location in Morningside .</p>
                    <p className={"articleMainBody"} style={{fontSize: "120%", justifySelf: 'left'}}>These will be hosted by our network of suppliers based on the topics that you want to learn more about. We'll even put lunch on too!  </p>
                    <p className={"articleMainBody"} style={{fontSize: "120%", justifySelf: 'left'}}>To be notified of upcoming Lunch & Learn sessions please complete the form below. </p>
                    <p className={"articleMainBody"} style={{fontSize: "120%", justifySelf: 'left'}}> Please select the topics that you are interest in (you can select multiple)</p>
                    <Form
                        form={form}
                        name="LunchAndLearn"
                        layout="horizontal"
                        labelCol={{span: 5}}
                        wrapperCol={{span: 20}}
                        initialValues={{remember: true}}
                        onFinish={onFormFinish}
                        onFinishFailed={onFormFinishFailed}
                    >


                        <Form.Item label="Topics" name="Checkbox" valuePropName="checked">
                                <Checkbox.Group>
                                    <Space direction={"vertical"}>
                                    {options.map(item => {
                                        return (
                                            <div>
                                                <Collapse
                                                    items={[{
                                                        key: '1',
                                                        label:
                                                            <p className={"font_link"}>{item.label}</p>,
                                                            // <Checkbox value={item.value} className={"font_link"}>
                                                            //     {item.label}
                                                            // </Checkbox>,
                                                        children:
                                                            <div>
                                                                <Space direction={"vertical"}>
                                                                {item.text.split('\n').map((str: string, index) =>(
                                                                    <Checkbox value={item.value + "_" + index} >
                                                                        <p className={"articleMainBody"} style={{fontSize: '110%'}}>
                                                                            {str.split("+")[0]}
                                                                        </p>
                                                                        {str.split("+")[1] && <p>{str.split("+")[1]}</p>}
                                                                    </Checkbox>

                                                                ))}
                                                                </Space>
                                                            </div>

                                                    }]}
                                                ></Collapse>
                                            </div>
                                        )
                                    })}
                                    </Space>
                                </Checkbox.Group>
                        </Form.Item>
                        <p className={"articleMainBody"} style={{fontSize: "130%"}}>Are there any topics that we havent covered that you would like to learn more about?</p>
                        <Form.Item label="Other Questions" name="Textarea">
                            <TextArea rows={4}/>
                        </Form.Item>

                        <Form.Item
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            label="First Name"
                            name="FirstName"
                        >
                            <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: true, message: 'Please input your Company!' }]}
                            label="Company"
                            name="Company"
                        >
                            <Input placeholder="Company" />
                        </Form.Item>
                        <Form.Item
                            rules={[{ required: true, message: 'Please input your Email!' }]}
                            label="Email"
                            name="Email"
                        >
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item
                            label="Phone"
                            name="Phone"
                        >
                            <Input placeholder="Phone" />
                        </Form.Item>

                        <Form.Item wrapperCol={{offset: 8, span: 20}}>
                            <Button type="primary" htmlType="submit" style={{padding: '5px'}}
                                    className={"font_link"}>
                                Submit
                            </Button>
                            <Divider type={"vertical"}/>
                            <Button htmlType="button" onClick={onFormClearClick}
                                    style={{padding: '5px'}} className={"font_link"}>
                                Clear
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Overlay>
        </div>
    );
}
