import React, { useContext, useEffect, useState } from "react";
import { OverlayViewF } from "@react-google-maps/api";
// import { MapContainer, TileLayer, InfoWindow, Marker} from 'react-leaflet'
import Overlay from "../../componets/overlay/overlay";
import {Button, Col, Collapse, Input, Row, Space, Tabs} from "antd";
import type { TabsProps } from 'antd';
import { ClockCircleOutlined, LaptopOutlined, MobileOutlined, PhoneOutlined, } from "@ant-design/icons";
import MediaQuery from "react-responsive";
import MarketingComp from "../../componets/marketing/marketing";

import { EnvelopeAtFill, TelephoneFill, GeoAltFill, ClockFill} from "react-bootstrap-icons";

import "./about.css";
import AboutUs from "../../componets/aboutus";
import StaffMember from "../../componets/staffMember";
import {Helmet} from "react-helmet";

const items: TabsProps['items'] = [
    {
      key: '1',
      label: `About Us`,
      children: <AboutUs />,
    },
    {
      key: '2',
      label: `Tab 2`,
      children: `Content of Tab Pane 2`,
    },
    {
      key: '3',
      label: `Tab 3`,
      children: `Content of Tab Pane 3`,
    },
  ];

const About: React.FC = () => {
    return (
        <div
        >
            <Helmet>
                <title>About Us</title>
                <meta name="description" content={"About Bayside PlasterBoard"} />
                <meta name="keywords" content="plasterboard, Bayside, about us" />
                <meta property="og:title" content="About Us" />
                <meta property="og:description" content="About Bayside PlasterBoard" />
            </Helmet>
            <Overlay>

                    <div className="aboutPageMain" style={{
                        //backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize:     "cover",
                        backgroundRepeat:   "no-repeat",
                        height: '100%'
                    }}>
                        {/*<h1 className="aboutPageTitle">About Us</h1>*/}
                        {/*<Tabs defaultActiveKey="1" items={items}/>*/}
                        <Row
                            className="BaysideHistory"
                            style={{
                                height: "120%",
                                width: "100%",
                            }}
                        >
                            <div
                                style={{
                                    height: "25%",
                                    width: "90%",
                                    marginTop: '1vw',
                                    borderRadius: "20px",
                                    background: 'mintcream',
                                    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                    padding: "1%",
                                    paddingLeft: '5%',
                                    flex: 'auto',
                                    justifyItems: 'center'
                                }}
                            >
                                <h1 className={"font_link"} style={{fontWeight: 'bold', flex: "auto", marginLeft: '17%'}}>Bayside History</h1>
                                <MediaQuery minWidth={901} >
                                    <Row>
                                        <Col
                                            span={12}
                                        >
                                            <p className={"aboutMainBody"}>
                                                Owners Darren & Kim Rubach opened their business in 2001 with just three member of staff, one truck and a 400m2 shed. Darren's vision was to see his business expand through a combination of quality products and quality customer service. The company motto was, ‘not thousands of products but good, old-fashioned service’.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                Within just a couple of years, increased demand prompted Darren and Kim to build a new 3500 m2 complex at 1424 New Cleveland Rd, Capalaba. They relocated there in 2004.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                More recently, Darren’s vision of the future came true again. In 2016, Bayside Plasterboard was given the opportunity to expand a second time. A second store was purchased, located on the Gold Coast, at Carerra, with the aim to expand the Bayside delivery zone and let even more of Brisbane access this excellent service. Bayside Plasterboard is now able to bring plastering supplies to customers from the southern tip of the Sunshine Coast down almost as far as Byron Bay.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                Today, Bayside Plasterboard has its own fleet of vehicles and a dedicated, experienced team committed to going the extra mile for each and every customer. The complex in Capalaba now serves the growing needs of the building industry throughout the central and northern Brisbane area, while the Carerra location takes care of the southern regions.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                Our motto of ‘not thousands of products but good, old-fashioned service’ has paid off time and time again. In the current digital-sales driven market it’s not enough to offer any old product. Bayside Plasterboard have always understood this concept perfectly; for years we aware that our attention to detail and willingness to always put the customer first would more than make up for a more select catalogue. This belief has been proved through our growth ... twice.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                We’d like to introduce you to our team of dedicated Bayside Plasterboard staff. Our team of drivers, warehouse staff, administration department and management team work hard to ensure we remain on top of the list when it comes to plaster supplies. Brisbane and the surrounding suburbs have responded so positively we have had to expand. We plan to continue in this trend, growing our business by offering a dedicated, world-class service to every Bayside Plasterboard customer for many years to come.
                                            </p>
                                        </Col>
                                        <Col
                                            span={12}
                                            style={{
                                                textAlign: "right",
                                            }}
                                        >
                                            <img
                                                className="logo"
                                                alt="bayside"
                                                onClick={() => {
                                                    window.location.href = "/";
                                                }}
                                                src="../../assets/images/cleanscreenshot.PNG"
                                                style={{
                                                    width: '30vw',
                                                    marginRight: '7vw'
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </MediaQuery>
                                <MediaQuery maxWidth={900}>
                                    <Row>
                                        <Col
                                            span={24}
                                        >
                                            <p className={"aboutMainBody"}>
                                                Owners Darren & Kim Rubach opened their business in 2001 with just three
                                                member of staff, one truck and a 400m2 shed. Darren's vision was to see
                                                his business expand through a combination of quality products and
                                                quality customer service. The company motto was, ‘not thousands of
                                                products but good, old-fashioned service’.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                Within just a couple of years, increased demand prompted Darren and Kim
                                                to build a new 3500 m2 complex at 1424 New Cleveland Rd, Capalaba. They
                                                relocated there in 2004.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                More recently, Darren’s vision of the future came true again. In 2016,
                                                Bayside Plasterboard was given the opportunity to expand a second time.
                                                A second store was purchased, located on the Gold Coast, at Carerra,
                                                with the aim to expand the Bayside delivery zone and let even more of
                                                Brisbane access this excellent service. Bayside Plasterboard is now able
                                                to bring plastering supplies to customers from the southern tip of the
                                                Sunshine Coast down almost as far as Byron Bay.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                Today, Bayside Plasterboard has its own fleet of vehicles and a
                                                dedicated, experienced team committed to going the extra mile for each
                                                and every customer. The complex in Capalaba now serves the growing needs
                                                of the building industry throughout the central and northern Brisbane
                                                area, while the Carerra location takes care of the southern regions.
                                            </p>
                                            <img
                                                className="logo"
                                                alt="bayside"
                                                onClick={() => {
                                                    window.location.href = "/";
                                                }}
                                                src="../../assets/images/cleanscreenshot.PNG"
                                                style={{
                                                    width: '95%',
                                                    marginRight: '10%'
                                                }}
                                            />

                                            <p className={"aboutMainBody"}>
                                                Our motto of ‘not thousands of products but good, old-fashioned service’
                                                has paid off time and time again. In the current digital-sales driven
                                                market it’s not enough to offer any old product. Bayside Plasterboard
                                                have always understood this concept perfectly; for years we aware that
                                                our attention to detail and willingness to always put the customer first
                                                would more than make up for a more select catalogue. This belief has
                                                been proved through our growth ... twice.
                                            </p>
                                            <p className={"aboutMainBody"}>
                                                We’d like to introduce you to our team of dedicated Bayside Plasterboard
                                                staff. Our team of drivers, warehouse staff, administration department
                                                and management team work hard to ensure we remain on top of the list
                                                when it comes to plaster supplies. Brisbane and the surrounding suburbs
                                                have responded so positively we have had to expand. We plan to continue
                                                in this trend, growing our business by offering a dedicated, world-class
                                                service to every Bayside Plasterboard customer for many years to come.
                                            </p>
                                        </Col>
                                    </Row>
                                </MediaQuery>
                            </div>
                        </Row>
                        <br/>
                        <Row
                            className="AboutTeam"
                            style={{
                                height: "80%",
                                width: "100%",
                            }}
                            >
                            <div
                                style={{
                                    height: "100%",
                                    width: '100%',
                                    marginTop: '1vw',
                                    marginBottom: '2%',
                                    borderRadius: "20px",
                                    // E46731
                                    background: 'rgba(42, 42, 43, 0.5)',
                                    boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                    padding: "1vw",
                                    paddingLeft: "5vw"
                                }}
                            >
                                <MediaQuery minWidth={900} >
                                    <h3 className={"font_link"}>Owners </h3>
                                    <Row className={"staffRow1"}>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="DARREN RUBACH" title="Director" phone={""}  email={""} photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Kim RUBACH" title="Director" phone="" email="" photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                    </Row>
                                    <h3 className={"font_link"}>Management Team</h3>
                                    <Row
                                        className="staffRow1"
                                    >

                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Jacob Rubach" title="General Manager" phone="0408 335 880" email="jacob@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Daryl Jennens" title="Sales Manager" phone="0413 511 229" email="daryl@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>



                                    </Row>
                                    <br/>
                                    <h3 className={"font_link"}> Sales Team</h3>
                                    <Row
                                        className="staffRow2"
                                    >
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Caine Chisolm" title="Buisness Development Manager"
                                                         phone="0421 383 998" email="caine@bspb.com.au"
                                                         photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Harry Huang" title="Buisness Development Manager"
                                                         phone="0421 383 997" email="Harry@bspb.com.au"
                                                         photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Ravi Signh" title="Buisness Development Manager" phone="0418 889 335"
                                                         email="Ravi@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Stephen Young" title="Buisness Development Manager"
                                                         phone="0407 042 816" email="Stephen@bspb.com.au"
                                                         photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Tom Cotten" title="Buisness Development Manager" phone="0421 383 999"
                                                         email="Tom@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                    </Row>
                                    <h3 className={"font_link"}>Warehouse Team</h3>
                                    <Row
                                        className="staffRow3"
                                    >
                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Jason Booth" title="Logistics Manager" phone="" email=""
                                                         photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                        <div
                                            style={{width: "2vw"}}
                                        ></div>

                                        <Col
                                            span={4}
                                        >
                                            <StaffMember Name="Paul Murphy" title="Operations Manager" phone="" email=""
                                                         photo="../../assets/images/staffheadshot-2.jpg"/>
                                        </Col>
                                    </Row>
                                </MediaQuery>
                                <MediaQuery maxWidth={901}>
                                <Col
                                        span={23}
                                        style={{marginBottom: '5%'}}
                                    >

                                    <Collapse
                                        accordion={true}
                                        bordered={false}
                                    >
                                        <Collapse.Panel header="Owners" key="1">
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="DARREN RUBACH" title="Director" phone={""}  email={""} photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                            <br/>
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Kim RUBACH" title="Director" phone="" email="" photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                        </Collapse.Panel>
                                        <Collapse.Panel header="Management Team" key="2">
                                            <Col
                                                span={24}
                                            >
                                                <StaffMember Name="Jacob Rubach" title="General Manager" phone="0408 335 880" email="jacob@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                            </Col>
                                            <br/>
                                            <Col
                                                span={24}
                                            >
                                                <StaffMember Name="Daryl Jennens" title="Sales Manager" phone="0413 511 229" email="daryl@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                            </Col>

                                        </Collapse.Panel>
                                        <Collapse.Panel header="Sales Team" key="3">
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Caine Chisolm" title="Buisness Development Manager"
                                                                 phone="0421 383 998" email="caine@bspb.com.au"
                                                                 photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                                <br/>
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Harry Huang" title="Buisness Development Manager"
                                                                 phone="0421 383 997" email="Harry@bspb.com.au"
                                                                 photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                                <br/>
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Ravi Signh" title="Buisness Development Manager" phone="0418 889 335"
                                                                 email="Ravi@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                                <br/>
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Stephen Young" title="Buisness Development Manager"
                                                                 phone="0407 042 816" email="Stephen@bspb.com.au"
                                                                 photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                                <br/>
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Tom Cotten" title="Buisness Development Manager" phone="0421 383 999"
                                                                 email="Tom@bspb.com.au" photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                        </Collapse.Panel>
                                        <Collapse.Panel header="WareHouse Team" key="4">
                                            <div>
                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Jason Booth" title="Logistics Manager" phone="" email=""
                                                                 photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                                <br/>

                                                <Col
                                                    span={24}
                                                >
                                                    <StaffMember Name="Paul Murphy" title="Operations Manager" phone="" email=""
                                                                 photo="../../assets/images/staffheadshot-2.jpg"/>
                                                </Col>
                                            </div>

                                        </Collapse.Panel>
                                    </Collapse>
                                    </Col>

                                </MediaQuery>
                            </div>
                        </Row>

                    </div>
            </Overlay>
        </div >
    );
}

export default About;