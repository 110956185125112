 import React, { useContext, useEffect, useState } from "react";
import Overlay from "../../componets/overlay/overlay";
import {Button, Card, Col, Divider, Image, Input, Modal, Pagination, Row, Space, Table, Tabs} from "antd";

import MediaQuery from "react-responsive";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import resourceStore from "../../stores/resource.store";
import Meta from "antd/es/card/Meta";
import ArticleTile from "./ArticleTile";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {LeftCircleOutlined} from "@ant-design/icons";
 import baseUrl from "../../stores/Utility";
 import {Helmet} from "react-helmet";
 import UserStore from "../../stores/user.store";

function ResourcesPage() {
    const store = useContext(resourceStore)
    const userStore = useContext(UserStore)
    const [modalOpen, setModalOpen] = useState(false)
    const [article, setArticle] = useState(null)
    const projectPath = useLocation();
    useEffect(() => {
        if (store.selectedArticle == -1) {
            const thing = store.getArticleFromTitle(decodeURI(projectPath.pathname.split("Blog/")[1]));
            console.log(thing)
        }
    }, [store.SelectArticle]);
    const navigate = useNavigate();
    return (

        <div>
            <Helmet>
                <title>{store.SelectArticle.Title}</title>
                <meta name="description" content={store.SelectArticle.SubTitle} />
                <meta name="keywords" content={store.SelectArticle.Tags} />
                <meta property="og:title" content="Blogs" />
                <meta property="og:description" content="Bayside Blog" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="../../assets/icons/BaysideLogo.png" />
            </Helmet>
            <Overlay>
                    <div className="Resource"  style={{
                        height: '100%',
                        marginLeft: '10%',
                        marginRight: '10%',
                        padding: '5%'
                    }}
                    >
                        {userStore.IsUserLoggedIn() &&
                            <Button onClick={() => {
                                store.SetArticleForEdit(store.SelectArticle)
                                navigate('/ArticleCreate')
                            }}> Edit </Button>
                        }
                        <Row
                            className="mainback"

                        >
                            <Col span={4}>
                                <Button
                                    className={"font_link"}
                                    onClick={() => {
                                        store.setSelectArticle(-1)
                                        navigate("/Articles")
                                    }}
                                    style={{paddingLeft: '4%', paddingRight: '4%', paddingTop: '2%', fontSize: '115%'}}
                                    icon={<LeftCircleOutlined />}
                                >Blogs </Button>
                            </Col>
                            <Col span={24}>
                                <Row
                                    className="mainback"
                                    style={{textAlign: 'center', alignItems: 'center', justifyContent: 'center'}}
                                >
                                    <h1 className={"font_link"} >{store.SelectArticle.Title.replaceAll("-", " ")}</h1>
                                    <Divider style={{borderBottom: '1px solid #ccc'}}/>
                                </Row>
                            </Col>
                        </Row>
                        <Row
                            style={{
                                display: 'flex',  justifyContent:'center', alignItems:'center',
                            }}
                        >
                            <Image src={`${baseUrl()}/resourceImage/${store.SelectArticle.Id}`} height="50%" width="70%"
                                   style={{
                                       borderRadius: '10px',
                                       boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.3)',
                                   }}/>
                        </Row>
                        <Row>
                            <Col
                                className={"articleMainBody"}
                                style={{
                                    fontSize: '115%'
                                }}
                            >

                                {store.formatForDisplay(store.SelectArticle.Article)}
                            </Col>

                        </Row>
                    </div>
            </Overlay>
        </div >
    );
}

export default observer(ResourcesPage);