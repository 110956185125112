import React, { useContext, useEffect, useState } from "react";
import Overlay from "../../componets/overlay/overlay";
import { Button, Col, Input, Row, Space, Tabs } from "antd";
import {
    CalculatorOutlined,
    ClockCircleOutlined, CodepenOutlined,
    LaptopOutlined,
    MobileOutlined,
    PhoneOutlined,
} from "@ant-design/icons";
import MediaQuery from "react-responsive";

const OrderBuilderPage: React.FC = () => {
    return (

        <div>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div className="OrderBuilder" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize:     "cover",
                        height: '100%',
                        backgroundRepeat:   "no-repeat",}}
                    >
                        <Row
                            className="mainback"
                            style={{
                                height: '70vh'
                            }}
                        >
                            <h1> Order Builder</h1>
                            <div
                                style={{
                                    marginLeft: '5vw',
                                    marginRight: '5vw',
                                    height: '60vh'
                                }}
                            >
                                <Row
                                    style={{
                                        marginTop: '5%'
                                    }}
                                >
                                    <Col span={11}
                                        style={{
                                            background: 'darkolivegreen',
                                            borderRadius: '20px',
                                            boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)'
                                        }}
                                    >
                                        <Row
                                            style={{
                                                paddingLeft: '15%',
                                                paddingTop: '10vh',
                                            }}
                                        >
                                            <p
                                            >Set Up A Ceiling System</p>
                                            <CalculatorOutlined style={{fontSize: '5vw'}}/>
                                            <Space />
                                            <CodepenOutlined style={{fontSize: '5vw'}}/>
                                        </Row>
                                    </Col>
                                    <Col span={2}/>
                                    <Col span={11}
                                         style={{
                                             background: 'darkslateblue',
                                             borderRadius: '20px',
                                             boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)'
                                         }}
                                    >
                                        <Row
                                            style={{
                                                paddingLeft: '15%',
                                                paddingTop: '10vh',
                                            }}
                                        >
                                            <p
                                            >Set Up A Wall</p>
                                            <CalculatorOutlined style={{fontSize: '5vw'}}/>
                                            <Space />
                                            <CodepenOutlined style={{fontSize: '5vw'}}/>
                                        </Row>
                                    </Col>
                                </Row>
                                <br/>
                                <Row
                                    style={{
                                        width: '100%'
                                    }}
                                >
                                    <Col span={11}
                                         style={{
                                             background: 'darkorange',
                                             borderRadius: '20px',
                                             boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)',
                                         }}
                                    >
                                        <Row
                                            style={{
                                                paddingLeft: '15%',
                                                paddingTop: '10vh',
                                            }}
                                        >
                                            <p
                                            >Calculate Delivery Fee</p>
                                            <CalculatorOutlined style={{fontSize: '5vw'}}/>
                                            <Space />
                                            <CodepenOutlined style={{fontSize: '5vw'}}/>
                                        </Row>
                                    </Col>
                                    <Col span={2} />
                                    <Col span={11}
                                         style={{
                                             background: 'darkslategrey',
                                             borderRadius: '20px',
                                             boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)'
                                         }}
                                    >
                                        <Row
                                            style={{
                                                paddingLeft: '15%',
                                                paddingTop: '10vh'
                                            }}
                                        >
                                            <p
                                            >Set Up Some insulation </p>
                                            <CalculatorOutlined style={{fontSize: '5vw'}}/>
                                            <Space />
                                            <CodepenOutlined style={{fontSize: '5vw'}}/>
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        </Row>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <p>mobile</p>
                </MediaQuery>
            </Overlay>
        </div >
    );
}

export default OrderBuilderPage;