import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './pages/home/home';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import About from './pages/about/about';
import CataloguePage from "./pages/catalogue/catalogue";
import InsperationPage from "./pages/insperation/insperation";
import OrderBuilderPage from "./pages/orderbuilder/orderbuilder";
import Resourcespage from "./pages/Resources/resourcespage";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";
import CheckOut from "./pages/CheckOut/CheckOut";
import AdminPage from "./pages/Admin/AdminPage";
import {SiteVisit} from "./pages/siteVisit/SiteVisit";
import UsersPage from "./pages/UserPage/UserPage";
import OrderForm from "./pages/OrderForm/OrderForm";
import ArticleViewPage from "./pages/Resources/ArticleViewPage";
import {PrivacyPolicy} from "./pages/PrivacyPolicy/Index";
import {TermsConditions} from "./pages/PrivacyPolicy/TermsConditions";
import {ContactUsPage} from "./pages/Contactus/ContactUsPage";
import {CustomerEntryPage} from "./pages/CustomerEntry/CustomerEntryPage";
import {CustomerEnquiry} from "./pages/Marketing/CustomerEnquiry";
import ArticleCreate from "./pages/ArticleCreate/ArticleCreate";
import AdminlogIn from "./pages/Admin/AdminlogIn";
import ProductRangeMain from "./pages/ProductRange/ProductRangeMain";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <Home/>,
    },
    {
        path: '/about',
        element: <About/>,
    },
    {
        path: '/catalogue',
        element: <CataloguePage/>,
    },
    {
        path: '/Inspiration',
        element: <InsperationPage/>,
    },
    {
        path: '/orderwizard',
        element: <OrderBuilderPage/>,
    },
    {
        path: '/Articles',
        element: <Resourcespage/>,
    },
    {
        path: '/checkout',
        element: <CheckOut/>,
    },
    // {
    //     path: '/Admin',
    //     element: <AdminPage />
    // },
    {
        path: '/SiteVisit',
        element: <SiteVisit />
    },
    {
        path: '/OrderForm',
        element: <OrderForm />
    },
    {
        path: '/Blog/*',
        element: <ArticleViewPage />
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />
    },
    {
        path: '/terms-conditions',
        element: <TermsConditions />
    },
    {
        path: '/ContactUs',
        element: <ContactUsPage />
    },
    {
        path: '/grand-opening-morningside',
        element: <CustomerEntryPage />
    },
    {
        path: '/LunchAndLearn',
        element: <CustomerEnquiry />
    },
    {
        path: '/ArticleCreate',
        element: <ArticleCreate />
    },
    {
        path: '/AdminLogin',
        element: <AdminlogIn />
    },
    {
        path: '/ProductRanges',
        element: <ProductRangeMain />
    }

])

root.render(
    <React.StrictMode>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <RouterProvider router={router}/>
        </DevSupport>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
