import {useForm} from "antd/es/form/Form";
import {Form, Image, Input, List, message, Row} from "antd";
import Button from "antd/es/button";
import React from "react";
import {MinusOutlined} from "@ant-design/icons";
import axios from "axios";
import baseUrl from "../../stores/Utility";

export function OpeningSIgnUpForm() {
    const [form] = useForm();

    const onFormFinish = (values: any) => {
        axios({
            method : "POST",
            url :  `${baseUrl()}/EnterCustomer`,
            headers : {
                "Content-Type":"application/json",
            },
            data: values
        }).then(res => {
            message.success("Request Sent")
            console.log(res)
        }).catch((err)=>{
            message.error("Please Retry")
            console.log(err)
        })
    };

    const onFormFinishFailed = (errorInfo: any) => {
        // todo handle form finish fail
    };

    const onFormClearClick = () => {
        form.resetFields();
    };

    return (
        <div>
            <img src="../../assets/images/balloons.png" height={'100%'} width={"100%"} style={{alignSelf: 'center'}}/>
            <h2>You're invited! Bring The Family Too!</h2>
            <p className={"articleMainBody"} style={{fontSize: '150%'}}>Bring the family along for a fun-filled day at our Morningside Grand Opening event.</p>
            <p className={"articleMainBody"} style={{fontSize: '150%'}}>11am - 2pm Saturday 9th March at our new warehouse</p>
            <p className={"articleMainBody"} style={{fontSize: '150%'}}>367 Thynne Rd, Morningside.</p>
            <List
                dataSource={["Live Music", "Food Trucks", "Drinks", "Face Painting", "Jumping Castle", "Golf Simulator"]}
                renderItem={(item) => <List.Item className={"articleMainBody"} style={{fontSize: '150%'}}><MinusOutlined /> {item}</List.Item>}

            />
            <h1 className={"font_link"} style={{fontSize: '150%'}}>Major Prize for account holders ~ register below</h1>
            <p className={"articleMainBody"} style={{fontSize: '150%'}}> 🛵 💰 1st Prize - 2 x Electric scooters and a $3k travel voucher.</p>
            <p className={"articleMainBody"} style={{fontSize: '150%'}}>🏈 2nd Prize - 6 x seats to NRL magic round Sunday 19th May 2024</p>

            <Form
                form={form}
                name="Grand Opening"
                labelCol={{span: 4}}
                layout="horizontal"
                initialValues={{remember: true}}
                onFinish={onFormFinish}
                onFinishFailed={onFormFinishFailed}
                style={{marginTop: '2%'}}
            >
                <Row

                >
                    <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please input your Name!', max: 249 }]} style={{width: '40%', marginLeft: '3%'}}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your Email!', max: 249 }]} style={{width: '40%', marginLeft: '3%'}}>
                        <Input/>
                    </Form.Item>
                </Row>
                <Row>
                    <Form.Item label="Phone" name="phone" rules={[{ required: true, message: 'Please input your Phone!', max: 249 }]} style={{width: '40%', marginLeft: '3%'}}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Company" name="Company" rules={[{ required: true, message: 'Please input your Company!',max: 249 }]} style={{width: '40%', marginLeft: '3%'}}>
                        <Input/>
                    </Form.Item>
                </Row>

                <Form.Item style={{marginTop: '5%'}}>
                    <Button className={"font_link"} type="primary" htmlType="submit" style={{padding: '1%', marginRight: '1%', width: ' 45%', textAlign: 'center', paddingBottom: '2%'}}>
                        Submit
                    </Button>
                    <Button className={"font_link"} htmlType="button" onClick={onFormClearClick} style={{padding: '1%', marginRight: '1%',  width: ' 45%', paddingBottom: '2%'}}>
                        Clear
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
