import React, { useContext, useEffect, useState } from "react";
import Overlay from "../../componets/overlay/overlay";
import {
    Button,
    Col,
    Input,
    Row,
    Space,
    Tabs,
    Table,
    Image,
    Tag,
    TreeSelect,
    Select,
    message,
    Form,
    InputNumber, Tooltip
} from "antd";
import {
    ClearOutlined,
    ClockCircleOutlined,
    FieldBinaryOutlined,
    LaptopOutlined,
    MobileOutlined,
    PhoneOutlined,
} from "@ant-design/icons";
import MediaQuery from "react-responsive";
import ProductArray from "../../componets/Product/ProductArray";
import {ColumnsType} from "antd/es/table";
import CatalogueStore, {CatalogueItem} from "../../stores/catalogue.store";
import {observer} from "mobx-react-lite";
import {toJS} from "mobx";
import CartStore, {Product} from "../../stores/Cart.store";
import {Helmet} from "react-helmet";

const CataloguePage: React.FC = () => {
    const CatStore = useContext(CatalogueStore);
    const UserCartStore = useContext(CartStore);
    const [backGroundColour , setbackGroundColour] = useState("red")

    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'Description',
            key: 'Description',
            render: (record: any, index: number) => {
                return (
                    <div
                    >
                        <Row>
                            <Col> <Image src="../../assets/images/plasterboard.jpg" height="15vh" width="20vw" /> </Col>
                            <Col style={{marginLeft: '1vw'}}> <h4 style={{fontSize: '120%'}}>{record}</h4> </Col>
                        </Row>
                    </div>
                )
            }
        },
        {
            title: 'Sku',
            dataIndex: 'Id',
            key: 'Id'
        },
        // {
        //     title: "Resources",
        //     dataIndex: 'Id',
        //
        //     key: 'Id',
        //     render: (record: any, index: number) => {
        //         return (
        //             <div>
        //                 <Tag
        //                     color="gold"
        //                     style={{
        //                         cursor: 'pointer'
        //                     }}
        //                     onClick={()=>{
        //                         console.log("Go to resource")
        //                     }}
        //                 >James harder Install</Tag>
        //             </div>
        //         )
        //     }
        // },
        {
            title: 'Supplier',
            dataIndex: 'Category',
            key: 'Category',
        },
        {
            title: 'Add To Cart',
            dataIndex: 'Name',
            key: 'operation',
            render: (text, record: any, index: number) => {
                let itemCount = 0;
                return (
                    <div>
                        <InputNumber
                            min={0}
                            style={{
                                marginRight: '0.5vw'
                            }}
                            onChange={(value) => {
                                if (value === null) return
                                itemCount = value;
                            }}
                        />

                        <Tooltip title={"Add To Cart"}>
                            <button
                                onClick={() => {
                                    UserCartStore.AddToCart({...record, Quantity: itemCount});
                                    itemCount = 0;
                                }}
                            >Add</button>
                        </Tooltip>
                    </div>
                )
            }
        },
        {
            title: 'Price Inc',
            dataIndex: 'PriceInc',
            key: 'PriceInc',
            render: ((text, record: CatalogueItem, index: number) => {
                return (
                    <div>
                        <p style={{fontSize: '120%'}}>{`$${text} / ${record.ItemType}`}</p>
                    </div>
                )
            })
        }
    ]

    const [form] = Form.useForm();
    const OnClear = () => {
        form.resetFields();
    }

    return (

        <div>
            <Helmet>
                <title>Catalogue</title>
                <meta name="description" content="BaySide Catalogue" />
                <meta name="keywords" content="plasterboard, Catalogue, Bayside" />
                <meta property="og:title" content="Catalogue" />
                <meta property="og:description" content="Bayside Catalogue" />
                <meta property="og:image" content="" />
                <meta property="og:url" content="../../assets/icons/BaysideLogo.png" />
            </Helmet>
            <Overlay>
                <MediaQuery minWidth={900}>
                    <div className="Catalogue" style={{
                        // backgroundImage: "url(../../assets/images/plasterboardRoom.jpg)",
                        backgroundSize:     "cover",
                        backgroundRepeat:   "no-repeat",}}
                    >
                        <div
                            className="mainback"
                            style={{
                                height: '100%'
                            }}
                        >

                            <div
                                className="productRangeDiv"
                                style={{
                                    zIndex: '1',
                                    alignContent: 'center',
                                    backgroundSize:     "cover",
                                    backgroundRepeat:   "no-repeat",
                                    boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)'
                                }}
                            >
                                <Row
                                    style={{ width: "100%" }}
                                >
                                    <Col
                                        span={4}
                                         className="catCol"
                                         onMouseEnter={() => {

                                         }}
                                        onClick={() => {
                                            CatStore.SetMainCategory("plasterBoard");
                                        }}
                                         style={{cursor: "pointer"}}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/material.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Plasterboard</p>
                                    </Col>
                                    <Col span={4} className="catCol_1"
                                         onMouseEnter={() => {

                                         }}
                                         onClick={() => {
                                             CatStore.SetMainCategory("Ceiling");
                                         }}
                                         style={{cursor: "pointer" }}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/frame.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Ceiling & Steel Framing</p>
                                    </Col>
                                    <Col span={4} className="catCol"
                                         onMouseEnter={() => {

                                         }}
                                         onClick={() => {
                                             CatStore.SetMainCategory("insulation");
                                         }}
                                         style={{cursor: "pointer"}}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/insulation.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Insulation</p>
                                    </Col>
                                    <Col span={3} className="catCol"
                                         onMouseEnter={() => {

                                         }}
                                         onClick={() => {
                                             CatStore.SetMainCategory("compound");
                                         }}
                                         style={{cursor: "pointer"}}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/cement.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Compound & Adhesives</p>
                                    </Col>
                                    <Col span={3} className="catCol_1"
                                         onMouseEnter={() => {

                                         }}
                                         onClick={() => {
                                             CatStore.SetMainCategory("screws");
                                         }}
                                         style={{cursor: "pointer"}}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/joint.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Fixing & Fasteners</p>
                                    </Col>
                                    <Col span={3} className="catCol"
                                         onMouseEnter={() => {

                                         }}
                                         onClick={() => {
                                             CatStore.SetMainCategory("tools");
                                         }}
                                         style={{cursor: "pointer"}}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/trowel.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Tooling</p>
                                    </Col>
                                    <Col span={3} className="catCol_1"
                                         onMouseEnter={() => {

                                         }}
                                         onClick={() => {
                                             CatStore.SetMainCategory("ceiling Systems");
                                         }}
                                         style={{cursor: "pointer"}}
                                    >
                                        <img className="productRangeIcon" alt="productRangeIcon" src="../../assets/icons/ceiling.png" style={{height: '6vw', width: '6vw'}}/>
                                        <p
                                            className="catColText"
                                        >Ceiling Systems</p>
                                    </Col>
                                </Row>
                            </div>
                            <br/>
                            <Form>
                                <Row
                                    style={{
                                        marginRight: "1vw",
                                        marginLeft: '1vw',
                                        paddingLeft: '5%',
                                        width: '100%',
                                        backgroundColor: '#E1341E',
                                        borderTopLeftRadius: '20px',
                                        borderBottomLeftRadius: '20px',
                                        paddingTop: '1%',
                                        boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.7)'
                                    }}
                                >
                                    <Col
                                        span={10}
                                    >
                                        <Form.Item>
                                            <Input
                                                placeholder="Search Products"
                                                onChange={(value)=>{
                                                    CatStore.SetSearchAbleText(value.target.value);
                                                }}
                                            ></Input>
                                        </Form.Item>
                                    </Col>
                                    <Col
                                        span={10}
                                        style={{
                                            height: '3vh',
                                            width: '100%',
                                            marginLeft: '1vw'
                                        }}
                                    >
                                        <Form.Item>
                                            <Select
                                                placeholder={"Product Ranges"}
                                                options={
                                                toJS(CatStore.Suppliers.map(item => {return {
                                                    value: item,
                                                    label: item
                                                }}))}
                                                style={{
                                                    width: "30vw"
                                                }}
                                                showSearch={true}
                                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                filterSort={(optionA, optionB) =>
                                                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())}
                                                allowClear={true}
                                                onChange={(value) => {
                                                    CatStore.SupplierSelected = value
                                                }}
                                            >
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                    <Col span={3}
                                         style={{
                                             marginLeft: '1vw'
                                         }}
                                    >
                                        <Tooltip title={"Clear Search fields"}>
                                            <Button icon={
                                                <ClearOutlined/>
                                            }
                                                    onClick={()=>{
                                                        CatStore.Clear();
                                                        OnClear();
                                                    }}

                                                    size={'large'}
                                                    shape={'circle'}
                                            >

                                            </Button>
                                        </Tooltip>

                                    </Col>
                                </Row>

                            </Form>

                            <div
                                style={{
                                    padding: '5%'

                                }}
                            >
                                <Table
                                    columns={columns}
                                    pagination={{ position: ['topRight', 'bottomRight'] }}
                                    dataSource={toJS(CatStore.Filterdresults)}
                                    style={{
                                        borderRadius: '20px',
                                        padding: "5px",
                                        paddingLeft: '5%',
                                        paddingRight: '5%',
                                        backgroundColor: "lightslategray",
                                        boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.7)"
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery maxWidth={901}>
                    <p>mobile</p>
                </MediaQuery>
            </Overlay>
        </div >
    );
}

export default observer(CataloguePage);