import {Card, Col, Image, List, Row} from "antd";
import {MinusOutlined} from "@ant-design/icons";

export function Insulation() {
    return (
        <div style={{padding: '2%', height: '100%', minHeight: '60vh'}}>
            <Row>
                <Col span={11}>
                    <Card title={"Fletchers Pink Soundbreak"}>
                        <Row>
                            <Col span={11}>
                                <List
                                    dataSource={[
                                        "High density insulation to provide exceptional acoustic performance in residential wall, ceiling and sub-floor applications.",
                                        "Reduces sound transfer from the external environment into your home or between adjacent rooms.",
                                        "Provides excellent thermal insulation properties, keeping homes cooler in summer and warmer in winter.",
                                        "Improves the energy efficiency of a home to deliver energy cost savings.",
                                        "Manufactured using an innovative technology which makes Pink® Soundbreak® softer to touch compared to traditional glasswool insulation.",
                                        "Pink® Soundbreak® maintains its firmness ensuring batts remain in place for ongoing thermal performance.",
                                        "FBS-1 Glasswool Bio-Soluble Insulation® – safe to use.",
                                        "Non-combustible.",
                                        "Manufactured from up to 80% recycled content.",
                                        "Australian made.",
                                        "Backed by a Homeowner Lifetime Warranty.",
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Fletchers-Pink-Soundbreak-Featured-Image.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Image src={"./assets/images/productRanges/Fletchers-Pink-Soundbreak.png"} alt={"bayside Product"} />
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Autex GreenStuf ASB"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    The addition of Sound Solution in a standard timber-framed wall will reduce loud sounds on one side to a slight murmur on the other. Sound Solution is ideal for isolating bedrooms and bathrooms from living spaces.
</p><p>
                                    It reduces airborne sound, impact noise and noise transmission by controlling resonating noise inside the construction cavity.
</p><p>
                                    Sound Solution can be used in a mid-floor cavity, to significantly reduce noise between upstairs and downstairs.
                                </p>
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Autex-GreenStuf-Sound-Solution-Featured-Image.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Image src={"./assets/images/productRanges/Autex-GreenStuf-Sound-Solution-Specifications.png"} alt={"bayside Product"} />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col span={11}>
                    <Card title={"letchers Pink Partition Insulation"}>
                        <Row>
                            <Col span={11}>
                                <List
                                    dataSource={[
                                        `Provides excellent thermal insulation properties, keeping buildings cooler in summer and warmer in winter.`,
                                        `Delivers exceptional acoustic performance to limit sound transfer through external walls as well as between internal partition walls.`,
                                        `Improves the energy efficiency of a building to limit energy usage and deliver cost savings.`,
                                        `Available in a range of dimensions to suit common steel stud sizes.`,
                                        `Manufactured using an innovative technology which makes Pink® Partition insulation softer to touch compared to traditional glasswool insulation.`,
                                        `Maintains its firmness for ongoing thermal performance.`,
                                        `FBS-1 Glasswool Bio-Soluble Insulation® – safe to use.`,
                                        `Non-combustible.`,
                                        `Manufactured from up to 80% recycled content.`,
                                        `Australian made.`,
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Fletchers-Pink-Partition-Insulation-Featured-Image-600x600.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Image src={"./assets/images/productRanges/Fletchers-Pink-Partition-Insulation-Specifications.png"} alt={"bayside Product"} />
                        </Row>
                    </Card>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Fletchers Pink Batts"}>
                        <Row>
                            <Col span={11}>
                                <List
                                    dataSource={[
                                        "Provide excellent thermal insulation properties, keeping homes cooler in summer and warmer in winter",
                                        "Improve the energy efficiency of a home to deliver energy cost savings.",
                                        "Manufactured using an innovative technology which makes Pink Batts® softer to touch compared to traditional glasswool insulation.",
                                        "Maintain their firmness ensuring products remain in place for ongoing thermal performance.",
                                        "FBS-1 Glasswool Bio-Soluble Insulation® – safe to use.",
                                        "Non-combustible.",
                                        "CodeMark™ certified.",
                                        "Manufactured from up to 80% recycled content.",
                                        "Australian made.",
                                        "Backed by a homeowner lifetime warranty.",
                                    ]}
                                    renderItem={item => (<p><MinusOutlined/>{item}</p>)}
                                />
                            </Col>
                            <Col span={2} />
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Fletchers-Pink-Batts.jpg"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Image src={"./assets/images/productRanges/Fletcher-Pinkbatts-Specifications.png"} alt={"bayside Product"} />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row style={{marginTop: '1%'}}>
                <Col span={11}>
                    <Card title={"Autex GreenStuf ASB"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    GThe addition of Sound Solution in a standard timber-framed wall will reduce loud sounds on one side to a slight murmur on the other. Sound Solution is ideal for isolating bedrooms and bathrooms from living spaces.
                                </p><p>
                                It reduces airborne sound, impact noise and noise transmission by controlling resonating noise inside the construction cavity.
                            </p><p>
                                Sound Solution can be used in a mid-floor cavity, to significantly reduce noise between upstairs and downstairs.
                            </p>
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Autex-GreenStuf-Sound-Solution-Featured-Image.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Image src={"./assets/images/productRanges/Autex-GreenStuf-Sound-Solution-Specifications.png"} alt={"bayside Product"} />
                        </Row>
                    </Card>

                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                    <Card title={"Autex GreenStuf Partition Blankets"}>
                        <Row>
                            <Col span={11}>
                                <p>
                                    The Partition Blankets range includes Autex Sound Blanket, Commercial Partition & High Performance Infill
</p><p>
                                    Partition Blankets is a range of 100% polyester acoustic insulation products designed to improve the acoustic performance of partition walls and ceiling systems.
</p><p>
                                    Partition Blankets can be used in metal and timber stud partition walls and as an acoustic ceiling overlay for reducing airborne sound transfer.
                            </p>
                            </Col>
                            <Col span={2}/>
                            <Col span={11}>
                                <Image src={"./assets/images/productRanges/Autex-GreenStuf-Partition-Blankets-Featured-Image.png"} alt={"Bayside Product"}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
