import {observable, action, computed, reaction, makeAutoObservable} from "mobx"
import { createContext } from "react"

class InspirationStore {
    ItemsforOrder: any[] = [];
    constructor() {
        makeAutoObservable(this);
        this.ItemsforOrder = [
            {
                Id: 2,
                Name: "Some Ceiling Tile",
                quantity: 5
            },
            {
                Id: 3,
                Name: "Scaffolding",
                quantity: 1
            },
            {
                Id: 4,
                Name: "Big Clips",
                quantity: 8
            },
            {
                Id: 5,
                Name: "Hangers",
                quantity: 10
            },
            {
                Id: 5,
                Name: "Beams",
                quantity: 4
            },
            {
                Id: 6,
                Name: "Screws",
                quantity: 45
            },
        ]
    }

    getItemDetails(recordId: number) {
        const index = this.ItemsforOrder.findIndex(item => {return(item.Id === recordId)});
        if (index < 0) return null;
        return this.ItemsforOrder[index];
    }

    modifyQuantity(row: any, desiredQuantity: number) {
        if (desiredQuantity <= 0) return
        const modifier = desiredQuantity / row.quantity;
        this.ItemsforOrder.forEach(item => {
            item.quantity *= modifier;
            console.log(item);
        })
    }


}

export default createContext(new InspirationStore())
