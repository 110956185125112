import {observable, action, computed, reaction, makeAutoObservable} from "mobx"
import { createContext } from "react"
import axios from "axios";
import baseUrl from "./Utility";
import {Product} from "./Cart.store";
import {queries} from "@testing-library/react";
import {message} from "antd";

const initThisProduct:CatalogueItem = {
    Id : '1',
    Description : "High Quality plasterboard",
    ItemType: "2M",
    PriceEx: 89.00,
    PriceInc: 189,
    Category: "26A - TILES - KNAUF"
}

export interface CatalogueItem {
    Id: string;
    Description: string | undefined;
    ItemType: string;
    PriceEx: number;
    PriceInc: number;
    Category: string;
}

const productCopies = 15;

const productArray : any[] = [];

for (let i = 0; i <= productCopies ; i += 1) {
    productArray.push(initThisProduct);
}

const HardSuppliers = ["James Hardie"];


class CatalogueStore {

    AvailableProducts: CatalogueItem[] = [];
    MainCategory: any;
    SearchAbleText: string | null | undefined;
    SupplierSelected: string | null;
    Suppliers : any[];

    constructor() {
        this.AvailableProducts = productArray;
        this.MainCategory = null;
        this.SearchAbleText =  null
        this.SupplierSelected = null;
        this.Suppliers = HardSuppliers;
        makeAutoObservable(this);
        this.DownloadCatalogue();
    }

    async DownloadCatalogue() {
        let url = `${baseUrl()}/GetProducts`;
        let data: CatalogueItem[] = [];
        await axios.get(url)
            .then(function (response) {
                // handle success
                console.log(response.data);
                data = response.data;
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
        this.AvailableProducts = data;
        // get suppliers
        let supplierArray:string[] = [];
        this.AvailableProducts.forEach(item => {
            const supplier = item.Category;
            const index = supplierArray.findIndex(val => {return (val === supplier)});
            if (index == -1) {
                supplierArray.push(supplier);
            }
        })
        this.Suppliers = supplierArray;
        console.log(this.Suppliers.length)
        return this.AvailableProducts.length;
    }

    GetCatalogue(UserId: number) {
        if (UserId == -1) {
            return this.AvailableProducts;
        }
        return this.AvailableProducts;
    }

    get Filterdresults() {
        let returnValue = this.AvailableProducts;
        // if (this.MainCategory) {
        //     returnValue = returnValue.filter((item) => item.Category === this.MainCategory);
        // }

        if (this.SupplierSelected) {
            returnValue = returnValue.filter(item => item.Category === this.SupplierSelected)
        }
        //incomingType
        if (this.SearchAbleText) {
            returnValue = returnValue.filter((item) => {
                const searchText = `${this.SearchAbleText?.toLowerCase()}`
                return item?.Description?.toLowerCase().includes(searchText);
            });
        }
        return returnValue;
    }

    SetSearchAbleText(SearchText: string){
        this.SearchAbleText = SearchText;
    }

    SetMainCategory(cat: string) {
        this.MainCategory = cat;
        console.log(cat)
        switch (cat){
            case "plasterBoard":
                this.SearchAbleText = "board"
                break;
            case "Ceiling":
                // TODO go through with jacob search Phrase
                this.SearchAbleText = "ceil"
                break;
            case "insulation":
                this.SearchAbleText = "batts"
                break;
            case "compound":
                // TODO need multiple search words
                break;

        }
        console.log(this.SearchAbleText);

        // screws
        // tools
    }

    SetSupplier(supplier: string){
        this.SupplierSelected = supplier;
    }

    Clear(){
        this.SupplierSelected = null;
        this.MainCategory = null;
        this.SearchAbleText = null;
    }

    createOrderFormCartObject(values: any): Product[] {
        const keys = Object.keys(values);
        const cartArray: Product[] = []
        keys.forEach(key => {
            const quantity = values[key];
            let itemIndex = this.AvailableProducts.findIndex(item => item.Id === key)
            if (quantity === undefined) return
            if (itemIndex === -1) {
                message.error("Coundn't add " + key);
                return;
            }
            const cartItem: Product = { ...this.AvailableProducts[itemIndex], Quantity: quantity };
            cartArray.push(cartItem);
        })
        return cartArray;
    }

}

export default createContext(new CatalogueStore())
