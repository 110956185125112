import Overlay from "../../componets/overlay/overlay";
import {OpeningSIgnUpForm} from "../../componets/marketing/OpeningSIgnUpForm";

export function CustomerEntryPage() {
    return (
        <div>
            <Overlay>
                <div
                    style={{
                        margin: '3%'
                    }}
                >
                    <OpeningSIgnUpForm />
                </div>
            </Overlay>
        </div>
    );
}
